const prophetsMessengersQuestions = [
  {
    question: "Who is considered the first prophet in Islam?",
    options: ["Musa (Moses)", "Issa (Jesus)", "Adam", "Ibrahim (Abraham)"],
    answer: "Adam",
  },
  {
    question: "Which prophet is known for constructing the Ark?",
    options: ["Nuh (Noah)", "Yusuf (Joseph)", "Musa (Moses)", "Dawud (David)"],
    answer: "Nuh (Noah)",
  },
  {
    question: "Who is known as Khalilullah, the Friend of Allah?",
    options: [
      "Muhammad [PBUH]",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Issa (Jesus)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question: "Which prophet succeeded Sulaiman (Solomon) as a king?",
    options: ["Harun (Aaron)", "Dawud (David)", "Yahya (John)", "None"],
    answer: "None",
  },
  {
    question: "Who was the father of Yusuf (Joseph)?",
    options: [
      "Ishaq (Isaac)",
      "Yaqub (Jacob)",
      "Lut (Lot)",
      "Ismail (Ishmael)",
    ],
    answer: "Yaqub (Jacob)",
  },
  {
    question: "Which prophet is known for his patience?",
    options: ["Ayyub (Job)", "Yunus (Jonah)", "Musa (Moses)", "Issa (Jesus)"],
    answer: "Ayyub (Job)",
  },
  {
    question: "Who is the last prophet in Islam?",
    options: [
      "Musa (Moses)",
      "Issa (Jesus)",
      "Muhammad [PBUH]",
      "Ibrahim (Abraham)",
    ],
    answer: "Muhammad [PBUH]",
  },
  {
    question: "Which prophet is mentioned the most in the Quran?",
    options: [
      "Muhammad [PBUH]",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Nuh (Noah)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Who was thrown into a fire by a tyrant king but remained unharmed?",
    options: [
      "Ibrahim (Abraham)",
      "Musa (Moses)",
      "Isa (Jesus)",
      "Yunus (Jonah)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question: "Which prophet was swallowed by a whale?",
    options: ["Yunus (Jonah)", "Musa (Moses)", "Nuh (Noah)", "Lut (Lot)"],
    answer: "Yunus (Jonah)",
  },
  {
    question: "Who was given the title Al-Khalil by Allah?",
    options: [
      "Musa (Moses)",
      "Muhammad [PBUH]",
      "Ibrahim (Abraham)",
      "Dawud (David)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question: "Which prophet could interpret dreams?",
    options: [
      "Yusuf (Joseph)",
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Ismail (Ishmael)",
    ],
    answer: "Yusuf (Joseph)",
  },
  {
    question: "Who was the prophet known for his wisdom and ruling a kingdom?",
    options: [
      "Sulaiman (Solomon)",
      "Dawud (David)",
      "Harun (Aaron)",
      "Yusuf (Joseph)",
    ],
    answer: "Sulaiman (Solomon)",
  },
  {
    question: "Which prophet was known to have split the sea?",
    options: [
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Nuh (Noah)",
      "Issa (Jesus)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question: "Who was the prophet that could talk to animals?",
    options: [
      "Sulaiman (Solomon)",
      "Dawud (David)",
      "Yusuf (Joseph)",
      "Ayyub (Job)",
    ],
    answer: "Sulaiman (Solomon)",
  },
  {
    question:
      "Which prophet is known to have been taken up to heavens before his death?",
    options: [
      "Issa (Jesus)",
      "Muhammad [PBUH]",
      "Ibrahim (Abraham)",
      "Musa (Moses)",
    ],
    answer: "Issa (Jesus)",
  },
  {
    question:
      "Which prophet is recognized for being given a comprehensive scripture as a guide?",
    options: [
      "Muhammad [PBUH]",
      "Musa (Moses)",
      "Issa (Jesus)",
      "Dawud (David)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Who is the prophet known to have been granted kingship and prophethood by Allah?",
    options: [
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Yusuf (Joseph)",
      "Ayyub (Job)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Which prophet is famous for his miraculous birth and speaking to people in the cradle?",
    options: ["Issa (Jesus)", "Muhammad [PBUH]", "Yahya (John)", "Adam"],
    answer: "Issa (Jesus)",
  },
  {
    question:
      "Who was the prophet that led the Children of Israel after Musa (Moses)?",
    options: [
      "Yusha (Joshua)",
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Harun (Aaron)",
    ],
    answer: "Yusha (Joshua)",
  },
  {
    question:
      "Which prophet is known for his significant role in the Islamic Hajj ritual?",
    options: [
      "Ismail (Ishmael)",
      "Ibrahim (Abraham)",
      "Muhammad [PBUH]",
      "Musa (Moses)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question:
      "Which prophet is notable for his night journey from Mecca to Jerusalem and then to heaven?",
    options: [
      "Muhammad [PBUH]",
      "Issa (Jesus)",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
    ],
    answer: "Muhammad [PBUH]",
  },
  {
    question: "Who was the prophet that was given the Zabur (Psalms)?",
    options: [
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Musa (Moses)",
      "Issa (Jesus)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Which prophet's life and safety were threatened by his own community for preaching monotheism?",
    options: ["Nuh (Noah)", "Lut (Lot)", "Shu'ayb", "Salih"],
    answer: "Salih",
  },
  {
    question:
      "Which prophet was known for his escape from his community's punishment through migration?",
    options: [
      "Musa (Moses)",
      "Lut (Lot)",
      "Ibrahim (Abraham)",
      "Muhammad [PBUH]",
    ],
    answer: "Muhammad [PBUH]",
  },
  {
    question:
      "Which prophet is known for being gifted with the ability to interpret dreams in the Quran?",
    options: [
      "Yusuf (Joseph)",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Isa (Jesus)",
    ],
    answer: "Yusuf (Joseph)",
  },
  {
    question:
      "Who is the prophet that was given the Torah according to Islamic belief?",
    options: [
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Dawud (David)",
      "Sulaiman (Solomon)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet is acknowledged for his sacrifice in Islamic tradition, willing to sacrifice his son upon God's command?",
    options: [
      "Ibrahim (Abraham)",
      "Isma'il (Ishmael)",
      "Musa (Moses)",
      "Dawud (David)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question:
      "Which prophet is celebrated for his miraculous journey by night from Mecca to Jerusalem?",
    options: ["Muhammad", "Musa (Moses)", "Isa (Jesus)", "Yunus (Jonah)"],
    answer: "Muhammad",
  },
  {
    question:
      "Who was the prophet that successfully contested the Pharaoh of Egypt and led the Exodus of the Israelites?",
    options: [
      "Musa (Moses)",
      "Harun (Aaron)",
      "Yusuf (Joseph)",
      "Sulaiman (Solomon)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question: "Who was the first prophet of Islam?",
    options: [
      "Nuh (Noah)",
      "Ibrahim (Abraham)",
      "Musa (Moses)",
      "Muhammad (Peace be upon him)",
    ],
    answer: "Muhammad (Peace be upon him)",
  },
  {
    question:
      "Which prophet is known for being thrown into a fire but was saved by Allah?",
    options: [
      "Isa (Jesus)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
      "Yusuf (Joseph)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question: "Who was the prophet who was swallowed by a whale?",
    options: ["Nuh (Noah)", "Yunus (Jonah)", "Ayyub (Job)", "Ishaq (Isaac)"],
    answer: "Yunus (Jonah)",
  },
  {
    question: "Which prophet was known for his exceptional patience?",
    options: [
      "Musa (Moses)",
      "Ayyub (Job)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
    ],
    answer: "Ayyub (Job)",
  },
  {
    question:
      "Who was the prophet who was given the ability to understand the language of animals?",
    options: [
      "Dawud (David)",
      "Sulayman (Solomon)",
      "Zakariya (Zechariah)",
      "Yahya (John)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question: "Which prophet was known for his wisdom and judgement?",
    options: [
      "Isa (Jesus)",
      "Dawud (David)",
      "Idris (Enoch)",
      "Ilyas (Elijah)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Who was the prophet who was miraculously born to a virgin mother?",
    options: [
      "Yahya (John)",
      "Isa (Jesus)",
      "Yusuf (Joseph)",
      "Ismail (Ishmael)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question: "Which prophet was known for his eloquence and oratory skills?",
    options: [
      "Shuayb (Jethro)",
      "Hud (Hud)",
      "Salih (Saleh)",
      "Muhammad (Peace be upon him)",
    ],
    answer: "Muhammad (Peace be upon him)",
  },
  {
    question: "Who was the prophet who was sent to the people of Madyan?",
    options: ["Nuh (Noah)", "Hud (Hud)", "Shuayb (Jethro)", "Salih (Saleh)"],
    answer: "Shuayb (Jethro)",
  },
  {
    question: "Which prophet was known for his ability to interpret dreams?",
    options: [
      "Yusuf (Joseph)",
      "Musa (Moses)",
      "Harun (Aaron)",
      "Zakariya (Zechariah)",
    ],
    answer: "Yusuf (Joseph)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of reviving the dead?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Zakariya (Zechariah)",
      "Yahya (John)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his strength and wrestling abilities?",
    options: [
      "Musa (Moses)",
      "Dawud (David)",
      "Sulayman (Solomon)",
      "Yahya (John)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question: "Who was the prophet who was sent to the people of 'Ad?",
    options: ["Hud (Hud)", "Salih (Saleh)", "Shuayb (Jethro)", "Idris (Enoch)"],
    answer: "Hud (Hud)",
  },
  {
    question:
      "Which prophet was known for his exceptional wisdom and knowledge?",
    options: [
      "Luqman (Luqman)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
      "Ismail (Ishmael)",
    ],
    answer: "Luqman (Luqman)",
  },
  {
    question: "Who was the prophet who was sent to the people of Thamud?",
    options: ["Salih (Saleh)", "Hud (Hud)", "Shuayb (Jethro)", "Idris (Enoch)"],
    answer: "Salih (Saleh)",
  },
  {
    question: "Which prophet was known for his ascension to the heavens?",
    options: [
      "Nuh (Noah)",
      "Idris (Enoch)",
      "Muhammad (Peace be upon him)",
      "Isa (Jesus)",
    ],
    answer: "Muhammad (Peace be upon him)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of splitting the sea?",
    options: [
      "Musa (Moses)",
      "Nuh (Noah)",
      "Yunus (Jonah)",
      "Sulayman (Solomon)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional patience and steadfastness?",
    options: ["Ayyub (Job)", "Yahya (John)", "Ilyas (Elijah)", "Harun (Aaron)"],
    answer: "Ayyub (Job)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of reviving a bird?",
    options: [
      "Ibrahim (Abraham)",
      "Isa (Jesus)",
      "Musa (Moses)",
      "Dawud (David)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question:
      "Which prophet was known for his ability to understand and speak to animals?",
    options: [
      "Sulayman (Solomon)",
      "Dawud (David)",
      "Yahya (John)",
      "Zakariya (Zechariah)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question: "Which prophet was known for his ability to control the wind?",
    options: [
      "Sulayman (Solomon)",
      "Ilyas (Elijah)",
      "Hud (Hud)",
      "Idris (Enoch)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question: "Who was the prophet who was sent to the people of Nineveh?",
    options: ["Yunus (Jonah)", "Shuayb (Jethro)", "Hud (Hud)", "Salih (Saleh)"],
    answer: "Yunus (Jonah)",
  },
  {
    question: "Which prophet was known for his exceptional beauty?",
    options: ["Yusuf (Joseph)", "Musa (Moses)", "Isa (Jesus)", "Dawud (David)"],
    answer: "Yusuf (Joseph)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a stream to flow from a rock?",
    options: [
      "Musa (Moses)",
      "Isa (Jesus)",
      "Sulayman (Solomon)",
      "Nuh (Noah)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his ability to tame and communicate with birds?",
    options: [
      "Dawud (David)",
      "Sulayman (Solomon)",
      "Idris (Enoch)",
      "Ilyas (Elijah)",
    ],
    answer: "Dawud (David)",
  },
  {
    question: "Who was the prophet who was sent to the people of Midian?",
    options: ["Shuayb (Jethro)", "Salih (Saleh)", "Hud (Hud)", "Yunus (Jonah)"],
    answer: "Shuayb (Jethro)",
  },
  {
    question: "Which prophet was known for his exceptional longevity?",
    options: ["Nuh (Noah)", "Idris (Enoch)", "Ayyub (Job)", "Luqman (Luqman)"],
    answer: "Nuh (Noah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a staff to turn into a snake?",
    options: ["Musa (Moses)", "Harun (Aaron)", "Isa (Jesus)", "Yunus (Jonah)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional eloquence and rhetoric?",
    options: [
      "Shuayb (Jethro)",
      "Ilyas (Elijah)",
      "Zakariya (Zechariah)",
      "Muhammad (Peace be upon him)",
    ],
    answer: "Muhammad (Peace be upon him)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a fire to become cool and harmless?",
    options: [
      "Ibrahim (Abraham)",
      "Isa (Jesus)",
      "Musa (Moses)",
      "Yunus (Jonah)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question:
      "Which prophet was known for his exceptional devotion and worship?",
    options: [
      "Zakariya (Zechariah)",
      "Dawud (David)",
      "Idris (Enoch)",
      "Yahya (John)",
    ],
    answer: "Zakariya (Zechariah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a mountain to split open?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Isa (Jesus)",
      "Dawud (David)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional devotion to Allah and his asceticism?",
    options: [
      "Yahya (John)",
      "Ilyas (Elijah)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Yahya (John)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional knowledge and wisdom in matters of the law?",
    options: [
      "Sulayman (Solomon)",
      "Musa (Moses)",
      "Luqman (Luqman)",
      "Shuayb (Jethro)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a staff to turn into a serpent?",
    options: ["Musa (Moses)", "Harun (Aaron)", "Isa (Jesus)", "Dawud (David)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in carpentry and metalwork?",
    options: [
      "Idris (Enoch)",
      "Dawud (David)",
      "Nuh (Noah)",
      "Sulayman (Solomon)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing the sun to move backwards?",
    options: [
      "Isa (Jesus)",
      "Yunus (Jonah)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional patience and perseverance in the face of adversity?",
    options: [
      "Ayyub (Job)",
      "Yahya (John)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ayyub (Job)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a storm to subside?",
    options: [
      "Nuh (Noah)",
      "Yunus (Jonah)",
      "Ilyas (Elijah)",
      "Shuayb (Jethro)",
    ],
    answer: "Nuh (Noah)",
  },
  {
    question: "Which prophet was known for his exceptional skills in archery?",
    options: [
      "Dawud (David)",
      "Yusuf (Joseph)",
      "Ismail (Ishmael)",
      "Idris (Enoch)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a she-camel to emerge from a rock?",
    options: ["Salih (Saleh)", "Musa (Moses)", "Shuayb (Jethro)", "Hud (Hud)"],
    answer: "Salih (Saleh)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in calligraphy and writing?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Zakariya (Zechariah)",
      "Isa (Jesus)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a spring to gush forth from a rock?",
    options: ["Musa (Moses)", "Isa (Jesus)", "Harun (Aaron)", "Yunus (Jonah)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in astronomy and navigation?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ibrahim (Abraham)",
      "Sulayman (Solomon)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a staff to turn into a serpent and then back into a staff?",
    options: ["Musa (Moses)", "Harun (Aaron)", "Isa (Jesus)", "Yunus (Jonah)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in agriculture and farming?",
    options: [
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a cloud to shade him from the sun?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in poetry and literature?",
    options: [
      "Luqman (Luqman)",
      "Dawud (David)",
      "Idris (Enoch)",
      "Zakariya (Zechariah)",
    ],
    answer: "Luqman (Luqman)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead bird to come back to life?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Ibrahim (Abraham)",
      "Yunus (Jonah)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in medicine and healing?",
    options: [
      "Idris (Enoch)",
      "Ilyas (Elijah)",
      "Isa (Jesus)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a storm to subside and the sea to part?",
    options: ["Musa (Moses)", "Nuh (Noah)", "Yunus (Jonah)", "Isa (Jesus)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in music and singing?",
    options: [
      "Dawud (David)",
      "Idris (Enoch)",
      "Yahya (John)",
      "Luqman (Luqman)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a river to flow from a rock?",
    options: [
      "Musa (Moses)",
      "Harun (Aaron)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in architecture and construction?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Nuh (Noah)",
      "Dawud (David)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dry tree to become green and bear fruit?",
    options: [
      "Isa (Jesus)",
      "Yunus (Jonah)",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in military strategy and warfare?",
    options: [
      "Dawud (David)",
      "Ismail (Ishmael)",
      "Sulayman (Solomon)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Dawud (David)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a cloud to provide shade and protection?",
    options: [
      "Musa (Moses)",
      "Isa (Jesus)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in mathematics and geometry?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Dhul-Kifl (Ezekiel)",
      "Ismail (Ishmael)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a mountain to be lifted and suspended in the air?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Isa (Jesus)",
      "Yunus (Jonah)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in weaving and textile work?",
    options: [
      "Idris (Enoch)",
      "Ismail (Ishmael)",
      "Yunus (Jonah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a mountain to be shattered into pieces?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Isa (Jesus)",
      "Yunus (Jonah)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in pottery and ceramics?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a staff to turn into a serpent and swallow the serpents of the sorcerers?",
    options: ["Musa (Moses)", "Harun (Aaron)", "Isa (Jesus)", "Yunus (Jonah)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in horticulture and gardening?",
    options: [
      "Nuh (Noah)",
      "Sulayman (Solomon)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a fire to become cool and harmless for Ibrahim?",
    options: [
      "Ibrahim (Abraham)",
      "Isa (Jesus)",
      "Musa (Moses)",
      "Yunus (Jonah)",
    ],
    answer: "Ibrahim (Abraham)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in animal husbandry and breeding?",
    options: [
      "Ismail (Ishmael)",
      "Nuh (Noah)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a storm to subside and the sea to part, allowing safe passage?",
    options: ["Musa (Moses)", "Nuh (Noah)", "Yunus (Jonah)", "Isa (Jesus)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in hunting and archery?",
    options: [
      "Ismail (Ishmael)",
      "Dawud (David)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and ascend to the heavens?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Ibrahim (Abraham)",
      "Yunus (Jonah)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in metallurgy and blacksmithing?",
    options: [
      "Idris (Enoch)",
      "Dawud (David)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and speak to his people?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Ilyas (Elijah)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in mining and geology?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a staff to turn into a serpent and swallow the serpents of the sorcerers, and then turn back into a staff?",
    options: ["Musa (Moses)", "Harun (Aaron)", "Isa (Jesus)", "Yunus (Jonah)"],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in fishing and maritime activities?",
    options: [
      "Nuh (Noah)",
      "Yunus (Jonah)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and testify to the truth of Allah's message?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in cartography and map-making?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in forestry and lumbering?",
    options: [
      "Nuh (Noah)",
      "Idris (Enoch)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and ascend to the heavens, and then return to earth?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in glassmaking and ceramics?",
    options: [
      "Idris (Enoch)",
      "Shuayb (Jethro)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing the earth to swallow those who denied him?",
    options: ["Musa (Moses)", "Salih (Saleh)", "Hud (Hud)", "Shuayb (Jethro)"],
    answer: "Salih (Saleh)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in tanning and leatherwork?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a cloud to provide shade and rain?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in beekeeping and honey production?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and testify to the truth of Allah's message, and then die again?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in perfumery and incense making?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a mountain to be lifted and suspended in the air, and then returned to its place?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Isa (Jesus)",
      "Yunus (Jonah)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in dyeing and textile coloring?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and testify to the truth of Allah's message, and then return to the grave?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in woodcarving and sculpture?",
    options: [
      "Idris (Enoch)",
      "Dawud (David)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a valley to be filled with water and rain?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in jewelry making and gemology?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a dead person to come back to life and testify to the truth of Allah's message, and then return to the grave, and then come back to life again?",
    options: [
      "Isa (Jesus)",
      "Ilyas (Elijah)",
      "Yunus (Jonah)",
      "Ibrahim (Abraham)",
    ],
    answer: "Isa (Jesus)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in tailoring and clothing design?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a river to flow from a rock and provide water for his people?",
    options: [
      "Musa (Moses)",
      "Harun (Aaron)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
    ],
    answer: "Musa (Moses)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in papermaking and bookbinding?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a cloud to provide shade and rain, and the earth to produce vegetation?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in soap making and cosmetics?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in shipbuilding and naval architecture?",
    options: [
      "Nuh (Noah)",
      "Yunus (Jonah)",
      "Idris (Enoch)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in rope-making and knot-tying?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in basket weaving and wickerwork?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a cloud to provide shade and rain, and the earth to produce vegetation, and the mountains to move?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in masonry and stone carving?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in tanning and leatherwork, as well as tent-making?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },
  {
    question:
      "Who was the prophet who was given the miracle of causing a valley to be filled with water and rain, and the earth to produce vegetation, and the mountains to move, and the animals to gather?",
    options: [
      "Musa (Moses)",
      "Sulayman (Solomon)",
      "Yunus (Jonah)",
      "Isa (Jesus)",
    ],
    answer: "Sulayman (Solomon)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in hat-making and millinery?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in distillation and alcohol-free beverages?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in cheese-making and dairy products?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in candle-making and wax-working?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in glass-blowing and lamp-making?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in milling and flour production?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in baking and pastry-making?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in basket weaving and matting?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in shoe-making and leather-working?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in carpentry and woodworking?",
    options: [
      "Idris (Enoch)",
      "Dawud (David)",
      "Nuh (Noah)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Dawud (David)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in spinning and weaving?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in goat and sheep herding?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in horse-breeding and equestrian activities?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Ismail (Ishmael)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in calligraphy and manuscript illumination?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in olive oil production and pressing?",
    options: [
      "Idris (Enoch)",
      "Ayyub (Job)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in wine-making and viticulture?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in bee-keeping and honey production?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Sulayman (Solomon)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in boat-building and seafaring?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in falconry and bird training?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Sulayman (Solomon)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in pearl diving and oyster farming?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Nuh (Noah)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in perfume-making and aromatherapy?",
    options: [
      "Idris (Enoch)",
      "Luqman (Luqman)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in blacksmithing and metalworking?",
    options: [
      "Idris (Enoch)",
      "Dawud (David)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Dawud (David)",
  },

  {
    question:
      "Which prophet was known for his exceptional skills in glass-blowing and stained glass making?",
    options: [
      "Idris (Enoch)",
      "Sulayman (Solomon)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
  {
    question:
      "Which prophet was known for his exceptional skills in pottery and ceramic glazing?",
    options: [
      "Idris (Enoch)",
      "Nuh (Noah)",
      "Ismail (Ishmael)",
      "Dhul-Kifl (Ezekiel)",
    ],
    answer: "Idris (Enoch)",
  },
];

export default prophetsMessengersQuestions;
