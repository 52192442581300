import React from "react";
import ContentLayout from "../ContentLayout/ContentLayout";

const About = () => {
  const currentYear = new Date().getFullYear(); // Automatically get the current year

  return (
    <ContentLayout>
      <div className="bg-white shadow-md rounded-lg px-8 max-w-4xl mx-auto px-4 py-8">
        {" "}
        {/* Adjust the max-width as needed and center it */}
        <h1 className="text-xl lg:text-2xl font-bold text-center mb-6">
          About Muslim Nuggets
        </h1>
        <div className="text-lg text-left space-y-4 text-deeperPurple">
          {" "}
          {/* Increase spacing between paragraphs */}
          <p>
            Muslim Nuggets is an educational platform dedicated to enriching the
            spiritual and intellectual lives of Muslims around the world.
          </p>
          <p>
            With a focus on providing easy access to Islamic Trivia, Shareable
            Hadiths, Shareable Heartfelt Rabanna Duas, and wisdom from Muslim
            Legends, Muslim Nuggets aims to foster a deeper understanding and
            appreciation of Islamic teachings and heritage.
          </p>
          <p>
            My mission is to create an engaging, informative, and accessible
            space for learning and sharing the treasures of Islam.
          </p>
          <p>
            Muslim Nuggets is developed by
            <a
              href="https://olawhite.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-greenHover hover:text-blue-600 ml-1 text-base	" // Added margin left for space
            >
              Yusuph Ola-White
            </a>
            .
          </p>
        </div>
        <div className="text-center mt-8">
          {" "}
          {/* Centralize version and copyright */}
          <p className="text-md">Muslim Nuggets v.1.0</p>
          <p className="text-md">
            (c) {currentYear} - Muslim Nuggets. <br />
            All rights reserved.
          </p>
        </div>
      </div>
    </ContentLayout>
  );
};

export default About;
