const rabannaDuas = [
  {
    arabic:
      "رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الْآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'aatinaa fid-dunyaa hasanatanw wa fil-'aakhirati hasanatanw wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, give us in this world [that which is] good and in the Hereafter [that which is] good and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking Allah's blessings in both worlds and protection from the Hellfire.",
    reference: "Quran 2:201",
  },
  {
    arabic:
      "رَبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِن لَّدُنْكَ رَحْمَةً إِنَّكَ أَنْتَ الْوَهَّابُ",
    transliteration:
      "Rabbanaa laa tuzigh quloobanaa ba'da idh hadaitanaa wa hab lanaa mil ladunka rahmatan innaka Antan-Wahhaab",
    translation:
      "Our Lord, let not our hearts deviate after You have guided us and grant us from Yourself mercy. Indeed, You are the Bestower.",
    usage: "This dua is recited for seeking guidance and mercy from Allah.",
    reference: "Quran 3:8",
  },
  {
    arabic:
      "رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'innanaa 'aamannaa fagh-firlana dhunoobanaa wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking forgiveness from Allah and protection from the Hellfire.",
    reference: "Quran 3:16",
  },
  {
    arabic:
      "رَبَّنَا إِنَّكَ مَن تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنصَارٍ",
    transliteration:
      "Rabbanaa 'innaka man tudkhilin-naara faqad 'akhzaitahu wa maa lil-zzaalimeena min 'ansaar",
    translation:
      "Our Lord, indeed whoever You admit to the Fire - You have disgraced him, and for the wrongdoers there are no helpers.",
    usage:
      "This dua is recited as a reminder of the consequences of wrongdoing and the need for Allah's guidance and protection.",
    reference: "Quran 3:192",
  },
  {
    arabic:
      "رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَارِ",
    transliteration:
      "Rabbanaaa 'innanaa sami'naa munaadiyany-yunaadee lil-'eemaani 'an 'aaminu biRabbikum fa'aamannaa Rabbanaa fagh-firlana dhunoobanaa wa kaffir 'anaa sayyi'aatinaa wa tawaffanaa ma'al-'abraar",
    translation:
      "Our Lord, indeed we have heard a caller calling to faith, [saying], 'Believe in your Lord,' and we have believed. Our Lord, so forgive us our sins and remove from us our misdeeds and cause us to die with the righteous.",
    usage:
      "This dua is recited for seeking forgiveness, removal of misdeeds, and a virtuous death.",
    reference: "Quran 3:193",
  },
  {
    arabic:
      "رَبَّنَا وَآتِنَا مَا وَعَدتَّنَا عَلَى رُسُلِكَ وَلَا تُخْزِنَا يَوْمَ الْقِيَامَةِ إِنَّكَ لَا تُخْلِفُ الْمِيعَادَ",
    transliteration:
      "Rabbana wa aatina maa wa'adtanaa 'alaa Rusulika wa laa tukhzinaa Yawmal-Qiyaamah, innaka laa tukhliful-mee'aad",
    translation:
      "Our Lord, and grant us what You promised us through Your messengers and do not disgrace us on the Day of Resurrection. Indeed, You do not fail in [Your] promise.",
    usage:
      "This dua is recited for seeking the fulfillment of Allah's promises and avoiding disgrace on the Day of Judgment.",
    reference: "Quran 3:194",
  },
  {
    arabic:
      "رَبَّنَا إِنَّكَ مَن تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنصَارٍ",
    transliteration:
      "Rabbanaaa 'innaka man tudkhilin-naara faqad 'akhzaitahu wa maa lil-zzaalimeena min 'ansaar",
    translation:
      "Our Lord, indeed whoever You admit to the Fire - You have disgraced him, and for the wrongdoers there are no helpers.",
    usage:
      "This dua is recited as a reminder of the consequences of wrongdoing and the need for Allah's guidance and protection.",
    reference: "Quran 3:192",
  },
  {
    arabic:
      "رَبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'innanaa 'aamannaa fagh-firlana dhunoobanaa wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking forgiveness from Allah and protection from the Hellfire.",
    reference: "Quran 3:16",
  },
  {
    arabic:
      "رَبَّنَا مَا خَلَقْتَ هَذَا بَاطِلًا سُبْحَانَكَ فَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbana maa khalaqta haadzaa baatilan Subhaanaka faqinaa 'adzaaban-naar",
    translation:
      "Our Lord, You did not create this aimlessly; exalted are You [above such a thing]; then protect us from the punishment of the Fire.",
    usage:
      "This dua is recited as a declaration of faith in Allah's wisdom and for seeking His protection from the Hellfire.",
    reference: "Quran 3:191",
  },
  {
    arabic:
      "رَبَّنَا إِنَّنَا سَمِعْنَا مُنَادِيًا يُنَادِي لِلْإِيمَانِ أَنْ آمِنُوا بِرَبِّكُمْ فَآمَنَّا رَبَّنَا فَاغْفِرْ لَنَا ذُنُوبَنَا وَكَفِّرْ عَنَّا سَيِّئَاتِنَا وَتَوَفَّنَا مَعَ الْأَبْرَارِ",
    transliteration:
      "Rabbanaaa 'innanaa sami'naa munaadiyany-yunaadee lil-'eemaani 'an 'aaminu biRabbikum fa'aamannaa Rabbanaa fagh-firlana dhunoobanaa wa kaffir 'anaa sayyi'aatinaa wa tawaffanaa ma'al-'abraar",
    translation:
      "Our Lord, indeed we have heard a caller calling to faith, [saying], 'Believe in your Lord,' and we have believed. Our Lord, so forgive us our sins and remove from us our misdeeds and cause us to die with the righteous.",
    usage:
      "This dua is recited for seeking forgiveness, removal of misdeeds, and a virtuous death.",
    reference: "Quran 3:193",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّكَ جَامِعُ النَّاسِ لِيَوْمٍ لَّا رَيْبَ فِيهِ إِنَّ اللَّهَ لَا يُخْلِفُ الْمِيعَادَ",
    transliteration:
      "Rabbana 'innaka Jami'un-naasi li-Yawmil-laa rayba feehi 'innnal-laaha laa yukh-liful-mee'aad",
    translation:
      "Our Lord, indeed You are the Gatherer of the people for a Day about which there is no doubt. Indeed, Allah does not fail in His promise.",
    usage:
      "This dua is recited as a reminder of the Day of Resurrection and Allah's promise.",
    reference: "Quran 3:9",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'innanaa aamannaa fagh-firlana dhunoobanaa wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking forgiveness from Allah and protection from the Hellfire.",
    reference: "Quran 3:16",
  },
  {
    arabic:
      "رَّبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِن لَّدُنكَ رَحْمَةً إِنَّكَ أَنتَ الْوَهَّابُ",
    transliteration:
      "Rabbana laa tuzigh quloobana ba'da 'idh hadaitana wahab-lana mil-ladunka rahmatan 'innaka 'Antan-Wahhabu",
    translation:
      "Our Lord, let not our hearts deviate after You have guided us and grant us from Yourself mercy. Indeed, You are the Bestower.",
    usage: "This dua is recited for seeking guidance and mercy from Allah.",
    reference: "Quran 3:8",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّنَا آمَنَّا بِمَا أَنزَلْتَ وَاتَّبَعْنَا الرَّسُولَ فَاكْتُبْنَا مَعَ الشَّاهِدِينَ",
    transliteration:
      "Rabbana 'innana aamanna bima 'anzalta wat-taba'nar-Rasoola fak-tubna ma'ash-shahideen",
    translation:
      "Our Lord, we have believed in what You revealed and have followed the Messenger, so register us among the witnesses.",
    usage:
      "This dua is recited for affirming belief in Allah's revelation and the Prophet, and seeking to be counted among the righteous witnesses.",
    reference: "Quran 3:53",
  },
  {
    arabic:
      "رَّبَّنَا آمَنَّا فَاغْفِرْ لَنَا وَارْحَمْنَا وَأَنْتَ خَيْرُ الرَّاحِمِينَ",
    transliteration:
      "Rabbana aamanna fagh-firlana warkamna wa 'Antan-Khayrul-Rahimeen",
    translation:
      "Our Lord, we have believed, so forgive us and have mercy upon us, and You are the best of the merciful.",
    usage: "This dua is recited for seeking forgiveness and mercy from Allah.",
    reference: "Quran 23:109",
  },
  {
    arabic: "رَّبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَتَوَفَّنَا مُسْلِمِينَ",
    transliteration: "Rabbana 'afrigh 'alayna sabranw wa tawaffana Muslimeen",
    translation:
      "Our Lord, pour upon us patience and let us die as Muslims [in submission to You].",
    usage:
      "This dua is recited for seeking patience and a virtuous death as a Muslim.",
    reference: "Quran 7:126",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وَانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbanaghfir lana dhunubana wa 'israfana fee 'amrina wa thabbit 'aqdamana wansurna 'alal-qawmil-kafireen",
    translation:
      "Our Lord, forgive us our sins and the excess [committed] in our affairs and plant firmly our feet and give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, steadfastness, and victory over the disbelievers.",
    reference: "Quran 3:147",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbanagh-firlana dhunuubana wa israfana fee amrina wa thabbit aqdamana wansurna 'alal-qawmil-kafireen",
    translation:
      "Our Lord, forgive us our sins and the excess [committed] in our affairs and plant firmly our feet and give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, steadfastness, and victory over the disbelievers.",
    reference: "Quran 3:147",
  },
  {
    arabic:
      "رَّبَّنَا لَا تُؤَاخِذْنَا إِن نَّسِينَا أَوْ أَخْطَأْنَا رَّبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِن قَبْلِنَا رَّبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا أَنتَ مَوْلَانَا فَانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbana la tu'akhidhna 'in naseena 'aw 'akhta'na Rabbana wa la tahmil 'alayna 'isran kama hamaltahu 'alal-ladhina min qablina Rabbana wa la tuham-milna ma la taqata lana bihi wa'fu 'anna wagh-firlana warkamna 'Anta Mawlana fansurna 'alal-qawmil-kafireen",
    translation:
      "Our Lord, do not impose blame upon us if we have forgotten or erred. Our Lord, and lay not upon us a burden like that which You laid upon those before us. Our Lord, and burden us not with that which we have no ability to bear. And pardon us; and forgive us; and have mercy upon us. You are our Protector, so give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, ease of burden, mercy, and victory over the disbelievers.",
    reference: "Quran 2:286",
  },
  {
    arabic:
      "رَّبَّنَا آتِنَا مِن لَّدُنكَ رَحْمَةً وَهَيِّئْ لَنَا مِنْ أَمْرِنَا رَشَدًا",
    transliteration:
      "Rabbana atina mil-ladunka rahmatan wa hayyi' lana min 'amrina rashadan",
    translation:
      "Our Lord, grant us from Yourself mercy and prepare for us from our affair right guidance.",
    usage: "This dua is recited for seeking mercy and guidance from Allah.",
    reference: "Quran 18:10",
  },
  {
    arabic:
      "رَّبَّنَا اصْرِفْ عَنَّا عَذَابَ جَهَنَّمَ إِنَّ عَذَابَهَا كَانَ غَرَامًا",
    transliteration:
      "Rabbanas-rif 'annaa 'adzaaba Jahannama 'inna 'adzaabahaa kaana gharaamaa",
    translation:
      "Our Lord, avert from us the punishment of Hell. Indeed, its punishment is ever adhering.",
    usage:
      "This dua is recited for seeking protection from the punishment of Hellfire.",
    reference: "Quran 25:65",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّكَ مَن تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنصَارٍ",
    transliteration:
      "Rabbanaa 'innaka man tudkhilin-naara faqad 'akhzaitahu wa maa lil-zzaalimeena min 'ansaar",
    translation:
      "Our Lord, indeed whoever You admit to the Fire - You have disgraced him, and for the wrongdoers there are no helpers.",
    usage:
      "This dua is recited as a reminder of the consequences of wrongdoing and the need for Allah's guidance and protection.",
    reference: "Quran 3:192",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'innanaa 'aamannaa fagh-firlana dhunoobanaa wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking forgiveness from Allah and protection from the Hellfire.",
    reference: "Quran 3:16",
  },
  {
    arabic: "رَّبَّنَا أَخْرِجْنَا مِنْهَا فَإِنْ عُدْنَا فَإِنَّا ظَالِمُونَ",
    transliteration: "Rabbana 'akhrijna minha fa'in 'udnaa fa'innaa zaalimoon",
    translation:
      "Our Lord, remove us from it, and if we return [to evil], we will indeed be wrongdoers.",
    usage: "This dua is recited for seeking Allah's help to avoid wrongdoing.",
    reference: "Quran 23:107",
  },
  {
    arabic: "رَّبَّنَا آمَنَّا فَاكْتُبْنَا مَعَ الشَّاهِدِينَ",
    transliteration: "Rabbanaa aamannaa fak-tubna ma'ash-shaa-hideen",
    translation:
      "Our Lord, we have believed, so enroll us among those who testify.",
    usage:
      "This dua is recited for affirming belief in Allah and seeking to be counted among the righteous witnesses.",
    reference: "Quran 5:83",
  },
  {
    arabic: "رَّبَّنَا أَفْرِغْ عَلَيْنَا صَبْرًا وَتَوَفَّنَا مُسْلِمِينَ",
    transliteration:
      "Rabbanaaa 'afrigh 'alainaa sabranw wa tawaffanaa Muslimeen",
    translation:
      "Our Lord, pour upon us patience and let us die as Muslims [in submission to You].",
    usage:
      "This dua is recited for seeking patience and a virtuous death as a Muslim.",
    reference: "Quran 7:126",
  },
  {
    arabic: "رَّبَّنَا لَا تَجْعَلْنَا فِتْنَةً لِّلْقَوْمِ الظَّالِمِينَ",
    transliteration: "Rabbana laa taj'alnaa fitnatal-lil-qawmiz-zaalimeen",
    translation:
      "Our Lord, make us not [objects of torment] for the people who have wronged.",
    usage:
      "This dua is recited for seeking protection from being tested or tempted by wrongdoers.",
    reference: "Quran 10:85",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّكَ مَن تُدْخِلِ النَّارَ فَقَدْ أَخْزَيْتَهُ وَمَا لِلظَّالِمِينَ مِنْ أَنصَارٍ",
    transliteration:
      "Rabbanaa 'innaka man tudkhilin-naara faqad 'akhzaitahu wa maa lil-zzaalimeena min 'ansaar",
    translation:
      "Our Lord, indeed whoever You admit to the Fire - You have disgraced him, and for the wrongdoers there are no helpers.",
    usage:
      "This dua is recited as a reminder of the consequences of wrongdoing and the need for Allah's guidance and protection.",
    reference: "Quran 3:192",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لَنَا وَلِإِخْوَانِنَا الَّذِينَ سَبَقُونَا بِالْإِيمَانِ وَلَا تَجْعَلْ فِي قُلُوبِنَا غِلًّا لِّلَّذِينَ آمَنُوا رَبَّنَا إِنَّكَ رَءُوفٌ رَّحِيمٌ",
    transliteration:
      "Rabbanaghfir lanaa wa li'ikhwaaninal-lazeena sabaqoonaa bil-'eemaani wa laa taj'al fee quloobinaaa ghillal-lillazeena 'aamanoo Rabbanaa 'innaka Ra'oofur-Raheem",
    translation:
      "Our Lord, forgive us and our brothers who preceded us in faith and put not in our hearts [any] resentment toward those who have believed. Our Lord, indeed You are Kind and Merciful.",
    usage:
      "This dua is recited for seeking forgiveness for oneself and other believers, and for removing any resentment or ill-feeling towards fellow believers.",
    reference: "Quran 59:10",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbanagh-firlana dhunuubana wa israfana fee amrina wa thabbit aqdamana wansurna 'alal-qawmil-kafireen",
    translation:
      "Our Lord, forgive us our sins and the excess [committed] in our affairs and plant firmly our feet and give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, steadfastness, and victory over the disbelievers.",
    reference: "Quran 3:147",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّكَ تَعْلَمُ مَا نُخْفِي وَمَا نُعْلِنُ وَمَا يَخْفَىٰ عَلَى اللَّهِ مِن شَيْءٍ فِي الْأَرْضِ وَلَا فِي السَّمَاءِ",
    transliteration:
      "Rabbanaa 'innaka ta'lamu maa nukhfee wa maa nu'linu wa maa yakhfaa 'alallaahi min shai'in fil-'arzi wa laa fis-samaa'i",
    translation:
      "Our Lord, indeed You know what we conceal and what we make public - and nothing is hidden from Allah on the earth or in the heaven.",
    usage:
      "This dua is recited as a declaration of faith in Allah's all-encompassing knowledge.",
    reference: "Quran 14:38",
  },
  {
    arabic:
      "رَّبَّنَا آتِنَا مِن لَّدُنكَ رَحْمَةً وَهَيِّئْ لَنَا مِنْ أَمْرِنَا رَشَدًا",
    transliteration:
      "Rabbanaa 'aatinaa mil-ladunka rahmatan-wa hayyi' lanaa min 'amrinaa rashadaa",
    translation:
      "Our Lord, grant us from Yourself mercy and prepare for us from our affair right guidance.",
    usage: "This dua is recited for seeking mercy and guidance from Allah.",
    reference: "Quran 18:10",
  },
  {
    arabic:
      "رَّبَّنَا إِنَّنَا آمَنَّا فَاغْفِرْ لَنَا ذُنُوبَنَا وَقِنَا عَذَابَ النَّارِ",
    transliteration:
      "Rabbanaa 'innanaa 'aamannaa fagh-firlana dhunoobanaa wa qinaa 'adhaaban-naar",
    translation:
      "Our Lord, indeed we have believed, so forgive us our sins and protect us from the punishment of the Fire.",
    usage:
      "This dua is recited for seeking forgiveness from Allah and protection from the Hellfire.",
    reference: "Quran 3:16",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لِي وَلِوَالِدَيَّ وَلِلْمُؤْمِنِينَ يَوْمَ يَقُومُ الْحِسَابُ",
    transliteration:
      "Rabbanaghfir lee wa liwaalidayya wa lilmu'mineena Yawma yaqoomul-hisaab",
    translation:
      "Our Lord, forgive me and my parents and the believers on the Day when the account is established.",
    usage:
      "This dua is recited for seeking forgiveness for oneself, parents, and all believers on the Day of Judgment.",
    reference: "Quran 14:41",
  },
  {
    arabic:
      "رَّبَّنَا لَا تُزِغْ قُلُوبَنَا بَعْدَ إِذْ هَدَيْتَنَا وَهَبْ لَنَا مِن لَّدُنْكَ رَحْمَةً إِنَّكَ أَنْتَ الْوَهَّابُ",
    transliteration:
      "Rabbana laa tuzigh quloobanaa ba'da 'idh hadaitanaa wa hab-lana mil-ladunka rahmatan 'innaka 'Antan-Wahhaab",
    translation:
      "Our Lord, let not our hearts deviate after You have guided us and grant us from Yourself mercy. Indeed, You are the Bestower.",
    usage: "This dua is recited for seeking guidance and mercy from Allah.",
    reference: "Quran 3:8",
  },
  {
    arabic:
      "رَّبَّنَا اغْفِرْ لَنَا ذُنُوبَنَا وَإِسْرَافَنَا فِي أَمْرِنَا وَثَبِّتْ أَقْدَامَنَا وانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbanagh-firlana dhunuubana wa israfana fee amrina wa thabbit aqdamana wansurna 'alal-qawmil-kafireen",
    translation:
      "Our Lord, forgive us our sins and the excess [committed] in our affairs and plant firmly our feet and give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, steadfastness, and victory over the disbelievers.",
    reference: "Quran 3:147",
  },
  {
    arabic:
      "رَّبَّنَا لَا تُؤَاخِذْنَا إِن نَّسِينَا أَوْ أَخْطَأْنَا رَبَّنَا وَلَا تَحْمِلْ عَلَيْنَا إِصْرًا كَمَا حَمَلْتَهُ عَلَى الَّذِينَ مِن قَبْلِنَا رَبَّنَا وَلَا تُحَمِّلْنَا مَا لَا طَاقَةَ لَنَا بِهِ وَاعْفُ عَنَّا وَاغْفِرْ لَنَا وَارْحَمْنَا أَنتَ مَوْلَانَا فَانصُرْنَا عَلَى الْقَوْمِ الْكَافِرِينَ",
    transliteration:
      "Rabbana laa tu'akhidh-naa 'in-naseeyna 'aw 'akhta'naa Rabbana wa laa tahmil 'alaiynaa 'isran kamaa hamaltahu 'alal-lazeena min qablinaa Rabbana wa laa tuham-milnaa maa laa taaqata lanaa bih; wa'fu 'annaa waghfir lanaa warkamnaa 'Anta Mawlaanaa fansurnaa 'alal-qawmil-kaafireen",
    translation:
      "Our Lord, do not impose blame upon us if we have forgotten or erred. Our Lord, and lay not upon us a burden like that which You laid upon those before us. Our Lord, and burden us not with that which we have no ability to bear. And pardon us; and forgive us; and have mercy upon us. You are our Protector, so give us victory over the disbelieving people.",
    usage:
      "This dua is recited for seeking forgiveness, ease of burden, mercy, and victory over the disbelievers.",
    reference: "Quran 2:286",
  },
  {
    arabic:
      "رَّبَّنَا وَأَدْخِلْهُمْ جَنَّاتِ عَدْنٍ الَّتِي وَعَدتَّهُم وَمَن صَلَحَ مِنْ آبَائِهِمْ وَأَزْوَاجِهِمْ وَذُرِّيَّاتِهِمْ إِنَّكَ أَنتَ الْعَزِيزُ الْحَكِيمُ",
    transliteration:
      "Rabbana wa'adkhilhum Jannaati 'Adnin-illati wa'attahum wa man salaha min 'aabaa'ihim wa'azwaajihim wa dhurriyyaatihim 'innaka 'Antan-'Azeezul-Hakeem",
    translation:
      "Our Lord, and admit them to gardens of perpetual residence which You have promised them and whoever was righteous among their fathers, their spouses and their offspring. Indeed, You are the Exalted in Might, the Wise.",
    usage:
      "This dua is recited for seeking admission to Paradise for oneself, family, and righteous people.",
    reference: "Quran 40:8",
  },
  {
    arabic:
      "رَّبَّنَا أَتْمِمْ لَنَا نُورَنَا وَاغْفِرْ لَنَا إِنَّكَ عَلَىٰ كُلِّ شَيْءٍ قَدِيرٌ",
    transliteration:
      "Rabbana 'atmim lanaa nooranaa waghfir lanaa 'innaka 'alaa kulli shai'in Qadeer",
    translation:
      "Our Lord, perfect for us our light and forgive us. Indeed, You are over all things competent.",
    usage:
      "This dua is recited for seeking guidance, forgiveness, and Allah's help in all matters.",
    reference: "Quran 66:8",
  },
  {
    arabic:
      "رَّبَّنَا اصْرِفْ عَنَّا عَذَابَ جَهَنَّمَ إِنَّ عَذَابَهَا كَانَ غَرَامًا",
    transliteration:
      "Rabbanas-rif 'annaa 'adzaaba Jahannama 'inna 'adzaabahaa kaana gharaamaa",
    translation:
      "Our Lord, avert from us the punishment of Hell. Indeed, its punishment is ever adhering.",
    usage:
      "This dua is recited for seeking protection from the punishment of Hellfire.",
    reference: "Quran 25:65",
  },
];

export default rabannaDuas;
