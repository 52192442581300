// Gradient.js in the Background directory
import { useState, useCallback } from "react";

const gradients = [
  "bg-gradient-indigo-purple",
  "bg-gradient-dark-cyan-teal",
  "bg-gradient-dark-magenta-hot-pink",
  "bg-gradient-tomato-crimson",
  "bg-gradient-forest-green-lime-green",
  "bg-gradient-dark-slate-blue-slate-blue",
  "bg-gradient-saddle-brown-peru",
  "bg-gradient-dark-slate-gray-teal",
  "bg-gradient-dark-red-fire-brick",
  "bg-gradient-saddle-brown-sienna",
  "bg-gradient-dark-red-orange-red",
  "bg-gradient-sea-green-green",
  "bg-gradient-olive-drab-dark-olive-green",
  "bg-gradient-dark-slate-gray-dim-gray",
  "bg-gradient-dark-slate-blue-dark-olive-green",
  "bg-gradient-blue-violet-dark-slate-blue",
  "bg-gradient-slate-blue-midnight-blue",
  "bg-gradient-dark-slate-gray-dark-red",
  "bg-gradient-dark-green-saddle-brown",
  "bg-gradient-dark-blue-dark-magenta",
];

// This function now becomes a custom hook
export const useGradient = () => {
  const getRandomGradient = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * gradients.length);
    return gradients[randomIndex];
  }, []);

  const [gradient, setGradient] = useState(getRandomGradient());

  // Expose a method to force update the gradient
  const updateGradient = useCallback(() => {
    setGradient(getRandomGradient());
  }, [getRandomGradient]);

  return [gradient, updateGradient];
};
