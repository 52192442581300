import React, { useState, useEffect, useRef } from "react";
import gearIcon from "../../assets/gear.png";

const Settings = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const settingsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const saveSettings = () => {
    setIsSettingsOpen(false);
    // Save userName or other settings logic can go here
  };

  return (
    <>
      <button
        onClick={() => setIsSettingsOpen(!isSettingsOpen)}
        className="z-20"
      >
        <img src={gearIcon} alt="Settings" className="h-6" />
      </button>
      {isSettingsOpen && (
        <div
          ref={settingsRef}
          style={{
            top: "0.2rem",
            opacity: isSettingsOpen ? 1 : 0,
            transform: isSettingsOpen ? "translateX(0)" : "translateX(-100%)",
            transition: "opacity 300ms ease, transform 300ms ease",
            display: isSettingsOpen ? "block" : "none",
          }}
          className="absolute top-full right-0 mt-0 w-64 bg-white p-4 rounded-l-lg shadow-md z-10"
        >
          <div className="mt-4">
            <input
              type="text"
              placeholder="Type your name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="p-2 border rounded w-full"
            />
          </div>
          <button
            onClick={saveSettings}
            className="mt-4 bg-deeperPurple hover:bg-greenHover text-white font-bold py-2 px-4 rounded w-full"
          >
            Save Settings
          </button>
        </div>
      )}
    </>
  );
};

export default Settings;
