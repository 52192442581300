import React, { useState, useEffect, useCallback } from "react";
import ContentLayout from "../ContentLayout/ContentLayout";
import legendsNuggets from "../Data/legendsNuggets";
import html2canvas from "html2canvas";
import logoIcon from "../../assets/logo.png";
import { useGradient } from "../Background/Gradient";
import { GrPrevious, GrNext } from "react-icons/gr";
import { LuChevronFirst, LuChevronLast } from "react-icons/lu";
import { FiDownload } from "react-icons/fi";
import { LuShare2 } from "react-icons/lu";
import { MdOutlineAutorenew } from "react-icons/md";

const ITEMS_PER_PAGE = 5;
const MAX_VISIBLE_PAGES = 3;

const Nuggets = () => {
  const [nugget, setNugget] = useState({});
  const [gradient, updateGradient] = useGradient();
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(legendsNuggets.length / ITEMS_PER_PAGE);

  const getPaginationGroup = () => {
    let start =
      Math.floor((currentPage - 1) / MAX_VISIBLE_PAGES) * MAX_VISIBLE_PAGES;
    return new Array(Math.min(MAX_VISIBLE_PAGES, totalPages - start))
      .fill()
      .map((_, idx) => start + idx + 1);
  };

  const goToNext = () =>
    setCurrentPage((page) => Math.min(page + 1, totalPages));
  const goToPrevious = () => setCurrentPage((page) => Math.max(page - 1, 1));
  const goToFirst = () => setCurrentPage(1);
  const goToLast = () => setCurrentPage(totalPages);

  const generateNewNugget = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * legendsNuggets.length);
    setNugget(legendsNuggets[randomIndex]);
    updateGradient();
  }, [updateGradient]);

  useEffect(() => {
    generateNewNugget();
  }, [generateNewNugget]);

  const setPage = (page) => setCurrentPage(page);

  const viewNugget = (item) => {
    setNugget(item);
    updateGradient();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const currentItems = legendsNuggets.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const shareNugget = () => {
    html2canvas(document.querySelector("#nuggetContent"), { scale: 4 }).then(
      (canvas) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], "nugget.png", { type: "image/png" });

          // Check if the Web Share API is available and supports sharing files
          if (
            navigator.share &&
            navigator.canShare &&
            navigator.canShare({ files: [file] })
          ) {
            navigator
              .share({
                files: [file],
                title: "Muslim Legends Nugget",
                text: "Check out this interesting nugget!",
              })
              .then(() => console.log("Share was successful."))
              .catch((error) => console.error("Sharing failed", error));
          } else {
            // If Web Share API is not supported or cannot share files, upload to Cloudinary
            const formData = new FormData();
            formData.append("file", file);
            formData.append("upload_preset", "y7zkjnpy");

            const uploadURL = `https://api.cloudinary.com/v1_1/dndncnjcq/image/upload`;

            fetch(uploadURL, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.secure_url) {
                  // Use the image URL for sharing or copying to clipboard
                  alert(
                    "Sharing is not supported on this browser. Image URL copied to clipboard."
                  );
                  navigator.clipboard.writeText(data.secure_url).then(() => {
                    alert("Image URL copied to clipboard.");
                  });
                } else {
                  throw new Error("Upload failed, no URL returned.");
                }
              })
              .catch((error) => {
                console.error("Failed to upload image to Cloudinary", error);
                alert("An error occurred while uploading the image.");
              });
          }
        }, "image/png");
      }
    );
  };

  const downloadNuggetImage = () => {
    html2canvas(document.querySelector("#nuggetContent"), { scale: 4 }).then(
      (canvas) => {
        // Generate a unique identifier for the filename using just the current timestamp
        const timestamp = Date.now(); // Current timestamp for uniqueness
        const uniqueFilename = `muslim-legend-nugget-${timestamp}.png`;

        // Create a link and set the URL using the canvas data
        const link = document.createElement("a");
        link.download = uniqueFilename;
        link.href = canvas.toDataURL();
        link.click(); // Trigger the download
      }
    );
  };

  return (
    <ContentLayout>
      <div
        id="nuggetContent"
        className={`overflow-auto flex flex-col items-center text-center p-5 rounded text-white ${gradient} w-full md:max-w-[350px] mx-auto max-h-[calc(100vh-100px)]`}
      >
        <img
          src={logoIcon}
          alt="Muslim Legends Nugget"
          className="w-16 h-16 mb-3"
        />{" "}
        <div className="text-lg">{nugget.legend} once said,</div>
        <div className="m-0.5">__________________</div>
        <div
          style={{
            fontFamily: "'Handlee', cursive",
            fontSize: "1.2rem",
            fontWeight: 600,
          }}
        >
          {nugget.nugget}
        </div>
        <div className="m-0.5">__________________</div>
        <div className="text- mb-1">{nugget.message}</div>
        <div className="mt-2 text-sm">muslimnuggets.com | @muslimnuggets</div>
      </div>
      <div className="flex justify-center space-x-4 my-4">
        <button
          onClick={downloadNuggetImage}
          className="p-2 px-5 bg-downloadColor hover:bg-deeperPurple text-white rounded"
          title="Download"
        >
          <FiDownload size="26px" />
        </button>
        {/* New Hadith Button */}
        <button
          onClick={generateNewNugget}
          className="p-2 px-5 bg-green-700 hover:bg-deeperPurple text-white rounded"
          title="New Nugget"
        >
          <MdOutlineAutorenew size="26px" />
        </button>
        {/* Share Button */}
        <button
          onClick={shareNugget}
          className="p-2 px-5 bg-blue-700 hover:bg-deeperPurple text-white rounded"
          title="Share"
        >
          <LuShare2 size="26px" />
        </button>

        {/* New Download button */}
      </div>
      <div>
        <h2 className="text-center text-lg font-semibold mb-3">
          Discover Muslim Legends' Nuggets
        </h2>
        <ul className="md:max-w-[600px] mx-auto">
          {currentItems.map((item, index) => (
            <li
              key={index}
              onClick={() => viewNugget(item)}
              className="cursor-pointer p-3 text-deeperPurple hover:bg-deeperPurple hover:text-white bg-white rounded shadow-md my-1"
            >
              {item.nugget.substring(0, 80)}
              <span className="font-bold"> - {item.legend}</span>
            </li>
          ))}
        </ul>
        <h3 className="text-center text-red-600 font-normal mb-2 mt-2">
          Repeatedly click on any Nugget to change the background.
        </h3>
      </div>
      <div className="flex justify-center mt-4 items-center mb-10">
        <button
          onClick={goToFirst}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <LuChevronFirst size="28px" />
        </button>
        <button
          onClick={goToPrevious}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <GrPrevious />
        </button>
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={() => setPage(item)}
            className={`px-3 py-1 mx-1 rounded text-lg ${
              currentPage === item
                ? "bg-deeperPurple text-white hover:bg-deeperPurple hover:text-white"
                : "bg-gray-200 hover:bg-deeperPurple hover:text-white"
            }`}
          >
            {item}
          </button>
        ))}

        <button
          onClick={goToNext}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <GrNext />
        </button>
        <button
          onClick={goToLast}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <LuChevronLast size="28px" />
        </button>
      </div>
    </ContentLayout>
  );
};

export default Nuggets;
