import React from "react";
import { Link } from "react-router-dom";
import ContentLayout from "../ContentLayout/ContentLayout";
import rabannaIcon from "../../assets/rabanna-color.png";
import nuggetsIcon from "../../assets/nuggets-color.png";

const MoreApps = () => {
  return (
    <ContentLayout>
      <div className="px-2 py-4">
        <h1 className="font-bold text-center w-full mb-3 text-2xl">
          Share to Promote Islam
        </h1>
        <h3 className="text-center w-full mb-6 text-base px-2">
          Generate, Download & Share Quranic Duas, Muslim Legends Quotes and
          many more with your friends to promote Islam.
        </h3>
        <div className="grid grid-cols-2 gap-0.5 md:gap-10 text-center">
          <Link to="/rabanna" className="p-3">
            <div className="block bg-white shadow-lg rounded-lg hover:bg-lighterPurple transition-all flex flex-col items-center p-4">
              <img src={rabannaIcon} alt="Rabanna" className="h-12 mb-2" />
              <span className="text-deeperPurple font-base">Rabanna Duas</span>
            </div>
          </Link>
          <Link to="/nuggets" className="p-3">
            <div className="block bg-white shadow-lg rounded-lg hover:bg-lighterPurple transition-all flex flex-col items-center p-4">
              <img src={nuggetsIcon} alt="Nuggets" className="h-12 mb-2" />
              <span className="text-deeperPurple font-base">Legend Quotes</span>
            </div>
          </Link>
          {/* Additional links can be added here, maintaining two columns layout */}
        </div>
      </div>
    </ContentLayout>
  );
};

export default MoreApps;
