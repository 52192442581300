// src/components/Header/Header.js
import React, { useState } from "react";
import Menu from "./Menu";
import Logo from "./Logo";
import Settings from "./Settings";
import menuIcon from "../../assets/menu.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="flex items-start justify-between lg:w-1/2 mx-auto p-4 bg-deeperPurple relative">
      <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="z-20">
        <img src={menuIcon} alt="Menu" className="h-6" />
      </button>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Logo />
      <Settings />
    </header>
  );
};

export default Header;
