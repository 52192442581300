import React from "react";
import { useParams, Link } from "react-router-dom";
import ContentLayout from "../ContentLayout/ContentLayout";
import quranChapters from "./quranChapters"; // Ensure this path is correct

const ChapterInsight = () => {
  let { chapterSlug } = useParams();

  const formatTitleForURL = (title) => {
    return title
      .toLowerCase()
      .replace(/<[^>]+>/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/-{2,}/g, "-");
  };

  const chapter = quranChapters.find(
    (chapter) => formatTitleForURL(chapter.title) === chapterSlug
  );

  if (!chapter) {
    return (
      <ContentLayout>
        <div className="p-5">Chapter not found.</div>
      </ContentLayout>
    );
  }

  const currentIndex = quranChapters.indexOf(chapter);
  const prevChapterSlug =
    currentIndex > 0
      ? formatTitleForURL(quranChapters[currentIndex - 1].title)
      : null;
  const nextChapterSlug =
    currentIndex < quranChapters.length - 1
      ? formatTitleForURL(quranChapters[currentIndex + 1].title)
      : null;

  return (
    <ContentLayout>
      <div className="bg-white p-8 rounded-lg text-justify">
        <div className="mb-2 text-left">
          <h2 dangerouslySetInnerHTML={{ __html: chapter.title }}></h2>
        </div>
        <div className="mb-5">
          <p dangerouslySetInnerHTML={{ __html: chapter.description }}></p>
        </div>
        <div className="mb-5">
          <p
            dangerouslySetInnerHTML={{ __html: chapter.context_revelation }}
          ></p>
        </div>
        <div className="mb-5">
          <p dangerouslySetInnerHTML={{ __html: chapter.themes_structure }}></p>
        </div>
        <div className="mb-5">
          <p
            dangerouslySetInnerHTML={{
              __html: chapter.spiritual_practical_guidance,
            }}
          ></p>
        </div>
        <div className="mb-5">
          <p
            dangerouslySetInnerHTML={{ __html: chapter.impact_significance }}
          ></p>
        </div>
        <div className="mb-5">
          <p
            dangerouslySetInnerHTML={{ __html: chapter.application_relevance }}
          ></p>
        </div>
      </div>
      {/* Add more content sections as needed, each wrapped in a <div className="mb-2"> */}
      <div className="flex justify-between my-4 px-4">
        {prevChapterSlug && (
          <Link
            to={`/quranInsights/${prevChapterSlug}`}
            className="text-white bg-deeperPurple rounded px-4 py-2 hover:bg-greenHover"
          >
            Chapter {currentIndex} {/* Previous Chapter Number */}
          </Link>
        )}
        {nextChapterSlug && (
          <Link
            to={`/quranInsights/${nextChapterSlug}`}
            className="text-white bg-deeperPurple rounded px-4 py-2 hover:bg-greenHover"
          >
            Chapter {currentIndex + 2}{" "}
            {/* Next Chapter Number (currentIndex is 0-based) */}
          </Link>
        )}
      </div>
    </ContentLayout>
  );
};

export default ChapterInsight;
