import React, { useState, useEffect } from "react";
import ContentLayout from "../ContentLayout/ContentLayout";
import islamicFiguresQuestions from "./TriviaQuestions/islamicFiguresQuestions";
import islamicGeographyQuestions from "./TriviaQuestions/islamicGeographyQuestions";
import islamicHistoryQuestions from "./TriviaQuestions/islamicHistoryQuestions";
import islamicLawsQuestions from "./TriviaQuestions/islamicLawsQuestions";
import islamicPracticesQuestions from "./TriviaQuestions/islamicPracticesQuestions";
import islamicRamadanQuestions from "./TriviaQuestions/islamicRamadanQuestions";
import islamicTextsQuestions from "./TriviaQuestions/islamicTextsQuestions";
import prophetsMessengersQuestions from "./TriviaQuestions/prophetsMessengersQuestions";
import TriviaResultModal from "./TriviaResultModal"; // import the ResultModal component

const categories = [
  "Islamic Prophets",
  "Islamic Figures",
  "Islamic Geography",
  "Islamic History",
  "Islamic Laws",
  "Islamic Practices",
  "Ramadan",
  "Islamic Texts",
];

const questionOptions = [5, 10, 15, 20, 25, 30]; // Available options for number of questions

function shuffleArray(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

const TriviaPlus = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [currentCategory, setCurrentCategory] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(10);
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);

  // Calculate progress percentage based on the current question and total questions
  const progressPercentage = (currentQuestionIndex / selectedOption) * 100;

  // Calculate the maximum score based on the number of questions
  const maxScore = selectedOption * 10;

  // Calculate the current score percentage based on the current score and max score
  const scorePercentage = (score / maxScore) * 100;

  const resetGame = () => {
    setCurrentCategory("");
    setQuestions([]);
    setCurrentQuestionIndex(0);
    setScore(0);
    setSelectedOption(10); // Or whatever the default number of questions is
    setShowResults(false);
    setSelectedAnswer("");
    setFeedbackMessage("");
    setIsCorrect(null);
    setIsProcessing(false);
  };

  useEffect(() => {
    if (!currentCategory) return;
    let loadedQuestions;
    switch (currentCategory) {
      case "Islamic Prophets":
        loadedQuestions = prophetsMessengersQuestions;
        break;
      case "Islamic Figures":
        loadedQuestions = islamicFiguresQuestions;
        break;
      case "Islamic Geography":
        loadedQuestions = islamicGeographyQuestions;
        break;
      case "Islamic History":
        loadedQuestions = islamicHistoryQuestions;
        break;
      case "Islamic Laws":
        loadedQuestions = islamicLawsQuestions;
        break;
      case "Islamic Practices":
        loadedQuestions = islamicPracticesQuestions;
        break;
      case "Ramadan":
        loadedQuestions = islamicRamadanQuestions;
        break;
      case "Islamic Texts":
        loadedQuestions = islamicTextsQuestions;
        break;
      default:
        loadedQuestions = [];
        break;
    }
    const shuffledQuestions = shuffleArray([...loadedQuestions]).slice(
      0,
      selectedOption
    );
    setQuestions(shuffledQuestions);
    setCurrentQuestionIndex(0);
    setShowResults(false);
    setSelectedAnswer("");
    setFeedbackMessage("");
    setIsCorrect(null);
  }, [currentCategory, selectedOption]);

  const handleAnswerSelect = () => {
    if (isProcessing || !selectedAnswer) return;

    setIsProcessing(true); // Start processing

    const isAnswerCorrect =
      selectedAnswer === questions[currentQuestionIndex].answer;
    setIsCorrect(isAnswerCorrect);
    if (isAnswerCorrect) {
      setScore((prevScore) => prevScore + 1);
      setFeedbackMessage(`Correct! The answer is "${selectedAnswer}".`);
    } else {
      setFeedbackMessage(
        `Wrong! The correct answer is "${questions[currentQuestionIndex].answer}".`
      );
    }
    setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      } else {
        setShowResults(true);
      }
      setSelectedAnswer("");
      setFeedbackMessage("");
      setIsCorrect(null);
      setIsProcessing(false); // End processing
    }, 2000); // Adjust delay as needed
  };

  return (
    <ContentLayout>
      {currentCategory === "" && (
        <>
          <div className="text-lg font-base font-semibold text-deeperPurple text-center mb-4">
            Please select a category to start the trivia.
          </div>
          <div className="flex flex-wrap justify-center mb-4">
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => setCurrentCategory(category)}
                className={`m-2 px-4 py-2 text-sm font-semibold text-white rounded ${
                  currentCategory === category
                    ? "bg-deeperPurple"
                    : "bg-deeperPurple hover:bg-greenHover"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </>
      )}

      {currentCategory !== "" && (
        <>
          <h2 className="text-center text-base text-deeperPurple font-semibold my-1.5">
            Select a question range to attempt
          </h2>
          <div className="flex flex-wrap justify-center mb-3 overflow-x-auto">
            {questionOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => setSelectedOption(option)}
                className={`m-2 px-4 py-2 text-sm font-semibold text-white rounded ${
                  selectedOption === option
                    ? "bg-greenHover"
                    : "bg-deeperPurple hover:bg-greenHover"
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}

      {!showResults && questions.length > 0 && (
        <>
          <div className="mb-4 bg-white shadow-md p-8 rounded-lg">
            <h2 className="text-lg text-center text-deeperPurple font-semibold mb-4">
              Question {currentQuestionIndex + 1}/{selectedOption} on{" "}
              {currentCategory}
            </h2>
            {/* Progress Bar and Score Indicator */}
            <div className="flex items-center mb-4">
              <div className="flex-1 bg-deeperPurple h-3 rounded">
                <div
                  className="bg-green-500 h-3 rounded"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              {/* Points and Score Indicator */}
              <div className="w-32 text-center ml-2 flex items-center">
                <span className="font-medium text-greenHover pr-0.5">
                  Points: {score * 10}/
                </span>
                {/* Added label */}
                <span className="text-red-500  font-medium">{maxScore}</span>
              </div>
            </div>

            <p className="text-lg text-deeperPurple font-medium mb-4">
              {questions[currentQuestionIndex].question}
            </p>
            <form className="mt-4">
              {questions[currentQuestionIndex].options.map((option, index) => (
                <div
                  key={index}
                  className={`mb-2 p-3 rounded shadow cursor-pointer ${
                    selectedAnswer === option
                      ? "bg-greenHover text-white"
                      : "bg-gray-100 hover:bg-lighterPurpleHover"
                  }`}
                  onClick={() => setSelectedAnswer(option)}
                >
                  <label
                    htmlFor={`option-${index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      id={`option-${index}`}
                      name="answer"
                      value={option}
                      checked={selectedAnswer === option}
                      onChange={(e) => setSelectedAnswer(e.target.value)}
                      className="mr-2"
                    />
                    <span
                      className={`${
                        selectedAnswer === option
                          ? "text-white"
                          : "text-deeperPurple"
                      }`}
                    >
                      {option}
                    </span>
                  </label>
                </div>
              ))}
              {feedbackMessage && (
                <div
                  className={`text-md mt-2 font-semibold ${
                    isCorrect ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {feedbackMessage}
                </div>
              )}
              <button
                type="button"
                onClick={handleAnswerSelect}
                disabled={!selectedAnswer} // Disable the button if no option is selected
                className={`mt-4 px-8 py-3 bg-deeperPurple text-white rounded hover:bg-greenHover mx-auto w-full ${
                  !selectedAnswer && "opacity-50 cursor-not-allowed" // Apply additional styles to indicate button is disabled
                }`}
              >
                {currentQuestionIndex === questions.length - 1
                  ? "Finish"
                  : "Next"}
              </button>
            </form>
          </div>
        </>
      )}

      {showResults && (
        <TriviaResultModal
          score={score}
          totalQuestions={questions.length}
          currentCategory={currentCategory}
          onRestart={resetGame} // Pass the reset function
        />
      )}
    </ContentLayout>
  );
};

export default TriviaPlus;
