import React from "react";

const ContentLayout = ({ children }) => {
  return (
    <div className="bg-slate-100 text-deeperPurple lg:w-1/2 mx-auto px-4 rounded-tr-3xl rounded-tl-3xl flex flex-col min-h-screen md:py-12 md:px-12 py-4 mb-[56px]">
      {children}
    </div>
  );
};

export default ContentLayout;
