// src/components/Header/Menu.js
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Menu = ({ isMenuOpen, setIsMenuOpen }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsMenuOpen]);

  return (
    <div
      ref={menuRef}
      style={{
        top: "0.2rem",
        opacity: isMenuOpen ? 1 : 0,
        transform: isMenuOpen ? "translateX(0)" : "translateX(-100%)",
        transition: "opacity 300ms ease, transform 300ms ease",
        display: isMenuOpen ? "block" : "none",
      }}
      className="absolute top-full left-0 mt-0 w-64 bg-white p-4 rounded-r-lg shadow-md z-10"
    >
      <button
        onClick={() => setIsMenuOpen(false)}
        className="absolute top-0 right-0 mt-2 mr-2 text-xl"
      >
        X
      </button>
      <div className="mt-6 pr-16">
        <Link
          to="/trivia"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          Islamic Trivia
        </Link>
        <Link
          to="/quraninsights"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          Quran Insights
        </Link>
        <Link
          to="/hadiths"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          Share Hadiths
        </Link>

        <Link
          to="/rabanna"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          Share Rabanna
        </Link>
        <Link
          to="/nuggets"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          Share Nuggets
        </Link>
        <Link
          to="/about"
          className="block py-2 font-medium hover:text-greenHover text-deeperPurple"
          onClick={() => setIsMenuOpen(false)}
        >
          About
        </Link>
        {/* Add other links similarly */}
      </div>
    </div>
  );
};

export default Menu;
