// src/components/Header/Logo.js
import React from "react";
import { Link } from "react-router-dom";
import logoIcon from "../../assets/logo.png";

const Logo = () => {
  return (
    <div className="flex-grow flex justify-center items-center">
      <Link to="/">
        <img src={logoIcon} alt="Muslim Nuggets Logo" className="h-20 mb-1" />
      </Link>
    </div>
  );
};

export default Logo;
