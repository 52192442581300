const authenticHadiths = [
  {
    about: "The Importance of Simplicity in Religion",
    hadith_content:
      'The Prophet (ﷺ) said, "Religion is very easy and whoever overburdens himself in his religion will not be able to continue in that way. So you should not be extremists, but try to be near to perfection and receive the good tidings that you will be rewarded; and gain strength by worshipping in the mornings, the nights."',
    narrated_by: "Narrated by Abu Hurairah",
    book: "Jami` at-Tirmidhi, Book 8, Hadith 26",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      'Anas ibn Malik (RA) reported: The Messenger of Allah (ﷺ) said, "Make things easy and do not make them difficult, cheer the people up by conveying glad tidings to them and do not repulse (them)."',
    narrated_by: "Narrated by Anas ibn Malik",
    book: "Sahih al-Bukhari, Book 78, Hadith 146",
  },
  {
    about: "The Importance of Honesty",
    hadith_content:
      'Abu Hurairah (RA) narrated that the Prophet (ﷺ) said: "Be conscious of Allah wherever you are. Follow up a bad deed with a good deed and it will erase it. And deal with people in a good manner."',
    narrated_by: "Narrated by Abu Hurairah",
    book: "Sunan al-Tirmidhi, Book 27, Hadith 107",
  },
  {
    about: "The Importance of Good Manners",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The most beloved of Allah's servants to Allah are those with the best manners.'",
    narrated_by: "Narrated by Al-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 1162",
  },
  {
    about: "The Virtue of Charity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Charity does not decrease wealth, no one forgives except that Allah increases his honor, and no one humbles himself for the sake of Allah except that Allah raises his status.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 12, Hadith 156",
  },
  {
    about: "The Importance of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Truthfulness leads to righteousness, and righteousness leads to Paradise. And a man keeps on telling the truth until he becomes a truthful person. Falsehood leads to wickedness and wickedness leads to the Hellfire, and a man may keep on telling lies until he is written before Allah as a liar.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 116",
  },
  {
    about: "The Significance of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Merit of Good Deeds",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Do good deeds properly, sincerely, and moderately. Always adopt a middle, moderate, regular course, whereby you will reach your target (Paradise).'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 469",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is obligatory upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'No fatigue, nor disease, nor sorrow, nor sadness, nor hurt, nor distress befalls a Muslim, even if it were the prick he receives from a thorn, but that Allah expiates some of his sins for that.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 70, Hadith 545",
  },
  {
    about: "The Importance of Good Character",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The most complete of the believers in faith are those with the best character, and the best of you are the best in behavior to their women.'",
    narrated_by: "Narrated by Al-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 32, Hadith 1162",
  },
  {
    about: "The Blessings of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not show mercy to others, will not be shown mercy.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 36",
  },
  {
    about: "The Importance of Praying on Time",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The most difficult prayers for the hypocrites are the 'Isha' (night) and Fajr (early morning) prayers. If they knew the rewards for these prayers at their respective times, they would certainly present themselves (in the mosques) even if they had to crawl.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 1, Hadith 530",
  },

  {
    about: "Moderation in Religion",
    hadith_content:
      'The Prophet (ﷺ) said, "Religion is very easy and whoever overburdens himself in his religion will not be able to continue in that way. So you should not be extremists, but try to be near to perfection and receive the good tidings that you will be rewarded; and gain strength by worshipping in the mornings, the nights."',
    narrated_by: "Narrated by Abu Hurairah",
    book: "Jami` at-Tirmidhi, Book 8, Hadith 26",
  },
  {
    about: "Seeking Knowledge",
    hadith_content:
      "Narrated by Abu Hurairah (RA): The Prophet (PBUH) said, ‘Seek knowledge, even if you have to go to China.’",
    narrated_by: "Narrated by Abu Hurairah",
    book: "Sahih al-Bukhari, Book 1, Hadith 1",
  },
  {
    about: "Importance of Prayer",
    hadith_content:
      "Narrated by Ibn Abbas (RA): The Prophet (PBUH) said, ‘The first matter that will be accounted for from a person’s deeds on the Day of Judgement is the prayer.’",
    narrated_by: "Narrated by Ibn Abbas",
    book: "Sahih Muslim, Book 1, Hadith 39",
  },
  {
    about: "Kindness to Parents",
    hadith_content:
      "Narrated by Abdullah bin Amr bin As (RA): A man came to the Prophet (PBUH) and said, ‘O Messenger of Allah, who among the people deserves my best companionship?’ The Prophet (PBUH) said, ‘Your mother.’ The man said, ‘Then who?’ The Prophet (PBUH) said, ‘Then your mother.’ The man said, ‘Then who?’ The Prophet (PBUH) said, ‘Then your mother.’ The man said, ‘Then who?’ The Prophet (PBUH) said, ‘Then your father.’",
    narrated_by: "Narrated by Abdullah bin Amr bin As",
    book: "Sahih al-Bukhari, Book 2, Hadith 13",
  },
  {
    about: "The Two Most Important Things in Islam",
    hadith_content:
      "Narrated by Umar ibn al-Khattâb (RA): I heard the Messenger of Allah (PBUH) say while delivering a Khutbah (speech) in which he mentioned two things which he repeatedly emphasized (saying them again and again) until I thought that he would not stop mentioning them. He said, ‘The two weighty things are the Book of Allah (the Quran) and the Sunnah of the Messenger of Allah (PBUH).’",
    narrated_by: "Narrated by Umar ibn al-Khattâb",
    book: "Sahih al-Bukhari, Book 6, Hadith 618",
  },
  {
    about: "Actions are judged by intentions",
    hadith_content:
      "Narrated by Umar bin Al-Khattab (RA): I heard the Messenger of Allah (PBUH) say, ‘Actions are (according to) intentions and every person will get what he intended.’",
    narrated_by: "Narrated by Umar bin Al-Khattab",
    book: "Sahih al-Bukhari, Book 1, Hadith 1",
  },
  {
    about: "Importance of Charity",
    hadith_content:
      "Narrated by Abu Huraira (RA): The Prophet (ﷺ) said, ‘Charity extinguishes sin as water extinguishes fire.’",
    narrated_by: "Narrated by Abu Huraira",
    book: "Sahih al-Bukhari, Book 2, Hadith 660",
  },
  {
    about: "Keeping good company",
    hadith_content:
      "Narrated by Abu Musa al-Ash`ari (RA): The Prophet (ﷺ) said, ‘A man is upon the religion of his friend, so let him look carefully of whom he befriends.’",
    narrated_by: "Narrated by Abu Musa al-Ash`ari",
    book: "Sahih al-Bukhari, Book 8, Hadith 347",
  },
  {
    about: "The Virtue of Good Neighbors",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Gabriel kept recommending treating neighbors with kindness until I thought he would assign them a share of inheritance.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 32, Hadith 24",
  },
  {
    about: "The Importance of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty is part of faith and faith is in Paradise, but obscenity is part of hardness of heart and hardness of heart is in Hell.'",
    narrated_by: "Narrated by Abu Dawood",
    book: "Sunan Abi Dawood, Book 41, Hadith 5100",
  },
  {
    about: "The Blessings of Sadaqah (Charity)",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Sadaqah (charity) extinguishes sins like water extinguishes fire.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 16488",
  },
  {
    about: "The Importance of Fasting",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever fasts during Ramadan out of sincere faith and hoping to attain Allah's rewards, then all his past sins will be forgiven.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 31, Hadith 1",
  },
  {
    about: "The Virtue of Keeping Good Company",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'A person is likely to follow the faith of his friend, so be careful whom you befriend.'",
    narrated_by: "Narrated by Abu Dawood",
    book: "Sunan Abi Dawood, Book 41, Hadith 4814",
  },
  {
    about: "The Importance of Honoring Guests",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever believes in Allah and the Last Day should honor his guest.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 22",
  },
  {
    about: "The Virtue of Patience During Hardship",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'How wonderful is the case of a believer; there is good for him in everything, and this applies only to a believer. If prosperity attends him, he expresses gratitude to Allah and that is good for him; and if adversity befalls him, he endures it patiently and that is better for him.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 33, Hadith 6441",
  },
  {
    about: "The Importance of Smiling",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Do not belittle any good deed, even meeting your brother with a cheerful face.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6274",
  },
  {
    about: "The Virtue of Remembrance of Allah",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Shall I not inform you of the best of your deeds, the purest of them with your Master, the highest of them in your ranks, the deed that is better for you than spending gold and silver, even better for you than meeting your enemies in battle, so that you strike at their necks and they at yours?' They said, 'Yes, O Messenger of Allah.' He said, 'Remembrance of Allah.'",
    narrated_by: "Narrated by Abu Dawood and Tirmidhi",
    book: "Sunan Abu Dawood, Book 13, Hadith 3847",
  },
  {
    about: "The Importance of Justice",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The just will be seated upon pulpits of light, on the right side of the Most Merciful, and exalted. Those who are fair in their decisions and dealings with their family and the people and in all that they do.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 12, Hadith 2286",
  },
  {
    about: "The Virtue of Honoring Parents",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The pleasure of the Lord lies in the pleasure of the parent. The displeasure of the Lord lies in the displeasure of the parent.'",
    narrated_by: "Narrated by Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 1, Hadith 191",
  },
  {
    about: "The Virtue of Worshiping Allah Alone",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Allah has forbidden for you to be undutiful to your mothers, to withhold (what you should give) or demand (what you do not deserve), and to bury your daughters alive. And Allah has disliked that you talk too much about others, ask too many questions (in religion), or waste your property.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 2, Hadith 573",
  },
  {
    about: "The Importance of Repentance",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Allah is more pleased with the repentance of His slave than a person who has his camel in a waterless desert carrying his provision of food and drink and it is lost. He, having lost all hopes to get that back, lies down in shade and is disappointed about his camel; when all of a sudden he finds that camel standing before him. He takes hold of its reins and then out of boundless joy blurts out: 'O Allah, You are my slave and I am Your Rubb'. He commits this mistake out of extreme joy.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 693",
  },
  {
    about: "The Virtue of Good Speech",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever believes in Allah and the Last Day should either speak good or remain silent.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 28",
  },
  {
    about: "The Virtue of Good Relations",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The one who maintains good relations with his relatives only because they maintain a relationship with him is not truly upholding the ties of kinship. The one who truly upholds those ties is the one who does so even if they break off the relationship.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 13",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever follows a path to seek knowledge, Allah will make the path to Paradise easy for them.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 255",
  },
  {
    about: "The Virtue of Contentment",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Richness is not having many possessions, but richness is being content with oneself.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 453",
  },
  {
    about: "The Importance of Fearing Allah",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Fear Allah wherever you are, and follow up a bad deed with a good one and it will wipe it out, and behave well towards people.'",
    narrated_by: "Narrated by Al-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 40, Hadith 1987",
  },
  {
    about: "The Virtue of Kindness to Animals",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever is kind to the creatures of Allah, he is kind to himself.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 28",
  },
  {
    about: "The Importance of Honesty in Trade",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The truthful and trustworthy businessman will be in the company of Prophets, saints, and martyrs on the Day of Judgment.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 33, Hadith 1315",
  },
  {
    about: "The Virtue of Repelling Harm",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whosoever removes a worldly grief from a believer, Allah will remove from him one of the griefs of the Day of Judgment.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6250",
  },
  {
    about: "The Importance of Praying in Congregation",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The prayer in congregation is twenty-seven times superior to the prayer offered by a person alone.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 11, Hadith 619",
  },
  {
    about: "The Virtue of Giving Advice",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The religion is advice.' We said, 'To whom?' He said, 'To Allah and His Book, and His Messenger, and to the leaders of the Muslims and their common folk.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 1, Hadith 55",
  },
  {
    about: "The Importance of Consistency in Good Deeds",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Do good deeds properly, sincerely, and moderately. Always adopt a middle, moderate, regular course, whereby you will reach your target (Paradise).'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 469",
  },
  {
    about: "The Virtue of Good Character",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The heaviest thing to be placed on the Scale of a believer on the Day of Resurrection will be good character.'",
    narrated_by: "Narrated by Abu Dawood",
    book: "Sunan Abi Dawood, Book 42, Hadith 4781",
  },
  {
    about: "The Importance of Honoring Guests",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever believes in Allah and the Last Day should honor his guest.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 22",
  },
  {
    about: "The Virtue of Helping Others",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Allah will aid a servant (of His) so long as the servant aids his brother.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6359",
  },
  {
    about: "The Importance of Keeping Promises",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The signs of a hypocrite are three: whenever he speaks, he tells a lie; and whenever he promises, he breaks his promise; and whenever he is trusted, he betrays his trust.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 53, Hadith 32",
  },
  {
    about: "The Virtue of Repentance",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'O people, turn in repentance to Allah and seek His forgiveness. Verily, I turn to Him in repentance a hundred times a day.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 37, Hadith 6621",
  },
  {
    about: "The Importance of Keeping Family Ties",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who believes in Allah and the Last Day should not sever his relationship with his family.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 16",
  },
  {
    about: "The Virtue of Seeking Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'By Allah, I ask for forgiveness from Allah and turn to Him in repentance more than seventy times a day.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 75, Hadith 319",
  },
  {
    about: "The Importance of Patience in Hardship",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Strange are the ways of a believer for there is good in every affair of his and this is not the case with anyone else except in the case of a believer, for if he has an occasion to feel delight, he thanks (Allah), thus there is a good for him in it, and if he gets into trouble and shows resignation (and endures it patiently), there is a good for him in it.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6249",
  },
  {
    about: "The Virtue of Spreading Salaam (Greetings)",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'You will not enter Paradise until you believe, and you will not believe until you love one another. Shall I not guide you to something which, if you do it, will cause you to love one another? Spread salaam (greetings) among yourselves.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 1, Hadith 96",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The seeking of knowledge is obligatory for every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Importance of Being Just",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Be just, for injustice is darkness on the Day of Judgment.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6251",
  },
  {
    about: "The Virtue of Patience and Prayer",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The prayer of one of you is suspended between heaven and earth as long as he is in prayer, so say, 'Allahu Akbar' (Allah is the Greatest) at the beginning of the prayer, and then praise Him, and then send blessings upon the Prophet, then seek forgiveness, and then ask for what you need; for indeed, he who does that, his prayer will be answered.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 3, Hadith 1756",
  },
  {
    about: "The Importance of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty brings nothing except good.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 27",
  },
  {
    about: "The Virtue of Generosity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The believer's shade on the Day of Resurrection will be his charity.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 24, Hadith 513",
  },
  {
    about: "The Importance of Maintaining Family Ties",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'One who maintains ties with his relatives only because they reciprocate the treatment does not truly uphold the ties of kinship. The one who truly upholds them is the one who persists in doing so even if they sever the ties of kinship.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 16",
  },
  {
    about: "The Virtue of Helping Others in Need",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best of people are those who benefit people the most.'",
    narrated_by: "Narrated by Al-Muwatta",
    book: "Al-Muwatta, Book 15, Hadith 15.34",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is an obligation upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Being Good to Neighbors",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Jibril (Gabriel) kept recommending that I treat my neighbors well until I thought he would make them my heirs.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 238",
  },
  {
    about: "The Importance of Fulfilling Trusts",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The signs of a hypocrite are three: When he speaks, he lies; when he makes a promise, he breaks it; and when he is entrusted, he betrays his trust.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 53, Hadith 32",
  },
  {
    about: "The Virtue of Good Speech",
    hadith_content: "The Prophet Muhammad (ﷺ) said, 'A good word is charity.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 136",
  },
  {
    about: "The Importance of Sincerity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Actions are but by intentions, and every person will have only what they intended.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 1, Hadith 1",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Forgive him who wrongs you; join him who cuts you off; do good to him who does bad to you; and speak the truth even if it be against yourself.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 47, Hadith 2189",
  },
  {
    about: "The Importance of Charity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The believer's shade on the Day of Resurrection will be their charity.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 33, Hadith 2646",
  },
  {
    about: "The Virtue of Contentment",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Richness is not having many possessions, but richness is contentment of the soul.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 42, Hadith 7065",
  },
  {
    about: "The Importance of Enjoining Good and Forbidding Evil",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever among you sees an evil, let them change it with their hand; if they are unable to do so, then with their tongue; if they are unable to do so, then with their heart, and that is the weakest of faith.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 1, Hadith 49",
  },
  {
    about: "The Virtue of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'No one has been given a gift better and more comprehensive than patience.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 70, Hadith 548",
  },
  {
    about: "The Importance of Brotherhood",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'A Muslim is the brother of another Muslim. He does not oppress him, nor does he leave him at the mercy of others.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6219",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Importance of Prayer",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The first thing that the people will be called to account for on the Day of Resurrection will be prayer. Our Lord will say to the angels, 'Look at My slave's prayers. Were they complete or not?'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 1, Hadith 241",
  },
  {
    about: "The Virtue of Smiling",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'When you smile in the face of your brother, it is charity.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 1956",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is a duty upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Dua (Supplication)",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Dua is the essence of worship.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 36, Hadith 2686",
  },
  {
    about: "The Importance of Good Character",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best among you are those who have the best manners and character.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 56, Hadith 759",
  },
  {
    about: "The Virtue of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Truthfulness leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 92",
  },
  {
    about: "The Importance of Repentance",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Turn to Allah in repentance and seek His forgiveness, for I turn to Him seventy times each day in repentance.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 8, Hadith 319",
  },
  {
    about: "The Virtue of Contentment",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Riches do not mean having a great amount of property, but true wealth is contentment of the soul.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 453",
  },
  {
    about: "The Importance of Good Deeds",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The most beloved deed to Allah is the most regular and constant even though it were little.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 76, Hadith 469",
  },
  {
    about: "The Virtue of Patience and Prayer",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The prayer of one who is oppressed is answered, even if he is sinful.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 14, Hadith 1395",
  },
  {
    about: "The Importance of Charity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Give charity without delay, for it stands in the way of calamity.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 1887",
  },
  {
    about: "The Virtue of Compassion",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Have mercy on those on earth, and the One in the heavens will have mercy upon you.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 33, Hadith 2687",
  },
  {
    about: "The Importance of Honoring Parents",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Paradise lies at the feet of your mother.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 33, Hadith 3651",
  },
  {
    about: "The Virtue of Humility",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Allah has revealed to me that you must be humble, so that no one boasts over another, and no one oppresses another.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6278",
  },
  {
    about: "The Importance of Brotherhood",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Do not envy one another, do not outbid one another (with a view to raising the price), do not hate one another, do not turn your back on each other, but rather be servants of Allah as brothers.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 41, Hadith 571",
  },
  {
    about: "The Virtue of Generosity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The believer's shade on the Day of Resurrection will be their charity.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 24, Hadith 513",
  },
  {
    about: "The Importance of Patience in Hardship",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The example of a believer is that of a fresh green plant. The wind blows and shakes it, but it remains firm and steady. Similarly, when afflictions or trials befall a believer, they remain patient and firm.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6231",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Shall I not inform you of something more excellent in degree than fasting, prayer, and almsgiving (sadaqah)? It is purifying what is ruined and mending broken ties.'",
    narrated_by: "Narrated by Abu Dawood",
    book: "Sunan Abi Dawood, Book 15, Hadith 2433",
  },
  {
    about: "The Importance of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty brings nothing except good.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 27",
  },
  {
    about: "The Virtue of Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is obligatory upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Importance of Kindness to Animals",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'A woman was punished in Hell because of a cat which she had confined until it died. She did not give it to eat or to drink when it was confined, nor did she free it so that it might eat the insects of the earth.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 67, Hadith 393",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Importance of Being Just",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Be just, for injustice is darkness on the Day of Judgment.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6251",
  },
  {
    about: "The Virtue of Patience and Prayer",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The prayer of one of you is suspended between heaven and earth as long as he is in prayer, so say, 'Allahu Akbar' (Allah is the Greatest) at the beginning of the prayer, and then praise Him, and then send blessings upon the Prophet, then seek forgiveness, and then ask for what you need; for indeed, he who does that, his prayer will be answered.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 3, Hadith 1756",
  },
  {
    about: "The Importance of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty brings nothing except good.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 27",
  },
  {
    about: "The Virtue of Generosity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The believer's shade on the Day of Resurrection will be his charity.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 24, Hadith 513",
  },
  {
    about: "The Importance of Maintaining Family Ties",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'One who maintains ties with his relatives only because they reciprocate the treatment does not truly uphold the ties of kinship. The one who truly upholds them is the one who persists in doing so even if they sever the ties of kinship.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 16",
  },
  {
    about: "The Virtue of Helping Others in Need",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best of people are those who benefit people the most.'",
    narrated_by: "Narrated by Al-Muwatta",
    book: "Al-Muwatta, Book 15, Hadith 15.34",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is an obligation upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Being Good to Neighbors",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Jibril (Gabriel) kept recommending that I treat my neighbors well until I thought he would make them my heirs.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 238",
  },
  {
    about: "The Importance of Fulfilling Trusts",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The signs of a hypocrite are three: When he speaks, he lies; when he makes a promise, he breaks it; and when he is entrusted, he betrays his trust.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 53, Hadith 32",
  },
  {
    about: "The Virtue of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Truthfulness leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 92",
  },
  {
    about: "The Importance of Repentance",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Turn to Allah in repentance and seek His forgiveness, for I turn to Him seventy times each day in repentance.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 8, Hadith 319",
  },
  {
    about: "The Virtue of Contentment",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Riches do not mean having a great amount of property, but true wealth is contentment of the soul.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 453",
  },
  {
    about: "The Importance of Good Deeds",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The most beloved deed to Allah is the most regular and constant even though it were little.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 76, Hadith 469",
  },
  {
    about: "The Virtue of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'No one has been given a gift better and more comprehensive than patience.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 70, Hadith 548",
  },
  {
    about: "The Importance of Brotherhood",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'A Muslim is the brother of another Muslim. He does not oppress him, nor does he leave him at the mercy of others.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6219",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Importance of Prayer",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The first thing that the people will be called to account for on the Day of Resurrection will be prayer. Our Lord will say to the angels, 'Look at My slave's prayers. Were they complete or not?'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 1, Hadith 241",
  },
  {
    about: "The Virtue of Smiling",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'When you smile in the face of your brother, it is charity.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 1956",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is a duty upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Charity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Charity extinguishes sins just as water extinguishes fire.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 31, Hadith 3691",
  },
  {
    about: "The Importance of Good Character",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The heaviest thing to be placed on the Scale of a believer on the Day of Resurrection will be good character.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 2004",
  },
  {
    about: "The Virtue of Kindness to Parents",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The pleasure of the Lord lies in the pleasure of the parent. The anger of the Lord lies in the anger of the parent.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 49, Hadith 3084",
  },
  {
    about: "The Importance of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Shall I inform you about the people of Paradise? They comprise every poor humble person, and if he swears by Allah to do something, Allah fulfills his oath. Shall I inform you about the people of the Fire? They comprise every cruel, violent, proud, and arrogant person.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 81, Hadith 21",
  },
  {
    about: "The Importance of Fasting",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Fasting is a shield; when anyone of you is fasting on a day, he should neither indulge in obscene language nor should he raise his voice in anger. If someone attacks him or insults him, let him say: 'I am fasting!'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 30, Hadith 118",
  },
  {
    about: "The Virtue of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Honesty leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 94",
  },
  {
    about: "The Importance of Knowledge and Wisdom",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The seeking of knowledge is obligatory for every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Patience is the key to Paradise.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 81, Hadith 19",
  },
  {
    about: "The Importance of Helping Others",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever relieves a believer’s distress, Allah will relieve his distress on the Day of Resurrection.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 70, Hadith 545",
  },
  {
    about: "The Virtue of Humility",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who humbles himself for the sake of Allah, Allah will elevate his status.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 6214",
  },
  {
    about: "The Importance of Sincerity in Worship",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Allah does not look at your forms or wealth, but He looks at your hearts and deeds.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 4, Hadith 6718",
  },
  {
    about: "The Virtue of Compassion towards Animals",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever is kind to animals, Allah will be kind to him on the Day of Judgment.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 67, Hadith 224",
  },
  {
    about: "The Importance of Seeking Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'By Allah, I seek Allah's forgiveness and turn to Him in repentance more than seventy times a day.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 75, Hadith 319",
  },
  {
    about: "The Virtue of Truthfulness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Truthfulness leads to righteousness, and righteousness leads to Paradise. A man continues to speak the truth until he is recorded with Allah as a truthful person.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 73, Hadith 116",
  },
  {
    about: "The Importance of Charity in Concealment",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Giving charity in secret extinguishes the wrath of the Lord.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 24, Hadith 562",
  },
  {
    about: "The Virtue of Honoring Guests",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever believes in Allah and the Last Day should honor his guest.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 8, Hadith 158",
  },
  {
    about: "The Importance of Being Kind to Women",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best of you are those who are best to their wives.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 1, Hadith 1162",
  },
  {
    about: "The Virtue of Patience during Hardship",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'No fatigue, nor disease, nor sorrow, nor sadness, nor hurt, nor distress befalls a Muslim, even if it were the prick he receives from a thorn, but that Allah expiates some of his sins for that.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 70, Hadith 545",
  },
  {
    about: "The Importance of Spreading Peace",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Spread peace among yourselves.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 2685",
  },
  {
    about: "The Virtue of Remembrance of Allah",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The likeness of the one who remembers his Lord and the one who does not remember Him is like that of a living to a dead person.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 75, Hadith 421",
  },
  {
    about: "The Importance of Good Treatment of Women",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best of you are those who are best to their wives.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 1, Hadith 1162",
  },
  {
    about: "The Virtue of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty is a part of faith.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 38",
  },
  {
    about: "The Importance of Enjoining Good and Forbidding Evil",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever among you sees an evil, let him change it with his hand; and if he is not able to do so, then with his tongue; and if he is not able to do so, then with his heart - and that is the weakest of faith.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 1, Hadith 79",
  },
  {
    about: "The Virtue of Generosity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The believer's shade on the Day of Resurrection will be his charity.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 24, Hadith 513",
  },
  {
    about: "The Importance of Fulfilling Trusts",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The signs of a hypocrite are three: When he speaks, he lies; when he makes a promise, he breaks it; and when he is entrusted, he betrays his trust.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 53, Hadith 32",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Shall I not inform you of something more excellent in degree than fasting, prayer, and almsgiving (sadaqah)? It is purifying what is ruined and mending broken ties.'",
    narrated_by: "Narrated by Abu Dawood",
    book: "Sunan Abi Dawood, Book 15, Hadith 2433",
  },
  {
    about: "The Importance of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever does not thank the people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18427",
  },
  {
    about: "The Virtue of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best among you are those who have the best manners and character.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 56, Hadith 759",
  },
  {
    about: "The Importance of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Honesty leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 92",
  },
  {
    about: "The Virtue of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Truthfulness leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 98",
  },
  {
    about: "The Importance of Good Character",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The heaviest thing to be placed on the Scale of a believer on the Day of Resurrection will be good character.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 2004",
  },
  {
    about: "The Virtue of Generosity",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Charity does not decrease wealth, no one forgives except that Allah increases his honor, and no one humbles himself for the sake of Allah except that Allah raises his status.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 207",
  },
  {
    about: "The Importance of Modesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Modesty is part of faith, and faith leads to Paradise. And indecency is part of hardness of the heart, and hardness of the heart leads to the Fire.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 131",
  },
  {
    about: "The Virtue of Helping Others",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever relieves a believer's distress, Allah will relieve his distress on the Day of Resurrection.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 70, Hadith 545",
  },
  {
    about: "The Importance of Good Manners",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'I have only been sent to perfect good manners.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 76, Hadith 538",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18568",
  },
  {
    about: "The Importance of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Patience is a pillar of faith.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 2, Hadith 47",
  },
  {
    about: "The Virtue of Kindness to Parents",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Paradise lies beneath the feet of your mother.'",
    narrated_by: "Narrated by Al-Bayhaqi",
    book: "Shu'ab al-Iman, Book 1, Hadith 2",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is obligatory upon every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Smiling",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Your smiling in the face of your brother is charity.'",
    narrated_by: "Narrated by At-Tirmidhi",
    book: "Jami` at-Tirmidhi, Book 27, Hadith 1956",
  },
  {
    about: "The Importance of Patience",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Patience is a means of great help.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 32, Hadith 2167",
  },
  {
    about: "The Virtue of Good Deeds",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best deed is the one done regularly even if it is small.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 78, Hadith 647",
  },
  {
    about: "The Importance of Kindness to Animals",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Whoever is kind to the creatures of Allah, Allah will be kind to him on the Day of Judgment.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 4, Hadith 537",
  },
  {
    about: "The Virtue of Gratitude",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'He who does not thank people, does not thank Allah.'",
    narrated_by: "Narrated by Ahmad",
    book: "Musnad Ahmad, Hadith 18427",
  },
  {
    about: "The Importance of Seeking Knowledge",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Seeking knowledge is obligatory for every Muslim.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 1, Hadith 224",
  },
  {
    about: "The Virtue of Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The best among you are those who forgive when they are in a position of power.'",
    narrated_by: "Narrated by Ibn Majah",
    book: "Sunan Ibn Majah, Book 37, Hadith 4069",
  },
  {
    about: "The Importance of Maintaining Family Ties",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'The person who perfectly maintains the ties of kinship is not the one who does it because he gets recompensed by his relatives (for being kind and good to them), but the one who truly maintains the bonds of kinship is the one who persists in doing so even though the latter has severed the ties of kinship with him.'",
    narrated_by: "Narrated by Al-Bukhari",
    book: "Sahih al-Bukhari, Book 73, Hadith 14",
  },
  {
    about: "The Virtue of Honesty",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'Honesty leads to righteousness, and righteousness leads to Paradise.'",
    narrated_by: "Narrated by Muslim",
    book: "Sahih Muslim, Book 45, Hadith 92",
  },
  {
    about: "The Importance of Seeking Forgiveness",
    hadith_content:
      "The Prophet Muhammad (ﷺ) said, 'By Allah, I seek Allah's forgiveness and turn to Him in repentance more than seventy times a day.'",
    narrated_by: "Narrated by Al-Bukhari and Muslim",
    book: "Sahih al-Bukhari, Book 75, Hadith 319",
  },
];

export default authenticHadiths;
