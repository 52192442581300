const legendsNuggets = [
  {
    legend: "Prophet Muhammad (PBUH)",
    nugget: "The ink of the scholar is holier than the blood of the martyr.",
    message:
      "Knowledge and education hold a sacred status, even more revered than the ultimate sacrifice of martyrdom.",
  },
  {
    legend: "Ali ibn Abi Talib",
    nugget: "Patience is the key that opens the door of joy.",
    message:
      "Embrace patience, for it unlocks the path to true happiness and contentment.",
  },
  {
    legend: "Imam Al-Ghazali",
    nugget:
      "Knowledge without action is insanity, and action without knowledge is foolishness.",
    message:
      "True wisdom lies in the harmony between knowledge and action, where one complements the other.",
  },
  {
    legend: "Jalal ad-Din Muhammad Rumi",
    nugget: "Silence is the language of God, all else is poor translation.",
    message:
      "Embrace the profound silence of the Divine, for all words are mere approximations of the ineffable truth.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Ignorance leads to fear, fear leads to hatred, and hatred leads to violence. This is the equation.",
    message:
      "Break the cycle of ignorance, fear, and hatred through knowledge and understanding, for they are the true paths to peace.",
  },
  {
    legend: "Al-Kindi",
    nugget: "No one is diminished by the truth, rather only enlarged.",
    message:
      "Embrace the truth, for it does not diminish but elevates and expands our understanding.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "The only true knowledge is knowledge which is acquired for itself alone.",
    message:
      "Seek knowledge for its own sake, not for external rewards or accolades, for true learning comes from a genuine thirst for understanding.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "Truth is ever incontrovertible, ignorance deviating from it will ever remain ubiquitous.",
    message:
      "While truth stands unwavering, ignorance will persist, reminding us of the constant need to pursue knowledge and dispel misconceptions.",
  },
  {
    legend: "Imam Shafi'i",
    nugget:
      "Knowledge is better than wealth: knowledge guards you, while you have to guard wealth.",
    message:
      "Prioritize the pursuit of knowledge, for it is a lasting treasure that safeguards you, while material wealth requires constant vigilance.",
  },
  {
    legend: "Imam Bukhari",
    nugget:
      "Knowledge from which no benefit is derived is like a treasure from which no profit is gained.",
    message:
      "True knowledge is not merely an intellectual exercise but a means to derive practical benefits and positive impact.",
  },
  {
    legend: "Fatima Al-Fihri",
    nugget: "Education is the light that dispels the darkness of ignorance.",
    message:
      "Embrace education as the illuminating force that banishes the shadows of ignorance and guides us toward understanding.",
  },
  {
    legend: "Ibn Sina (Avicenna)",
    nugget:
      "The knowledge of anything, since all things have causes, is not acquired or complete unless it is known by its causes.",
    message:
      "True understanding demands exploration of the underlying causes and roots, for knowledge without comprehending its foundations remains incomplete.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "The worth of a person is measured by the worth of their knowledge.",
    message:
      "Evaluate individuals not by external trappings but by the depth and value of their knowledge and wisdom.",
  },
  {
    legend: "Ibn Battuta",
    nugget:
      "Traveling leaves you speechless, then turns you into a storyteller.",
    message:
      "Embrace the transformative power of travel, for it expands horizons, fosters understanding, and equips you with tales to share.",
  },
  {
    legend: "Al-Idrisi",
    nugget:
      "Knowledge is the greatest treasure, and its acquisition the noblest pursuit.",
    message:
      "Recognize the immense value of knowledge as the ultimate wealth, and its pursuit as the most noble of endeavors.",
  },
  {
    legend: "Ibn Firnas",
    nugget:
      "Those who dread the perils of the unknown shall remain chained to the shackles of ignorance.",
    message:
      "Confront your fears of the unknown with courage, for only by venturing into uncharted territories can we break free from the bonds of ignorance.",
  },
  {
    legend: "Al-Haytham",
    nugget:
      "The seeker of truth must be guided solely by reason, not by authority or tradition.",
    message:
      "In the pursuit of truth, reason must be your compass, transcending the constraints of mere authority or inherited traditions.",
  },
  {
    legend: "Al-Jazari",
    nugget:
      "Knowledge without application is like a runner on a treadmill, expending energy yet going nowhere.",
    message:
      "True knowledge demands practical application, lest it become a futile exercise, expending effort without progress.",
  },
  {
    legend: "Imam Al-Shafi'i",
    nugget: "Seek knowledge, even if you have to travel as far as China.",
    message:
      "Let no distance or obstacle deter you from the pursuit of knowledge, for its value transcends all boundaries.",
  },
  {
    legend: "Nana Asma'u",
    nugget: "Knowledge is the light that illuminates the path to success.",
    message:
      "Embrace knowledge as the guiding light that reveals the way forward, leading you to personal and collective success.",
  },
  {
    legend: "Malcolm X",
    nugget:
      "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.",
    message:
      "Embrace education as your most valuable asset. It's your key to navigating the future and shaping it with wisdom.",
  },
  {
    legend: "Rumi",
    nugget:
      "Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.",
    message:
      "Wisdom leads us to focus on our own transformation, understanding it's the first step in contributing to the world's betterment.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "He who finds no way to express himself and show his worth is in a worse position than if he were dead.",
    message:
      "Expressing oneself and one's value is essential for life; silence and invisibility can be more limiting than death itself.",
  },
  {
    legend: "Al-Ghazali",
    nugget:
      "Knowledge without action is wastefulness and action without knowledge is foolishness.",
    message:
      "Strive for a balance where your knowledge drives impactful actions, and your actions are always guided by deep understanding.",
  },
  {
    legend: "Ibn Sina (Avicenna)",
    nugget:
      "The knowledge of anything, since all things have causes, is not acquired or complete unless it is known by its causes.",
    message:
      "True understanding comes from grasping the root causes of things, not just their appearances or effects.",
  },
  {
    legend: "Salahuddin Ayyubi",
    nugget:
      "Victory is changing the hearts of your opponents by gentleness and kindness.",
    message:
      "True conquest lies in winning hearts through compassion and kindness, not just in battlefield triumphs.",
  },
  {
    legend: "Ibn Battuta",
    nugget:
      "Traveling—it leaves you speechless, then turns you into a storyteller.",
    message:
      "The beauty of travel is that it renders us silent with awe before filling us with tales waiting to be told.",
  },
  {
    legend: "Fatima al-Fihri",
    nugget:
      "Seeking knowledge is a duty upon every Muslim; it will lead to a path of growth and enlightenment.",
    message:
      "Pursue knowledge relentlessly, as it is your path to growth and enlightenment, uplifting both yourself and your community.",
  },
  {
    legend: "Al-Kindi",
    nugget:
      "The intellect is refined by learning, and learning is the intellect’s food.",
    message:
      "Nourish your intellect through continuous learning; it's the fuel that keeps your mental faculties sharp and expansive.",
  },
  {
    legend: "Yusuf Islam (Cat Stevens)",
    nugget: "Peace train is sounding louder; glide on the peace train.",
    message:
      "Embrace the journey towards peace with open arms, letting its momentum guide and transform our collective spirit.",
  },
  {
    legend: "Nasir al-Din al-Tusi",
    nugget:
      "The body is the substance of the soul, and the soul is the ruler of the body.",
    message:
      "Our souls and bodies are intertwined, with the soul guiding our physical existence towards our true purpose and enlightenment.",
  },
  {
    legend: "Zaynab al-Ghazali",
    nugget:
      "Work for God’s sake and not for your own. It is the path to true success.",
    message:
      "Dedicate your efforts and intentions towards higher purposes, finding true fulfillment and success in selfless service.",
  },
  {
    legend: "Ahmad Ibn Hanbal",
    nugget:
      "Do not be led by others; anchor your beliefs firmly in knowledge and reason.",
    message:
      "Hold fast to knowledge and reason as your guides; let them anchor your beliefs, not the fleeting opinions of others.",
  },
  {
    legend: "Al-Farabi",
    nugget:
      "The attainment of happiness is achievable through the cultivation of virtuous habits.",
    message:
      "True happiness is not found but cultivated through consistent practice of virtue and commitment to personal growth.",
  },
  {
    legend: "Muhammad Iqbal",
    nugget:
      "The ultimate aim of the ego is not to see something, but to be something.",
    message:
      "Our deepest desire is not mere observation of the world but to make a meaningful impact and embody our visions.",
  },
  {
    legend: "Fatima Mernissi",
    nugget:
      "To exist is to change, to change is to mature, to mature is to create oneself endlessly.",
    message:
      "Life is a continuous journey of transformation, maturity, and self-creation, embracing change as the essence of existence.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "The first requirement in the acquisition of knowledge is the love of learning.",
    message:
      "A genuine love for learning is the cornerstone of acquiring knowledge, igniting curiosity and passion for discovery.",
  },
  {
    legend: "Hasan al-Basri",
    nugget:
      "Seek the world you will find in your heart, and you will find both the world and the next.",
    message:
      "By looking within, we find not only the essence of our being but also the key to both this world and the next.",
  },
  {
    legend: "Averroes (Ibn Rushd)",
    nugget:
      "Ignorance leads to fear, knowledge leads to understanding, and understanding leads to love.",
    message:
      "Transition from ignorance to knowledge is transformative, paving the way from fear, through understanding, to love.",
  },
  {
    legend: "Maryam Mirzakhani",
    nugget:
      "The beauty of mathematics only shows itself to more patient followers.",
    message:
      "Mathematics reveals its true beauty and secrets only to those who approach it with patience and dedication.",
  },
  {
    legend: "Lubna of Cordoba",
    nugget: "The ink of a scholar is more holy than the blood of a martyr.",
    message:
      "Cherish the power of knowledge and the written word, for they have the power to inspire change beyond battles.",
  },
  {
    legend: "Al-Ma'arri",
    nugget:
      "Do not suppose the statements of the prophets to be true; the sacred books are only such a set of idle tales.",
    message:
      "Question everything, including the teachings of prophets. True wisdom lies in the pursuit of knowledge and understanding.",
  },
  {
    legend: "Jalaluddin Akbar",
    nugget:
      "In all religions, the essence of faith is one; only it is expressed in different forms.",
    message:
      "At the heart of every faith lies a universal truth, though it may be wrapped in diverse traditions and expressions.",
  },
  {
    legend: "Sultan Razia",
    nugget: "A monarch owes a duty not to his throne, but to his people.",
    message:
      "True leadership is serving the people, not ruling over them; a leader's greatness lies in their service.",
  },
  {
    legend: "Mimar Sinan",
    nugget:
      "Architecture is the music of spaces in harmony with their purpose.",
    message:
      "The true essence of architecture lies in creating spaces that sing in harmony with their intended purpose.",
  },
  {
    legend: "Nizam al-Mulk",
    nugget:
      "The heart of a king is in the hands of God; but the hearts of the people are in the hands of a king.",
    message:
      "Leaders are guided by the divine, but their true power lies in winning the hearts and trust of their people.",
  },
  {
    legend: "Ibn al-Haytham",
    nugget:
      "The duty of the man who investigates the writings of scientists, if learning the truth is his goal, is to make himself an enemy of all that he reads.",
    message:
      "Pursue truth by questioning and challenging what you read; skepticism is a scholar's greatest tool.",
  },
  {
    legend: "Safiya Zaghloul",
    nugget: "Freedom and dignity are more precious than bread.",
    message:
      "The human spirit craves freedom and dignity above all, essentials that nourish the soul beyond physical needs.",
  },
  {
    legend: "Mehmed the Conqueror",
    nugget: "The world is a bridge; pass over it, but build no houses on it.",
    message:
      "Life is transient, a passage not to be burdened with worldly attachments but to be traversed with purpose.",
  },
  {
    legend: "Shirin Ebadi",
    nugget:
      "Human rights are not a privilege granted by the few, they are a liberty entitled to all.",
    message:
      "Human rights are universal, not gifts but entitlements due to every individual, irrespective of their circumstance.",
  },
  {
    legend: "Abu Hamid al-Ghazali",
    nugget:
      "The real work is in the Heart: Wake up your Heart! Because when the heart is awake, it is like a lamp that lights the whole house.",
    message:
      "Awakening the heart enlightens our entire being, guiding us with clarity and illuminating the path of wisdom.",
  },
  {
    legend: "Tariq ibn Ziyad",
    nugget: "We have not crossed the sea because we are made of sugar candy.",
    message:
      "Our journey was not undertaken for ease and comfort but for achieving greatness through resilience and courage.",
  },
  {
    legend: "Abbas Ibn Firnas",
    nugget:
      "Seeking knowledge is seeking life, and the pursuit of it is never idle.",
    message:
      "The quest for knowledge breathes life into our existence, a pursuit rich with purpose and unending discovery.",
  },
  {
    legend: "Fatimah bint Muhammad",
    nugget:
      "The best of you are those who are best to their families, and I am the best to my family.",
    message:
      "True virtue is shown through kindness and excellence in our closest relationships, reflecting our genuine character.",
  },
  {
    legend: "Omar Khayyam",
    nugget: "Be happy for this moment. This moment is your life.",
    message:
      "Embrace the present with joy, for it is not just a fleeting moment, but the very essence of life itself.",
  },
  {
    legend: "Rabia Basri",
    nugget: "Love should be as natural as living and breathing.",
    message:
      "Let love flow effortlessly within our lives, as essential and natural as the very air we breathe.",
  },
  {
    legend: "Al-Khwarizmi",
    nugget:
      "That which is measurable, let us measure; that which is not, let us ponder.",
    message:
      "Embrace the known with measurement and the unknown with contemplation, balancing our understanding of the world.",
  },
  {
    legend: "Hafez",
    nugget:
      "I wish I could show you when you are lonely or in darkness the astonishing light of your own being.",
    message:
      "In moments of solitude or despair, remember the incredible light that resides within you, waiting to shine brightly.",
  },
  {
    legend: "Ibn Hazm",
    nugget: "The soul is made for action, and cannot rest till it be employed.",
    message:
      "Our essence craves purpose and engagement, finding no satisfaction until it embarks on meaningful action.",
  },
  {
    legend: "Yasmin Mogahed",
    nugget:
      "If you want to focus more on Allah in your prayers, focus more on Him outside your prayers.",
    message:
      "To deepen your connection with Allah during prayers, integrate mindfulness of Him in every aspect of your daily life.",
  },
  {
    legend: "Abu Hanifa",
    nugget: "Understanding is the mother of safety and the granary of life.",
    message:
      "True safety and abundance in life come from understanding, the foundation upon which wisdom and peace are built.",
  },
  {
    legend: "Al-Jahiz",
    nugget: "The power of the pen is greater than the sword.",
    message:
      "Ideas and words wielded wisely have the power to change the world far beyond the might of any weapon.",
  },
  {
    legend: "Hassan Fathy",
    nugget: "Architecture is the art of how to waste space.",
    message:
      "True architectural mastery lies in creating beauty and purpose in spaces, making no place go unused.",
  },
  {
    legend: "Rabia Al-Adawiyya",
    nugget:
      "My love is contingent upon the essence of my Creator, not upon the conditions of creation.",
    message:
      "Divine love transcends worldly conditions, rooted deeply in the essence of the Creator's eternal presence.",
  },
  {
    legend: "Muhammad al-Baqir",
    nugget: "Knowledge enlivens the soul.",
    message:
      "Seeking knowledge is not merely an intellectual pursuit but a vital act that gives life to the soul.",
  },
  {
    legend: "Ahmad Shawqi",
    nugget: "Time has a sword; if you don't cut it, it will cut you.",
    message:
      "Time is relentless; if we don't make purposeful use of it, it will pass us by, leaving its mark.",
  },
  {
    legend: "Umm Kulthum",
    nugget: "Art is the language of the entire human personality.",
    message:
      "Art expresses the depths of the human spirit, embodying the complexity and richness of human experience.",
  },
  {
    legend: "Ibn Hazm",
    nugget:
      "The soul that loves with purity considers not the gift of the lover, but the love of the giver.",
    message:
      "In true love, the value lies not in the gift given, but in the sincerity and purity of the giver's love.",
  },
  {
    legend: "Fazlur Rahman Khan",
    nugget: "The technical mind is a servant of the creative mind.",
    message:
      "Technical skills and knowledge are tools that, when guided by creativity, can achieve wonders beyond imagination.",
  },
  {
    legend: "Leila Ahmed",
    nugget:
      "The veil is neither here nor there: it's the minds that need to be unveiled.",
    message:
      "The focus should not be on outward symbols but on opening minds to understanding and embracing diversity.",
  },
  {
    legend: "Zaha Hadid",
    nugget: "There are 360 degrees, so why stick to one?",
    message:
      "Embrace the vast possibilities in creativity and perspective; innovation thrives when we explore all angles.",
  },
  {
    legend: "Ibn Tufail",
    nugget:
      "Intellect is the guide of the soul, leading it through the murky uncertainties of life.",
    message:
      "Let intellect be your compass, guiding your soul through life's complexities towards clarity and enlightenment.",
  },
  {
    legend: "Abdul Qadir Jilani",
    nugget:
      "Patience is the key to relief, as despair is the precursor to relief.",
    message:
      "In patience, find the strength to endure, for after every hardship comes ease, a cycle of life's tests.",
  },
  {
    legend: "Mohamed Bamba",
    nugget: "Seek not the world, for it is a bridge to the hereafter.",
    message:
      "Focus your pursuits beyond the fleeting, for true fulfillment lies in what we build for the eternal journey.",
  },
  {
    legend: "Tariq ibn Ziyad",
    nugget:
      "Burn the boats; return is not an option. Victory is our only path.",
    message:
      "Embrace total commitment to your goals; let the prospect of retreat fade, making way for undivided focus on success.",
  },
  {
    legend: "Ibn Al-Qayyim",
    nugget:
      "The heart is like a bird: love as its head and its two wings are hope and fear.",
    message:
      "Balance your heart with love, hope, and fear; like a bird, it needs all three to soar towards divine heights.",
  },
  {
    legend: "Al-Khwarizmi",
    nugget: "The essence of algebra is to make the unknown known.",
    message:
      "In algebra, as in life, our quest is to unveil mysteries, turning the shadows of the unknown into light.",
  },
  {
    legend: "Hakeem Olajuwon",
    nugget: "Hard work beats talent when talent fails to work hard.",
    message:
      "Dedication and effort are paramount; they elevate talent into greatness through relentless pursuit and discipline.",
  },
  {
    legend: "Maimuna bint al-Harith",
    nugget:
      "In understanding and kindness, we find the true essence of companionship.",
    message:
      "Companionship thrives on understanding and kindness, foundations that build lasting connections and mutual respect.",
  },
  {
    legend: "Mulla Sadra",
    nugget:
      "Knowledge is the light that illuminates the path of the soul's journey.",
    message:
      "Embrace knowledge as the radiant beacon that guides the soul's spiritual ascent and unveils the deeper truths of existence.",
  },
  {
    legend: "Al-Farabi",
    nugget:
      "The pursuit of knowledge is the highest virtue and the greatest attainment of happiness.",
    message:
      "Recognize the noble pursuit of knowledge as the epitome of virtue and the ultimate source of true happiness and fulfillment.",
  },
  {
    legend: "Al-Khwarizmi",
    nugget: "Mathematics is the key that unlocks the secrets of the universe.",
    message:
      "Embrace the power of mathematics, for it is the universal language that decodes the mysteries of creation.",
  },
  {
    legend: "Thabit ibn Qurra",
    nugget:
      "Knowledge is a garden that grows with cultivation and withers with neglect.",
    message:
      "Nurture your knowledge as you would a cherished garden, tending to it with diligence and care, lest it wither from inattention.",
  },
  {
    legend: "Al-Razi",
    nugget: "The truth is truth, no matter who utters it.",
    message:
      "Embrace truth without prejudice, for its value transcends the messenger and is judged by its intrinsic merit.",
  },
  {
    legend: "Ibn Nafis",
    nugget:
      "Knowledge is the light that dispels the shadows of ignorance and illuminates the path to wisdom.",
    message:
      "Allow knowledge to be your guiding light, dispelling the obscuring shadows of ignorance and revealing the way to true wisdom.",
  },
  {
    legend: "Al-Sufi",
    nugget:
      "The study of the heavens is the key to understanding the grandeur of creation.",
    message:
      "Look to the heavens with awe and curiosity, for in their vast expanse lies the key to comprehending the magnificence of the Divine creation.",
  },
  {
    legend: "Ibn Bajjah",
    nugget:
      "Wisdom is the fruit of knowledge, nurtured by reflection and experience.",
    message:
      "Cultivate wisdom by allowing knowledge to bear fruit, nourished by deep reflection and the lessons of lived experience.",
  },
  {
    legend: "Ibn Tufail",
    nugget:
      "The true seeker of knowledge must embrace solitude, for in silence lies the path to self-discovery.",
    message:
      "Embrace periods of solitude and silence, for within that stillness lies the gateway to profound self-knowledge and inner awakening.",
  },
  {
    legend: "Al-Suhrawardi",
    nugget:
      "Knowledge is the light that illuminates the path to enlightenment.",
    message:
      "Allow knowledge to be your guiding light, revealing the way to true enlightenment and spiritual transcendence.",
  },
  {
    legend: "Ibn Arabi",
    nugget:
      "The universe is a book, and those who cannot read are deemed illiterate.",
    message:
      "Cultivate the ability to read the vast book of the universe, for those who lack this skill are truly illiterate in the greatest sense.",
  },
  {
    legend: "Al-Mas'udi",
    nugget:
      "Knowledge is the treasure that enriches the soul and endures beyond material wealth.",
    message:
      "Amass the true wealth of knowledge, for it enriches the soul and endures long after material riches have faded.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "Knowledge without ethics is like a ship without a rudder, destined to drift aimlessly.",
    message:
      "Anchor your knowledge with a firm ethical foundation, lest it become a rudderless vessel, adrift without purpose or direction.",
  },
  {
    legend: "Al-Muqaddasi",
    nugget:
      "The true traveler is one who seeks knowledge as well as experiences.",
    message:
      "Embark on your journeys with an insatiable thirst for knowledge, for the true traveler is one who returns not only with experiences but also with expanded wisdom.",
  },
  {
    legend: "Ibn Hazm",
    nugget:
      "Knowledge without humility is like a tree without roots, destined to topple with the slightest wind.",
    message:
      "Cultivate humility alongside your knowledge, for without it, your wisdom lacks the deep roots necessary to withstand the storms of life.",
  },
  {
    legend: "Al-Zahrawi",
    nugget:
      "Knowledge is the scalpel that cuts through the darkness of ignorance and heals the wounds of doubt.",
    message:
      "Wield the scalpel of knowledge to excise the darkness of ignorance and heal the wounds of doubt that afflict the human condition.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "The pursuit of knowledge demands the courage to question the accepted and the humility to reconsider one's own convictions.",
    message:
      "Approach the quest for knowledge with the bravery to challenge conventional wisdom and the humility to reevaluate your own firmly held beliefs.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge is the mirror that reflects the true nature of the world and the human condition.",
    message:
      "Gaze into the mirror of knowledge to glimpse the authentic reflection of the world around us and the depths of the human experience.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "Knowledge without ethics is a poison that corrupts the soul and poisons the mind.",
    message:
      "Guard against the corrupting influence of knowledge devoid of ethics, for it is a poison that taints the soul and clouds the mind.",
  },
  {
    legend: "Fatima Al-Fihri",
    nugget:
      "Knowledge is the key that unlocks the doors of opportunity and empowers individuals and communities.",
    message:
      "Embrace knowledge as the master key that opens doors of opportunity, empowering both individuals and entire communities to thrive and flourish.",
  },
  {
    legend: "Ibn Battuta",
    nugget:
      "The true measure of a person's wealth lies not in material possessions but in the richness of their knowledge and experiences.",
    message:
      "Redefine your concept of wealth by valuing the priceless treasures of knowledge and lived experiences over fleeting material possessions.",
  },
  {
    legend: "Al-Kindi",
    nugget:
      "Knowledge without application is like a mirage in the desert, tantalizing but ultimately unfulfilling.",
    message:
      "Strive to apply the knowledge you acquire, lest it remain a mere illusion, promising satisfaction yet leaving you unfulfilled.",
  },
  {
    legend: "Al-Ghazali",
    nugget:
      "Knowledge is the key that unlocks the shackles of ignorance and sets the soul free.",
    message:
      "Embrace knowledge as the liberating force that breaks the chains of ignorance and empowers your soul to soar to new heights.",
  },
  {
    legend: "Ibn Sina (Avicenna)",
    nugget:
      "Knowledge is the ladder that leads to the heights of wisdom and understanding.",
    message:
      "Climb the rungs of knowledge, one step at a time, for it is the ascent that will lead you to the lofty peaks of wisdom and profound understanding.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "Knowledge without curiosity is like a stagnant pond, stale and lifeless.",
    message:
      "Nurture an insatiable curiosity, for it is the wellspring that keeps the waters of knowledge fresh and vibrant.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge is the seed that blossoms into the flower of progress and enlightenment.",
    message:
      "Plant the seeds of knowledge and tend to them with care, for they will blossom into the vibrant flowers of progress and enlightenment.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "Knowledge is the compass that guides the traveler through the uncharted realms of discovery.",
    message:
      "Equip yourself with the compass of knowledge as you embark on your journeys of discovery, for it will be your trusted guide through the uncharted territories.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Knowledge without reason is like a ship without a rudder, destined to drift aimlessly on the seas of uncertainty.",
    message:
      "Anchor your knowledge with the steadying force of reason, lest it become a rudderless vessel, adrift on the turbulent seas of uncertainty.",
  },
  {
    legend: "Al-Khawarizmi",
    nugget:
      "Knowledge is the key that unlocks the secrets of the universe, revealing the intricate patterns that govern creation.",
    message:
      "Wield the key of knowledge to unveil the hidden secrets of the universe, unraveling the intricate patterns that weave through the tapestry of creation.",
  },
  {
    legend: "Jalal ad-Din Muhammad Rumi",
    nugget:
      "Knowledge is the wine that intoxicates the soul with the ecstasy of understanding.",
    message:
      "Imbibe the intoxicating wine of knowledge, allowing it to inebriate your soul with the profound ecstasy of deeper understanding.",
  },
  {
    legend: "Ibn Firnas",
    nugget:
      "Knowledge is the wind that lifts the soul to soar above the limitations of the mundane world.",
    message:
      "Let the winds of knowledge lift your soul and empower it to soar above the confines of the mundane, reaching new heights of awareness and transcendence.",
  },
  {
    legend: "Al-Haytham",
    nugget:
      "Knowledge is the light that illuminates the path to truth, dispelling the shadows of falsehood and misconception.",
    message:
      "Embrace knowledge as the radiant light that guides you along the path of truth, banishing the obscuring shadows of falsehood and misconception.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Knowledge without humility breeds arrogance, while humility without knowledge breeds ignorance.",
    message:
      "Strike a harmonious balance between knowledge and humility, for knowledge without humility breeds arrogance, while humility without knowledge perpetuates ignorance.",
  },
  {
    legend: "Al-Farabi",
    nugget:
      "Knowledge is the key that unlocks the gates of understanding, leading to the harmonious coexistence of diverse peoples and cultures.",
    message:
      "Wield the key of knowledge to unlock the gates of understanding, fostering harmony and coexistence among diverse peoples and cultures.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge without wisdom is like a ship without a captain, destined to flounder on the seas of uncertainty.",
    message:
      "Temper your knowledge with the guiding force of wisdom, lest it become a rudderless vessel, floundering on the turbulent seas of uncertainty.",
  },
  {
    legend: "Al-Razi",
    nugget:
      "Knowledge is the light that dispels the darkness of superstition and illuminates the path to reason and enlightenment.",
    message:
      "Allow the light of knowledge to banish the dark shadows of superstition, guiding you toward the enlightened realms of reason and understanding.",
  },
  {
    legend: "Ibn Arabi",
    nugget:
      "Knowledge is the mirror that reflects the grandeur of the Divine, revealing the intricate patterns that weave through creation.",
    message:
      "Gaze into the mirror of knowledge to behold the grandeur of the Divine, unveiled through the intricate patterns that interweave throughout creation.",
  },
  {
    legend: "Al-Muqaddasi",
    nugget:
      "Knowledge is the compass that guides the seeker on the journey of self-discovery and spiritual enlightenment.",
    message:
      "Equip yourself with the compass of knowledge as you embark on the profound journey of self-discovery and spiritual enlightenment.",
  },
  {
    legend: "Ibn Hazm",
    nugget:
      "Knowledge without ethics is like a sword without a sheath, capable of inflicting harm without restraint.",
    message:
      "Temper the blade of knowledge with the sheath of ethics, lest it become a dangerous weapon capable of inflicting harm without restraint.",
  },
  {
    legend: "Al-Zahrawi",
    nugget:
      "Knowledge is the balm that heals the wounds of ignorance and the ailments of the mind and soul.",
    message:
      "Apply the soothing balm of knowledge to heal the wounds of ignorance and treat the ailments that afflict the mind and soul.",
  },
  {
    legend: "Al-Ghazali",
    nugget:
      "Knowledge without action is like a tree without fruit, offering shade but no sustenance.",
    message:
      "Cultivate knowledge that bears the fruit of action, for knowledge without application offers only a fleeting shadow, devoid of true nourishment.",
  },
  {
    legend: "Imam Al-Shafi'i",
    nugget:
      "Knowledge is the armor that shields the believer from the arrows of doubt and confusion.",
    message:
      "Don the armor of knowledge to safeguard yourself from the piercing arrows of doubt and the assaults of confusion that threaten to undermine your faith.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge is the lamp that illuminates the path to progress and the beacon that guides civilizations toward enlightenment.",
    message:
      "Let the lamp of knowledge light your way toward progress, and allow it to be the guiding beacon that leads entire civilizations toward the shores of enlightenment.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "Knowledge is the bridge that spans the divide between ignorance and understanding, connecting cultures and fostering mutual respect.",
    message:
      "Build bridges of knowledge to span the divides of ignorance, fostering understanding and mutual respect between diverse cultures and peoples.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Knowledge without critical thinking is like a ship without a rudder, destined to drift aimlessly on the seas of uncertainty.",
    message:
      "Equip your vessel of knowledge with the rudder of critical thinking, lest it become a rudderless craft, adrift on the turbulent seas of uncertainty.",
  },
  {
    legend: "Al-Khawarizmi",
    nugget:
      "Knowledge is the key that unlocks the secrets of the universe, revealing the intricate patterns that weave through the tapestry of creation.",
    message:
      "Wield the key of knowledge to unveil the hidden secrets of the universe, unraveling the intricate patterns that interweave throughout the tapestry of creation.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "Knowledge without discipline is like a garden overgrown with weeds, choking out the fruits of wisdom.",
    message:
      "Cultivate discipline alongside your knowledge, lest it become an overgrown garden, suffocated by the weeds of disarray and unable to bear the fruits of wisdom.",
  },
  {
    legend: "Ibn Sina (Avicenna)",
    nugget:
      "Knowledge is the elixir that rejuvenates the soul, revitalizing the spirit with the essence of understanding.",
    message:
      "Imbibe the rejuvenating elixir of knowledge, allowing it to revitalize your soul and infuse your spirit with the invigorating essence of understanding.",
  },
  {
    legend: "Al-Farabi",
    nugget:
      "Knowledge without virtue is like a sword without a hilt, powerful yet uncontrollable and capable of inflicting harm.",
    message:
      "Temper the blade of knowledge with the hilt of virtue, lest it become an uncontrollable force, powerful yet capable of inflicting unintended harm.",
  },
  {
    legend: "Ibn Firnas",
    nugget:
      "Knowledge is the wind that lifts the soul to soar above the limitations of the mundane world, exploring new horizons of understanding.",
    message:
      "Let the winds of knowledge lift your soul, empowering it to soar above the confines of the mundane and explore new horizons of profound understanding.",
  },
  {
    legend: "Al-Haytham",
    nugget:
      "Knowledge without empirical observation is like a map without a compass, unable to accurately chart the terrain of reality.",
    message:
      "Complement your knowledge with empirical observation, lest it become a map without a compass, unable to accurately chart the true terrain of reality.",
  },
  {
    legend: "Ibn Battuta",
    nugget:
      "Knowledge is the currency that unlocks the doors of opportunity and enriches the traveler's experiences.",
    message:
      "Amass the currency of knowledge, for it unlocks doors of opportunity and enriches the experiences of the intrepid traveler on life's journey.",
  },
  {
    legend: "Al-Muqaddasi",
    nugget:
      "Knowledge is the compass that guides the seeker through the uncharted realms of spiritual discovery and self-transcendence.",
    message:
      "Equip yourself with the compass of knowledge as you embark on the uncharted realms of spiritual discovery and self-transcendence.",
  },
  {
    legend: "Ibn Hazm",
    nugget:
      "Knowledge without patience is like a seed sown on infertile ground, unable to take root and blossom into wisdom.",
    message:
      "Nurture patience alongside your knowledge, lest it be like a seed sown on infertile ground, unable to take root and blossom into the flower of wisdom.",
  },
  {
    legend: "Al-Zahrawi",
    nugget:
      "Knowledge is the scalpel that cuts through the darkness of ignorance, revealing the intricate workings of the human condition.",
    message:
      "Wield the scalpel of knowledge to cut through the darkness of ignorance, unveiling the intricate workings of the human condition and the mysteries of existence.",
  },
  {
    legend: "Ibn Arabi",
    nugget:
      "Knowledge is the key that unlocks the gates of perception, revealing the hidden dimensions of reality that lie beyond the veil of the seen.",
    message:
      "Use the key of knowledge to unlock the gates of perception, unveiling the hidden dimensions of reality that lie beyond the veil of the seen world.",
  },
  {
    legend: "Al-Mas'udi",
    nugget:
      "Knowledge is the compass that guides the explorer through the uncharted territories of human potential and achievement.",
    message:
      "Equip yourself with the compass of knowledge as you embark on the exploration of uncharted territories, pushing the boundaries of human potential and achievement.",
  },
  {
    legend: "Nana Asma'u",
    nugget:
      "Knowledge is the seed that blossoms into the flower of empowerment, enabling individuals and communities to thrive.",
    message:
      "Plant the seeds of knowledge and nurture them with care, for they will blossom into the vibrant flowers of empowerment, enabling individuals and communities to flourish.",
  },
  {
    legend: "Al-Sufi",
    nugget:
      "Knowledge is the telescope that brings the vast expanses of the cosmos within reach, revealing the intricate patterns that govern the celestial realms.",
    message:
      "Gaze through the telescope of knowledge to bring the vast expanses of the cosmos within your grasp, unveiling the intricate patterns that govern the celestial realms.",
  },
  {
    legend: "Ibn Tufail",
    nugget:
      "Knowledge without introspection is like a ship without a rudder, destined to drift aimlessly on the seas of uncertainty and self-delusion.",
    message:
      "Anchor your knowledge with the steadying force of introspection, lest it become a rudderless vessel, adrift on the turbulent seas of uncertainty and self-delusion.",
  },
  {
    legend: "Al-Kindi",
    nugget:
      "Knowledge is the seed that blossoms into the garden of wisdom, where the flowers of understanding and insight bloom.",
    message:
      "Nurture the seeds of knowledge, allowing them to blossom into a vibrant garden of wisdom, adorned with the fragrant flowers of understanding and profound insight.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Knowledge without courage is like a sword without a warrior, capable yet undeployed in the face of ignorance and injustice.",
    message:
      "Temper your knowledge with the courage to wield it as a warrior wields their sword, deploying it boldly against the forces of ignorance and injustice.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "Knowledge is the river that nourishes the fertile fields of progress, sustaining the growth of civilizations and cultures.",
    message:
      "Allow the river of knowledge to flow freely, nourishing the fertile fields of progress and sustaining the growth of thriving civilizations and vibrant cultures.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge is the foundation upon which the edifice of human achievement is built, supporting the structures of progress and enlightenment.",
    message:
      "Lay the solid foundation of knowledge, upon which the towering edifices of human achievement can be erected, supporting the structures of progress and enlightenment.",
  },
  {
    legend: "Al-Razi",
    nugget:
      "Knowledge without empathy is like a sword without a sheath, capable of inflicting harm without regard for the human condition.",
    message:
      "Temper the blade of knowledge with the sheath of empathy, lest it become a dangerous weapon, capable of inflicting harm without regard for the human experience.",
  },
  {
    legend: "Ibn Nafis",
    nugget:
      "Knowledge is the breath that animates the body of human civilization, infusing it with the vitality of progress and enlightenment.",
    message:
      "Allow the breath of knowledge to animate the body of human civilization, infusing it with the invigorating vitality of progress and the oxygen of enlightenment.",
  },
  {
    legend: "Al-Sufi",
    nugget:
      "Knowledge is the telescope that brings the vast expanses of the cosmos within reach, revealing the intricate patterns that govern the celestial realms.",
    message:
      "Gaze through the telescope of knowledge to bring the vast expanses of the cosmos within your grasp, unveiling the intricate patterns that govern the celestial realms.",
  },
  {
    legend: "Ibn Bajjah",
    nugget:
      "Knowledge without contemplation is like a seed that fails to take root, unable to germinate and bear the fruits of wisdom.",
    message:
      "Nurture contemplation alongside your knowledge, lest it be like a seed that fails to take root, unable to germinate and bear the nourishing fruits of wisdom.",
  },
  {
    legend: "Ibn Tufail",
    nugget:
      "Knowledge without a sense of wonder is like a map without a destination, guiding yet lacking the inspiration to embark on the journey.",
    message:
      "Cultivate a sense of wonder alongside your knowledge, lest it become a mere map without a destination, lacking the inspiration to embark on the transformative journey of discovery.",
  },
  {
    legend: "Al-Suhrawardi",
    nugget:
      "Knowledge is the light that illuminates the path to enlightenment, guiding the seeker through the realms of the seen and the unseen.",
    message:
      "Allow the light of knowledge to illuminate your path, guiding you through the realms of the seen and the unseen, toward the shores of enlightenment.",
  },
  {
    legend: "Ibn Arabi",
    nugget:
      "Knowledge without reverence is like a tree without roots, unable to anchor itself in the fertile soil of wisdom and understanding.",
    message:
      "Cultivate reverence alongside your knowledge, lest it become a rootless tree, unable to anchor itself in the fertile soil of wisdom and profound understanding.",
  },
  {
    legend: "Al-Mas'udi",
    nugget:
      "Knowledge is the compass that guides the explorer through the uncharted territories of human potential and achievement, revealing new horizons of possibility.",
    message:
      "Equip yourself with the compass of knowledge as you embark on the exploration of uncharted territories, revealing new horizons of human potential and achievement.",
  },
  {
    legend: "Al-Jahiz",
    nugget:
      "Knowledge without creativity is like a well without water, offering depth yet lacking the refreshing flow of innovation and insight.",
    message:
      "Nurture creativity alongside your knowledge, lest it become a well without water, offering depth yet lacking the refreshing flow of innovation and profound insight.",
  },
  {
    legend: "Al-Muqaddasi",
    nugget:
      "Knowledge is the map that charts the course of human civilization, guiding us through the landscapes of progress and enlightenment.",
    message:
      "Consult the map of knowledge to chart the course of human civilization, guiding us through the varied landscapes of progress and the uncharted territories of enlightenment.",
  },
  {
    legend: "Ibn Hazm",
    nugget:
      "Knowledge without integrity is like a house built on shifting sands, unable to withstand the storms of doubt and falsehood.",
    message:
      "Anchor your knowledge with the bedrock of integrity, lest it become a house built on shifting sands, unable to withstand the tempestuous storms of doubt and falsehood.",
  },
  {
    legend: "Al-Zahrawi",
    nugget:
      "Knowledge is the scalpel that cuts through the darkness of ignorance, revealing the intricate workings of the human condition and the path to healing.",
    message:
      "Wield the scalpel of knowledge to cut through the darkness of ignorance, unveiling the intricate workings of the human condition and illuminating the path to healing.",
  },
  {
    legend: "Ibn Rushd (Averroes)",
    nugget:
      "Knowledge without reason is like a ship without a rudder, destined to drift aimlessly on the seas of uncertainty and misconception.",
    message:
      "Anchor your knowledge with the steadying force of reason, lest it become a rudderless vessel, adrift on the turbulent seas of uncertainty and misconception.",
  },
  {
    legend: "Ibn Khaldun",
    nugget:
      "Knowledge is the seed that blossoms into the garden of civilization, where the fruits of progress and enlightenment are cultivated.",
    message:
      "Plant the seeds of knowledge and nurture them with care, for they will blossom into a vibrant garden of civilization, bearing the nourishing fruits of progress and enlightenment.",
  },
  {
    legend: "Al-Biruni",
    nugget:
      "Knowledge without curiosity is like a well without a bucket, offering depth yet unable to quench the thirst for understanding.",
    message:
      "Nurture an insatiable curiosity alongside your knowledge, lest it become a well without a bucket, offering depth yet unable to quench the thirst for profound understanding.",
  },
  {
    legend: "Fatima Al-Fihri",
    nugget:
      "Knowledge is the key that unlocks the doors of opportunity, empowering individuals and communities to flourish and reach their full potential.",
    message:
      "Wield the key of knowledge to unlock the doors of opportunity, empowering individuals and entire communities to flourish and reach their full, extraordinary potential.",
  },
];

export default legendsNuggets;
