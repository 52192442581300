import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContentLayout from "../ContentLayout/ContentLayout";
import quranChapters from "./quranChapters"; // Ensure this path is correct
import { GrPrevious, GrNext } from "react-icons/gr";
import { LuChevronFirst, LuChevronLast } from "react-icons/lu";

const QuranInsights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const titlesPerPage = 10;
  const totalPages = Math.ceil(quranChapters.length / titlesPerPage);

  const indexOfLastTitle = currentPage * titlesPerPage;
  const indexOfFirstTitle = indexOfLastTitle - titlesPerPage;
  const currentTitles = quranChapters.slice(
    indexOfFirstTitle,
    indexOfLastTitle
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to format title into a URL-friendly string
  const formatTitleForURL = (title) => {
    return title
      .toLowerCase() // convert to lowercase
      .replace(/<[^>]+>/g, "") // remove HTML tags
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, "") // remove special characters except hyphens (corrected)
      .replace(/-{2,}/g, "-"); // replace multiple hyphens with a single one (corrected)
  };

  // Dynamic pagination group based on current page
  const getPaginationGroup = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    if (endPage === totalPages) startPage = Math.max(endPage - 2, 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <ContentLayout>
      <h1 className="text-deeperPurple font-bold text-xl text-center uppercase mb-2">
        Quran Chapters Insights
      </h1>
      <h3 className="text-center text-base mb-4">
        Exploring the Quranic Chapters' Context and Revelation, Themes and
        Structure, Spiritual and Practical Guidance, Impact and Significance,
        and Application and Relevance.
      </h3>
      <div className="my-4 px-2">
        {currentTitles.map((chapter, index) => (
          <Link
            key={index}
            to={`/quranInsights/${formatTitleForURL(chapter.title)}`} // Use formatted title for URL
            className="block p-4 rounded-lg bg-white shadow-md my-2 hover:bg-deeperPurple hover:text-white text-base font-extralight" // Added text-base class here
            dangerouslySetInnerHTML={{ __html: chapter.title }}
          />
        ))}
      </div>

      <div className="flex justify-center mt-4 items-center mb-10">
        <button
          onClick={() => paginate(1)}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <LuChevronFirst size="28px" />
        </button>
        <button
          onClick={() => paginate(Math.max(currentPage - 1, 1))}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <GrPrevious size="28px" />
        </button>
        {getPaginationGroup().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => paginate(pageNumber)}
            className={`px-3 py-1 mx-1 rounded text-lg ${
              currentPage === pageNumber
                ? "bg-deeperPurple text-white hover:bg-deeperPurple hover:text-white"
                : "bg-gray-200 hover:bg-deeperPurple hover:text-white"
            }`}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={() => paginate(Math.min(currentPage + 1, totalPages))}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <GrNext size="28px" />
        </button>
        <button
          onClick={() => paginate(totalPages)}
          className="px-2 py-1 mx-1 rounded text-lg hover:bg-deeperPurple hover:text-white"
        >
          <LuChevronLast size="28px" />
        </button>
      </div>
    </ContentLayout>
  );
};

export default QuranInsights;
