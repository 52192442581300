import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logoIcon from "../../assets/logo.png";
import html2canvas from "html2canvas";
import { useGradient } from "../Background/Gradient";
import { FiDownload } from "react-icons/fi";
import { LuShare2 } from "react-icons/lu";

const ResultModal = ({
  score,
  totalQuestions,
  currentCategory,
  onRestart,
  userName,
}) => {
  const [gradient, updateGradient] = useGradient();

  useEffect(() => {
    updateGradient();
  }, [score, totalQuestions, updateGradient]); // Add updateGradient as a dependency

  // Calculate score percentage and determine the message
  const scorePercentage = (score / totalQuestions) * 100;
  let resultMessage;
  if (scorePercentage >= 86) {
    resultMessage = "You are Astonishing!👑";
  } else if (scorePercentage >= 71) {
    resultMessage = "You are Exceptional!🌟";
  } else if (scorePercentage >= 51) {
    resultMessage = "You are Fantastic!🚀";
  } else if (scorePercentage >= 31) {
    resultMessage = "You are Impressive!🔥";
  } else {
    resultMessage = "Great Start!👍";
  }

  const shareResult = () => {
    html2canvas(document.querySelector("#resultBox"), { scale: 4 }).then(
      (canvas) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], "result.png", { type: "image/png" });

          // Check if the Web Share API is available and supports sharing files
          if (
            navigator.share &&
            navigator.canShare &&
            navigator.canShare({ files: [file] })
          ) {
            navigator
              .share({
                files: [file],
                title: "Islamic Trivia Result",
                text: `Check out my score on Islamic Trivia! I scored ${score}/${totalQuestions}.`,
              })
              .then(() => console.log("Share was successful."))
              .catch((error) => console.error("Sharing failed", error));
          } else {
            // If Web Share API is not supported or cannot share files, upload to Cloudinary
            const formData = new FormData();
            formData.append("file", file);
            formData.append("upload_preset", "y7zkjnpy");

            const uploadURL = `https://api.cloudinary.com/v1_1/dndncnjcq/image/upload`;

            fetch(uploadURL, {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.secure_url) {
                  // Use the image URL for sharing or copying to clipboard
                  alert(
                    "Sharing is not supported on this browser. Image URL copied to clipboard."
                  );
                  navigator.clipboard.writeText(data.secure_url).then(() => {
                    alert("Image URL copied to clipboard.");
                  });
                } else {
                  throw new Error("Upload failed, no URL returned.");
                }
              })
              .catch((error) => {
                console.error("Failed to upload image to Cloudinary", error);
                alert("An error occurred while uploading the image.");
              });
          }
        }, "image/png");
      }
    );
  };

  const downloadResultImage = () => {
    html2canvas(document.querySelector("#resultBox"), { scale: 4 }).then(
      (canvas) => {
        // Generate a unique identifier for the filename using just the current timestamp
        const timestamp = Date.now(); // Current timestamp for uniqueness
        const uniqueFilename = `islam-nugget-score-${timestamp}.png`;

        // Create a link and set the URL using the canvas data
        const link = document.createElement("a");
        link.download = uniqueFilename;
        link.href = canvas.toDataURL("image/png");
        document.body.appendChild(link); // Append the link to the body
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove the link after triggering the download
      }
    );
  };

  return (
    <div
      className={
        "fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      }
    >
      <div className="relative top-20 mx-auto p-2 border w-80 shadow-lg rounded-md bg-gray-200 overflow-y-auto max-h-[calc(100vh-100px)] sm:mb-20 sm:-mt-10">
        <div
          id="resultBox"
          className={`resultBox mt-4 p-1 rounded shadow ${gradient}`}
        >
          <div className="flex justify-center mb-2 mt-2">
            <img src={logoIcon} alt="Islamic Trivia" className="w-16 h-16" />
          </div>
          <h3 className="text-center text-white text-2xl font-bold mb-2">
            {resultMessage}
          </h3>
          {/* Display the user's name if it exists */}
          {userName && (
            <p className="text-center text-white text-xl font-bold mb-2">
              {userName}.
            </p>
          )}
          <p className="text-center text-white text-2xl mb-2">You scored</p>
          <p className="text-center text-white text-4xl font-bold mb-3">
            {score}/{totalQuestions}
          </p>
          <p className="text-center text-white mb-2">
            Islamic Questions on <br />
            <span className="text-2xl">{currentCategory} Trivia</span>
          </p>
          <div className="my-2 text-center text-sm text-white">
            <p>Play more on muslimnuggets.com</p> Follow us @muslimnuggets
          </div>
        </div>
        <div className="flex justify-center mt-8 mb-2 sm:mb-8">
          <button
            onClick={downloadResultImage}
            className="p-2 px-5 bg-downloadColor hover:bg-deeperPurple text-white rounded"
            title="Download Score"
          >
            <FiDownload size="26px" />
          </button>
          <Link
            to="/triviaplus"
            onClick={onRestart}
            className="bg-green-700 text-white hover:bg-deeperPurple rounded p-2 mx-2"
          >
            Play Another
          </Link>
          <button
            onClick={shareResult}
            className="p-2 px-5 bg-blue-700 hover:bg-deeperPurple text-white rounded"
            title="Share Result"
          >
            <LuShare2 size="26px" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultModal;
