const islamicHistoryQuestions = [
  {
    question:
      "In which city was the Prophet Muhammad (peace be upon him) born?",
    options: ["Mecca", "Medina", "Jerusalem", "Baghdad"],
    answer: "Mecca",
    difficulty: "Easy",
  },
  {
    question: "What is the name of the holy book of Islam?",
    options: ["The Torah", "The Bible", "The Vedas", "The Quran"],
    answer: "The Quran",
    difficulty: "Easy",
  },
  {
    question:
      "In which city did Prophet Muhammad migrate to, marking the start of the Islamic calendar?",
    options: ["Mecca", "Medina", "Jerusalem", "Damascus"],
    answer: "Medina",
    difficulty: "Easy",
  },
  {
    question:
      "What is the term used for the pilgrimage to Mecca that Muslims must try to perform at least once in their lifetime?",
    options: ["Salah", "Hajj", "Zakat", "Sawm"],
    answer: "Hajj",
    difficulty: "Easy",
  },
  {
    question: "Which city is considered the third holiest site in Islam?",
    options: ["Mecca", "Medina", "Jerusalem", "Baghdad"],
    answer: "Jerusalem",
    difficulty: "Easy",
  },
  {
    question: "What significant event in Islamic history happened in 622 CE?",
    options: [
      "The birth of Muhammad",
      "The Battle of Badr",
      "The Hijrah",
      "The first revelation of the Quran",
    ],
    answer: "The Hijrah",
    difficulty: "Easy",
  },
  {
    question: "Who compiled the Quran into a single book?",
    options: [
      "Umar ibn Al-Khattab",
      "Ali ibn Abi Talib",
      "Abu Bakr",
      "Uthman ibn Affan",
    ],
    answer: "Uthman ibn Affan",
    difficulty: "Easy",
  },
  {
    question:
      "Which caliphate was established immediately after Muhammad’s death?",
    options: [
      "The Umayyad Caliphate",
      "The Rashidun Caliphate",
      "The Abbasid Caliphate",
      "The Ottoman Empire",
    ],
    answer: "The Rashidun Caliphate",
    difficulty: "Easy",
  },
  {
    question: "Who was the founder of the Ottoman Empire?",
    options: [
      "Suleiman the Magnificent",
      "Osman I",
      "Mehmed the Conqueror",
      "Bayezid I",
    ],
    answer: "Osman I",
    difficulty: "Easy",
  },
  {
    question:
      "What was the name of the treaty that ended hostilities between the Muslims and the Quraysh tribe of Mecca?",
    options: [
      "Treaty of Hudaybiyyah",
      "Treaty of Medina",
      "Treaty of Umar",
      "Treaty of Karbala",
    ],
    answer: "Treaty of Hudaybiyyah",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the Muslim leader during the Crusades known for recapturing Jerusalem?",
    options: [
      "Saladin",
      "Richard the Lionheart",
      "Suleiman the Magnificent",
      "Mehmed the Conqueror",
    ],
    answer: "Saladin",
    difficulty: "Easy",
  },
  {
    question: "What is the name given to the community of Muslims worldwide?",
    options: ["Ummah", "Caliphate", "Sharia", "Jihad"],
    answer: "Ummah",
    difficulty: "Easy",
  },
  {
    question: "What was the capital of the Umayyad Caliphate?",
    options: ["Baghdad", "Cordoba", "Damascus", "Cairo"],
    answer: "Damascus",
    difficulty: "Easy",
  },
  {
    question: "In Islamic architecture, what is a minaret?",
    options: ["A dome", "A tower", "A prayer hall", "A courtyard"],
    answer: "A tower",
    difficulty: "Easy",
  },
  {
    question:
      "Which Islamic dynasty is known for establishing the city of Baghdad?",
    options: [
      "The Abbasid Caliphate",
      "The Umayyad Caliphate",
      "The Fatimid Caliphate",
      "The Ayyubid Dynasty",
    ],
    answer: "The Abbasid Caliphate",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the famous Muslim explorer who traveled to Africa, Asia, and Europe in the 14th century?",
    options: ["Ibn Battuta", "Marco Polo", "Zheng He", "Vasco da Gama"],
    answer: "Ibn Battuta",
    difficulty: "Easy",
  },
  {
    question:
      "What was the primary achievement of Al-Khwarizmi in the field of science?",
    options: [
      "Developing algebra",
      "Discovering the circulation of blood",
      "Inventing the telescope",
      "Mapping the stars",
    ],
    answer: "Developing algebra",
    difficulty: "Easy",
  },
  {
    question:
      "Which caliph is credited with establishing the Bayt al-Hikma (House of Wisdom) in Baghdad?",
    options: ["Harun al-Rashid", "Al-Ma'mun", "Al-Mansur", "Abu Bakr"],
    answer: "Al-Ma'mun",
    difficulty: "Easy",
  },
  {
    question: "What is the Islamic term for 'striving in the way of Allah'?",
    options: ["Hajj", "Jihad", "Salah", "Zakat"],
    answer: "Jihad",
    difficulty: "Easy",
  },
  {
    question: "Which Islamic holiday celebrates the end of Ramadan?",
    options: ["Eid al-Fitr", "Eid al-Adha", "Ashura", "Laylat al-Qadr"],
    answer: "Eid al-Fitr",
    difficulty: "Easy",
  },
  {
    question:
      "What innovation is the Muslim scientist Alhazen (Ibn Al-Haytham) known for?",
    options: [
      "The concept of zero",
      "The laws of refraction",
      "The steam engine",
      "The theory of evolution",
    ],
    answer: "The laws of refraction",
    difficulty: "Easy",
  },
  {
    question: "Which Muslim leader was known as 'The Sword of Islam'?",
    options: [
      "Khalid ibn al-Walid",
      "Saladin",
      "Tariq ibn Ziyad",
      "Sultan Mehmed II",
    ],
    answer: "Khalid ibn al-Walid",
    difficulty: "Easy",
  },
  {
    question:
      "What significant Islamic structure was completed in 691 CE in Jerusalem?",
    options: [
      "Al-Aqsa Mosque",
      "The Dome of the Rock",
      "The Great Mosque of Cordoba",
      "The Hagia Sophia",
    ],
    answer: "The Dome of the Rock",
    difficulty: "Easy",
  },
  {
    question:
      "Which Islamic sect believes in a line of Imams descended from Ali ibn Abi Talib?",
    options: ["Sunni", "Shia", "Sufi", "Ahmadiyya"],
    answer: "Shia",
    difficulty: "Easy",
  },
  {
    question:
      "What is the name of the Islamic law derived from the Quran and the Hadith?",
    options: ["Sharia", "Fiqh", "Sunnah", "Tawhid"],
    answer: "Sharia",
    difficulty: "Easy",
  },
  {
    question:
      "Which city was the original home of the Umayyad Caliphate before it moved to Damascus?",
    options: ["Medina", "Mecca", "Jerusalem", "Cairo"],
    answer: "Medina",
    difficulty: "Easy",
  },
  {
    question: "What is the term for Islamic mysticism?",
    options: ["Sharia", "Fiqh", "Sufism", "Salafism"],
    answer: "Sufism",
    difficulty: "Easy",
  },
  {
    question:
      "In what year did the Ottoman Empire capture Constantinople, marking the end of the Byzantine Empire?",
    options: ["1453", "1492", "1526", "1571"],
    answer: "1453",
    difficulty: "Easy",
  },
  {
    question:
      "What was the primary reason for the split between Sunni and Shia Muslims?",
    options: [
      "Disagreements over fasting",
      "Disagreements over the Hajj",
      "Disagreements over the rightful successor to Muhammad",
      "Disagreements over prayer rituals",
    ],
    answer: "Disagreements over the rightful successor to Muhammad",
    difficulty: "Easy",
  },
  {
    question:
      "Which Mughal emperor is known for his policy of religious tolerance and the construction of the Taj Mahal?",
    options: ["Akbar", "Aurangzeb", "Shah Jahan", "Babur"],
    answer: "Shah Jahan",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the Andalusian Muslim scholar known for his extensive travels and detailed account of the Islamic world in the 14th century?",
    options: ["Ibn Battuta", "Al-Idrisi", "Ibn Khaldun", "Al-Ghazali"],
    answer: "Ibn Battuta",
    difficulty: "Easy",
  },
  {
    question: "What is 'Zakat', one of the Five Pillars of Islam?",
    options: [
      "Fasting during Ramadan",
      "Pilgrimage to Mecca",
      "Prayer five times a day",
      "Almsgiving or charity",
    ],
    answer: "Almsgiving or charity",
    difficulty: "Easy",
  },
  {
    question:
      "Which city became the cultural and political center of the Muslim world after the fall of the Umayyad Caliphate?",
    options: ["Cairo", "Baghdad", "Cordoba", "Constantinople"],
    answer: "Baghdad",
    difficulty: "Easy",
  },
  {
    question:
      "What Islamic architectural feature is designed to amplify the call to prayer?",
    options: ["Mihrab", "Minbar", "Dome", "Minaret"],
    answer: "Minaret",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the Muslim scholar responsible for the compilation of the Hadith collection known as Sahih al-Bukhari?",
    options: ["Imam Bukhari", "Imam Muslim", "Imam Malik", "Imam Hanbal"],
    answer: "Imam Bukhari",
    difficulty: "Easy",
  },
  {
    question:
      "What is the term for the Islamic law system derived from the Quran and the Sunnah?",
    options: ["Sharia", "Jihad", "Hijra", "Caliphate"],
    answer: "Sharia",
    difficulty: "Easy",
  },
  {
    question:
      "Which battle is considered the turning point that established Islam in Arabia?",
    options: [
      "Battle of Badr",
      "Battle of Uhud",
      "Battle of the Trench",
      "Conquest of Mecca",
    ],
    answer: "Battle of Badr",
    difficulty: "Moderate",
  },
  {
    question:
      "Who was the first caliph to succeed the Prophet Muhammad (peace be upon him)?",
    options: [
      "Abu Bakr",
      "Umar ibn al-Khattab",
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
    ],
    answer: "Abu Bakr",
    difficulty: "Moderate",
  },
  {
    question: "Which Muslim ruler conquered Spain in the early 8th century?",
    options: ["Tariq ibn Ziyad", "Saladin", "Nur al-Din Zangi", "Mehmed II"],
    answer: "Tariq ibn Ziyad",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Islamic dynasty that ruled in Spain from the 8th to 15th centuries?",
    options: [
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Fatimid Caliphate",
      "Almohad Caliphate",
    ],
    answer: "Umayyad Caliphate",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler defeated the Crusaders and recaptured Jerusalem in 1187?",
    options: ["Saladin", "Baybars", "Nur al-Din Zangi", "Alp Arslan"],
    answer: "Saladin",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Muslim dynasty that ruled in India from the 13th to the 19th century?",
    options: [
      "Mughal Dynasty",
      "Delhi Sultanate",
      "Safavid Dynasty",
      "Ottoman Dynasty",
    ],
    answer: "Mughal Dynasty",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the decimal numeral system to the West?",
    options: ["Al-Khwarizmi", "Avicenna", "Al-Razi", "Al-Battani"],
    answer: "Al-Khwarizmi",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler conquered Constantinople (present-day Istanbul) in 1453, ending the Byzantine Empire?",
    options: [
      "Mehmed II",
      "Saladin",
      "Suleiman the Magnificent",
      "Nur al-Din Zangi",
    ],
    answer: "Mehmed II",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Islamic architectural style is characterized by the use of horseshoe arches and intricate geometric patterns?",
    options: ["Mughal", "Ottoman", "Moorish", "Mamluk"],
    answer: "Moorish",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Muslim dynasty that ruled in Iran from the 16th to the 18th century?",
    options: [
      "Safavid Dynasty",
      "Qajar Dynasty",
      "Afsharid Dynasty",
      "Zand Dynasty",
    ],
    answer: "Safavid Dynasty",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the Arabic numeral system to Europe?",
    options: ["Al-Khwarizmi", "Gerbert of Aurillac", "Fibonacci", "Al-Battani"],
    answer: "Gerbert of Aurillac",
    difficulty: "Moderate",
  },
  {
    question: "Which Islamic caliphate was founded by the Shia Muslims?",
    options: [
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Fatimid Caliphate",
      "Rashidun Caliphate",
    ],
    answer: "Fatimid Caliphate",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Muslim ruler who defeated the Sassanid Persian Empire?",
    options: [
      "Khalid ibn al-Walid",
      "Tariq ibn Ziyad",
      "Salahuddin Ayubi",
      "Muhammad al-Qasim",
    ],
    answer: "Khalid ibn al-Walid",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Islamic dynasty ruled over the Indian subcontinent from the 13th to the 16th century?",
    options: [
      "Mughal Dynasty",
      "Delhi Sultanate",
      "Bahmani Sultanate",
      "Vijayanagar Empire",
    ],
    answer: "Delhi Sultanate",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Islamic religious movement that emphasized a strict interpretation of the Quran and Sunnah?",
    options: ["Sufism", "Wahhabism", "Shi'ism", "Kharijism"],
    answer: "Wahhabism",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the concept of the 'House of Wisdom' in Baghdad?",
    options: ["Harun al-Rashid", "Al-Ma'mun", "Al-Mutawakkil", "Al-Muqtadir"],
    answer: "Harun al-Rashid",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Islamic empire that ruled over Central Asia, Afghanistan, and parts of India in the 11th and 12th centuries?",
    options: [
      "Ghaznavid Empire",
      "Seljuk Empire",
      "Timurid Empire",
      "Mughal Empire",
    ],
    answer: "Ghaznavid Empire",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Islamic architectural style is characterized by the use of domes, minarets, and intricate tilework?",
    options: ["Mughal", "Ottoman", "Moorish", "Persian"],
    answer: "Persian",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Muslim ruler who defeated the Crusaders at the Battle of Hattin in 1187?",
    options: ["Nur al-Din Zangi", "Saladin", "Baybars", "Al-Kamil"],
    answer: "Saladin",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Islamic caliphate was founded by the Turkic Seljuk dynasty in the 11th century?",
    options: [
      "Abbasid Caliphate",
      "Fatimid Caliphate",
      "Seljuk Caliphate",
      "Ayyubid Caliphate",
    ],
    answer: "Seljuk Caliphate",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Islamic religious movement that emphasized the role of reason and rationalism?",
    options: ["Mu'tazilism", "Ash'arism", "Sufism", "Kharijism"],
    answer: "Mu'tazilism",
    difficulty: "Moderate",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the concept of the 'Islamic Golden Age' in the 8th and 9th centuries?",
    options: ["Harun al-Rashid", "Al-Ma'mun", "Al-Mutawakkil", "Al-Muqtadir"],
    answer: "Al-Ma'mun",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the name of the Islamic caliphate that ruled over parts of North Africa, Sicily, and Malta in the 10th and 11th centuries?",
    options: [
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Fatimid Caliphate",
      "Almohad Caliphate",
    ],
    answer: "Fatimid Caliphate",
    difficulty: "Moderate",
  },
  {
    question:
      "During which caliphate did the Islamic empire reach its greatest territorial extent?",
    options: [
      "Rashidun Caliphate",
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Ottoman Caliphate",
    ],
    answer: "Umayyad Caliphate",
    difficulty: "Challenging",
  },
  {
    question: "Which dynasty established the city of Baghdad as its capital?",
    options: [
      "Umayyad Dynasty",
      "Abbasid Dynasty",
      "Fatimid Dynasty",
      "Seljuk Dynasty",
    ],
    answer: "Abbasid Dynasty",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the Islamic literary movement that flourished during the Abbasid Caliphate?",
    options: ["Renaissance", "Enlightenment", "Golden Age", "Reformation"],
    answer: "Golden Age",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the concept of the 'House of Knowledge' in Cairo?",
    options: [
      "Saladin",
      "Al-Hakim bi-Amr Allah",
      "Al-Aziz Uthman",
      "Al-Mustansir Billah",
    ],
    answer: "Al-Hakim bi-Amr Allah",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the Islamic religious movement that emphasized the use of reason and philosophy in interpreting the Quran?",
    options: ["Mu'tazilism", "Ash'arism", "Sufism", "Kharijism"],
    answer: "Mu'tazilism",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Islamic dynasty ruled over parts of North Africa and Spain in the 12th and 13th centuries?",
    options: [
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Fatimid Caliphate",
      "Almohad Caliphate",
    ],
    answer: "Almohad Caliphate",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the Islamic religious movement that emphasized the importance of mysticism and spiritual purification?",
    options: ["Sufism", "Wahhabism", "Shi'ism", "Kharijism"],
    answer: "Sufism",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Muslim ruler is credited with introducing the concept of the 'House of Wisdom' in Cairo?",
    options: [
      "Saladin",
      "Al-Hakim bi-Amr Allah",
      "Al-Aziz Uthman",
      "Al-Mustansir Billah",
    ],
    answer: "Al-Hakim bi-Amr Allah",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the Islamic caliphate that ruled over parts of Central Asia and Iran in the 16th and 17th centuries?",
    options: [
      "Safavid Caliphate",
      "Mughal Caliphate",
      "Ottoman Caliphate",
      "Timurid Caliphate",
    ],
    answer: "Safavid Caliphate",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the Islamic religious movement that emphasized the importance of reason and rationalism in interpreting the Quran?",
    options: ["Mu'tazilism", "Ash'arism", "Sufism", "Kharijism"],
    answer: "Mu'tazilism",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Caliph of Islam?",
    options: [
      "Umar ibn Al-Khattab",
      "Ali ibn Abi Talib",
      "Abu Bakr",
      "Uthman ibn Affan",
    ],
    answer: "Abu Bakr",
    difficulty: "Challenging",
  },
  {
    question:
      "Which battle is considered a turning point in Muslim history, marking the spread of Islam into Europe?",
    options: [
      "Battle of Karbala",
      "Battle of Tours",
      "Battle of Badr",
      "Battle of Uhud",
    ],
    answer: "Battle of Tours",
    difficulty: "Challenging",
  },
  {
    question:
      "Which empire was known for its Islamic Golden Age, fostering developments in science, culture, and the arts?",
    options: [
      "The Umayyad Caliphate",
      "The Ottoman Empire",
      "The Abbasid Caliphate",
      "The Mughal Empire",
    ],
    answer: "The Abbasid Caliphate",
    difficulty: "Challenging",
  },
  {
    question: "What was the primary language of the Islamic Golden Age?",
    options: ["Turkish", "Persian", "Arabic", "Urdu"],
    answer: "Arabic",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Muslim philosopher is known for his work 'The Incoherence of the Philosophers'?",
    options: ["Ibn Rushd", "Al-Ghazali", "Ibn Sina", "Al-Farabi"],
    answer: "Al-Ghazali",
    difficulty: "Challenging",
  },
  {
    question:
      "Which work by Ibn Khaldun is considered a founding work of historiography, sociology, and economics?",
    options: [
      "Al-Muqaddimah",
      "Kitab al-Ibar",
      "The Book of Healing",
      "The Canon of Medicine",
    ],
    answer: "Al-Muqaddimah",
    difficulty: "Challenging",
  },
  {
    question: "What was the Islamic practice of 'Ijtihad'?",
    options: [
      "Fasting during Ramadan",
      "The pilgrimage to Mecca",
      "Independent reasoning in legal or theological matters",
      "Charitable giving",
    ],
    answer: "Independent reasoning in legal or theological matters",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the name of the famous Islamic library in Cordoba during the time of the Caliphate?",
    options: [
      "The House of Wisdom",
      "The Library of Alexandria",
      "The Cordoba Library",
      "The Alhambra Library",
    ],
    answer: "The Cordoba Library",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Islamic caliphate was known for its naval dominance and control of the Mediterranean?",
    options: [
      "The Abbasid Caliphate",
      "The Fatimid Caliphate",
      "The Umayyad Caliphate",
      "The Ayyubid Dynasty",
    ],
    answer: "The Fatimid Caliphate",
    difficulty: "Challenging",
  },
  {
    question: "Who was the founder of the Fatimid Caliphate?",
    options: [
      "Ubaid Allah al-Mahdi Billah",
      "Saladin",
      "Harun al-Rashid",
      "Muhammad al-Mahdi",
    ],
    answer: "Ubaid Allah al-Mahdi Billah",
    difficulty: "Challenging",
  },
  {
    question:
      "Which Persian polymath's work on algebra was fundamental in the development of the subject?",
    options: [
      "Omar Khayyam",
      "Al-Khwarizmi",
      "Avicenna (Ibn Sina)",
      "Al-Farabi",
    ],
    answer: "Al-Khwarizmi",
    difficulty: "Challenging",
  },
  {
    question:
      "What was the outcome of the First Crusade in the context of Islamic history?",
    options: [
      "The fall of Jerusalem to the Crusaders",
      "The recapture of Jerusalem by Saladin",
      "The establishment of the Ottoman Empire",
      "The defeat of the Mongols at Ain Jalut",
    ],
    answer: "The fall of Jerusalem to the Crusaders",
    difficulty: "Challenging",
  },
  {
    question: "Which event marked the end of the Islamic Golden Age?",
    options: [
      "The fall of Baghdad to the Mongols in 1258",
      "The death of Muhammad",
      "The Crusades",
      "The division between Sunni and Shia",
    ],
    answer: "The fall of Baghdad to the Mongols in 1258",
    difficulty: "Challenging",
  },
  {
    question: "Who was the founder of the Sunni Islamic legal tradition?",
    options: ["Abu Hanifa", "Malik ibn Anas", "Al-Shafi'i", "Ahmad ibn Hanbal"],
    answer: "Abu Hanifa",
    difficulty: "Difficult",
  },
  {
    question:
      "Which Muslim scholar and philosopher is known for his work on logic and metaphysics?",
    options: ["Al-Ghazali", "Averroes", "Avicenna", "Al-Farabi"],
    answer: "Averroes",
    difficulty: "Difficult",
  },
  {
    question:
      "What was the name of the Islamic reformist movement that emerged in the 18th century in Arabia?",
    options: ["Wahhabism", "Sufism", "Mu'tazilism", "Ash'arism"],
    answer: "Wahhabism",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first female ruler in the Muslim world?",
    options: [
      "Razia Sultana",
      "Shajarat al-Durr",
      "Khayzuran",
      "Arwa al-Sulayhi",
    ],
    answer: "Razia Sultana",
    difficulty: "Difficult",
  },
  {
    question:
      "Which caliph is credited with compiling the Quran into a single book?",
    options: [
      "Umar ibn Al-Khattab",
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
      "Abu Bakr",
    ],
    answer: "Uthman ibn Affan",
    difficulty: "Difficult",
  },
  {
    question:
      "During whose reign was the Islamic Empire expanded to its largest borders?",
    options: [
      "Harun al-Rashid",
      "Umar ibn Al-Khattab",
      "Salahuddin Ayubi",
      "Suleiman the Magnificent",
    ],
    answer: "Umar ibn Al-Khattab",
    difficulty: "Difficult",
  },
  {
    question:
      "What was the significant battle where the Muslim army defeated the Mongols?",
    options: [
      "The Battle of Ain Jalut",
      "The Battle of Karbala",
      "The Battle of Qadisiyyah",
      "The Battle of Yarmouk",
    ],
    answer: "The Battle of Ain Jalut",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the famous Muslim scholar known for his work in sociology and history, author of 'The Muqaddimah'?",
    options: ["Ibn Khaldun", "Al-Ghazali", "Ibn Sina", "Ibn Rushd"],
    answer: "Ibn Khaldun",
    difficulty: "Difficult",
  },
  {
    question:
      "What was the name of the naval battle in which the Ottoman Empire attempted to invade Europe through Spain?",
    options: [
      "The Battle of Lepanto",
      "The Battle of Tours",
      "The Siege of Vienna",
      "The Battle of Gibraltar",
    ],
    answer: "The Battle of Lepanto",
    difficulty: "Difficult",
  },
  {
    question:
      "Which famous Islamic scholar was known for his extensive travels and study of the Islamic world, leaving behind a remarkable account in his 'Rihla'?",
    options: ["Ibn Battuta", "Al-Idrisi", "Al-Biruni", "Ibn al-Haytham"],
    answer: "Ibn Battuta",
    difficulty: "Difficult",
  },
  {
    question:
      "What is the name of the famous Islamic library in Baghdad that was a center of learning during the Abbasid Caliphate?",
    options: [
      "The Library of Cordoba",
      "The House of Wisdom",
      "The Alexandria Library",
      "The Nizamiyah Library",
    ],
    answer: "The House of Wisdom",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the founder of the Mamluk Sultanate that ruled Egypt and the Levant?",
    options: ["Saladin", "Baybars", "Qutuz", "Sultan Suleiman"],
    answer: "Qutuz",
    difficulty: "Difficult",
  },
  {
    question:
      "What was the name of the treaty that concluded the Crusades, allowing Muslims to retain control over Jerusalem?",
    options: [
      "Treaty of Hudaybiyyah",
      "Treaty of Jaffa",
      "Treaty of Ramla",
      "Treaty of Versailles",
    ],
    answer: "Treaty of Ramla",
    difficulty: "Difficult",
  },
  {
    question:
      "In which century did the famous Muslim scholar Ibn Khaldun write his work, the Muqaddimah, considered a foundation of modern sociology, historiography, and economics?",
    options: ["12th Century", "13th Century", "14th Century", "15th Century"],
    answer: "14th Century",
    difficulty: "Difficult",
  },
  {
    question:
      "Which Muslim figure is known for his diplomatic letters to rulers across the world, including the Byzantine Emperor and the Persian Emperor?",
    options: [
      "Khalid ibn al-Walid",
      "Umar ibn Al-Khattab",
      "Prophet Muhammad",
      "Salahuddin Ayyubi",
    ],
    answer: "Prophet Muhammad",
    difficulty: "Difficult",
  },
  {
    question:
      "What significant Islamic event took place on the 10th of Muharram, also known as Ashura?",
    options: [
      "The martyrdom of Husayn ibn Ali",
      "The Battle of Badr",
      "The revelation of the Quran began",
      "The migration to Abyssinia",
    ],
    answer: "The martyrdom of Husayn ibn Ali",
    difficulty: "Difficult",
  },
  {
    question:
      "In which city was the first Islamic coin minted during the Caliphate?",
    options: ["Damascus", "Baghdad", "Medina", "Kufa"],
    answer: "Damascus",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the Muslim explorer credited with discovering the Americas over 300 years before Christopher Columbus, according to some theories?",
    options: ["Ibn Battuta", "Zheng He", "Abu Bakr II", "Al-Idrisi"],
    answer: "Abu Bakr II",
    difficulty: "Difficult",
  },
  {
    question:
      "Which Islamic caliphate was established immediately after the death of the Prophet Muhammad?",
    options: [
      "The Umayyad Caliphate",
      "The Abbasid Caliphate",
      "The Rashidun Caliphate",
      "The Fatimid Caliphate",
    ],
    answer: "The Rashidun Caliphate",
    difficulty: "Difficult",
  },
  {
    question: "What is the Islamic concept of 'Tawhid'?",
    options: ["Charity", "Fasting", "Pilgrimage", "The oneness of God"],
    answer: "The oneness of God",
    difficulty: "Difficult",
  },
  {
    question:
      "Which Islamic caliphate is noted for its naval expeditions and establishing a foothold in Sicily?",
    options: [
      "The Umayyad Caliphate",
      "The Abbasid Caliphate",
      "The Fatimid Caliphate",
      "The Ayyubid Dynasty",
    ],
    answer: "The Ayyubid Dynasty",
    difficulty: "Difficult",
  },
  {
    question:
      "Which Muslim figure is renowned for his compilation of astronomical tables in the 'Zij'?",
    options: ["Al-Khwarizmi", "Al-Biruni", "Al-Farghani", "Ulugh Beg"],
    answer: "Ulugh Beg",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the ruler of the Christian Kingdom of Abyssinia who gave refuge to early Muslims fleeing persecution?",
    options: [
      "Negus Ashama ibn Abjar",
      "King Solomon",
      "Emperor Constantine",
      "Pharaoh of Egypt",
    ],
    answer: "Negus Ashama ibn Abjar",
    difficulty: "Difficult",
  },
];

export default islamicHistoryQuestions;
