const islamicGeographyQuestions = [
  {
    question: "Which city is considered the holiest in Islam?",
    options: [
      "Mecca",
      "Medina",
      "Jerusalem",
      "Baghdad"
    ],
    answer: "Mecca"
  },
  {
    question: "Which is the longest river in the Arab world?",
    options: [
      "Nile",
      "Euphrates",
      "Tigris",
      "Indus"
    ],
    answer: "Nile"
  },
  {
    question: "Which country has the highest Muslim population?",
    options: [
      "Saudi Arabia",
      "Indonesia",
      "Pakistan",
      "Egypt"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which sea separates the Arabian Peninsula from Iran?",
    options: [
      "Red Sea",
      "Persian Gulf",
      "Arabian Sea",
      "Mediterranean Sea"
    ],
    answer: "Persian Gulf"
  },
  {
    question: "Which city is known as the 'Pearl of the Indian Ocean'?",
    options: [
      "Dubai",
      "Muscat",
      "Jeddah",
      "Bahrain"
    ],
    answer: "Muscat"
  },
  {
    question: "Which is the largest Arab country by land area?",
    options: [
      "Saudi Arabia",
      "Algeria",
      "Sudan",
      "Libya"
    ],
    answer: "Algeria"
  },
  {
    question: "Which Muslim-majority country is located entirely within Europe?",
    options: [
      "Turkey",
      "Azerbaijan",
      "Albania",
      "Bosnia and Herzegovina"
    ],
    answer: "Albania"
  },
  {
    question: "Which country is home to the ancient city of Petra?",
    options: [
      "Jordan",
      "Syria",
      "Lebanon",
      "Palestine"
    ],
    answer: "Jordan"
  },
  {
    question: "Which is the highest mountain in the Arab world?",
    options: [
      "Mount Everest",
      "Mount Kilimanjaro",
      "Jebel Toubkal",
      "Mount Damavand"
    ],
    answer: "Jebel Toubkal"
  },
  {
    question: "Which city is known as the 'Blue City' of Morocco?",
    options: [
      "Marrakesh",
      "Casablanca",
      "Fez",
      "Chefchaouen"
    ],
    answer: "Chefchaouen"
  },
  {
    question: "Which is the largest Muslim-majority country by population?",
    options: [
      "Indonesia",
      "Pakistan",
      "Bangladesh",
      "Nigeria"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which country is home to the ancient city of Timbuktu?",
    options: [
      "Mali",
      "Niger",
      "Chad",
      "Mauritania"
    ],
    answer: "Mali"
  },
  {
    question: "Which is the largest Muslim-majority country in Europe?",
    options: [
      "Turkey",
      "Russia",
      "France",
      "Germany"
    ],
    answer: "Turkey"
  },
  {
    question: "Which city is known as the 'City of a Thousand Minarets'?",
    options: [
      "Cairo",
      "Istanbul",
      "Baghdad",
      "Isfahan"
    ],
    answer: "Cairo"
  },
  {
    question: "Which country is home to the ancient city of Samarkand?",
    options: [
      "Uzbekistan",
      "Tajikistan",
      "Kazakhstan",
      "Kyrgyzstan"
    ],
    answer: "Uzbekistan"
  },
  {
    question: "Which is the largest Muslim-majority country in South America?",
    options: [
      "Brazil",
      "Guyana",
      "Suriname",
      "French Guiana"
    ],
    answer: "Suriname"
  },
  {
    question: "Which city is known as the 'City of Minarets'?",
    options: [
      "Marrakesh",
      "Fez",
      "Algiers",
      "Tunis"
    ],
    answer: "Fez"
  },
  {
    question: "Which country is home to the ancient city of Palmyra?",
    options: [
      "Syria",
      "Iraq",
      "Iran",
      "Turkey"
    ],
    answer: "Syria"
  },
  {
    question: "Which is the largest Muslim-majority country in Southeast Asia?",
    options: [
      "Indonesia",
      "Malaysia",
      "Brunei",
      "Singapore"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which city is known as the 'Pearl of the Desert'?",
    options: [
      "Dubai",
      "Riyadh",
      "Doha",
      "Abu Dhabi"
    ],
    answer: "Dubai"
  },
  {
    question: "Which city is known as the 'City of Islam'?",
    options: [
      "Mecca",
      "Medina",
      "Baghdad",
      "Damascus"
    ],
    answer: "Medina"
  },
  {
    question: "Which is the largest Muslim-majority country in Central Asia?",
    options: [
      "Kazakhstan",
      "Uzbekistan",
      "Turkmenistan",
      "Kyrgyzstan"
    ],
    answer: "Kazakhstan"
  },
  {
    question: "Which country is home to the ancient city of Bam?",
    options: [
      "Iran",
      "Iraq",
      "Afghanistan",
      "Pakistan"
    ],
    answer: "Iran"
  },
  {
    question: "Which sea separates the Iberian Peninsula from North Africa?",
    options: [
      "Mediterranean Sea",
      "Red Sea",
      "Strait of Gibraltar",
      "Gulf of Aden"
    ],
    answer: "Strait of Gibraltar"
  },
  {
    question: "Which city is known as the 'Islamic Capital of Europe'?",
    options: [
      "Berlin",
      "Paris",
      "London",
      "Sarajevo"
    ],
    answer: "Sarajevo"
  },
  {
    question: "Which country is home to the ancient city of Khiva?",
    options: [
      "Uzbekistan",
      "Turkmenistan",
      "Tajikistan",
      "Kazakhstan"
    ],
    answer: "Uzbekistan"
  },
  {
    question: "Which is the largest Muslim-majority country in Africa?",
    options: [
      "Nigeria",
      "Egypt",
      "Algeria",
      "Sudan"
    ],
    answer: "Nigeria"
  },
  {
    question: "Which city is known as the 'Gate to Makkah'?",
    options: [
      "Jeddah",
      "Medina",
      "Riyadh",
      "Mecca"
    ],
    answer: "Jeddah"
  },
  {
    question: "Which country is home to the ancient city of Mohenjo-daro?",
    options: [
      "Pakistan",
      "India",
      "Bangladesh",
      "Afghanistan"
    ],
    answer: "Pakistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Caucasus region?",
    options: [
      "Azerbaijan",
      "Armenia",
      "Georgia",
      "Chechnya"
    ],
    answer: "Azerbaijan"
  },
  {
    question: "Which city is known as the 'Jewel of the Nile'?",
    options: [
      "Luxor",
      "Aswan",
      "Cairo",
      "Alexandria"
    ],
    answer: "Luxor"
  },
  {
    question: "Which country is home to the ancient city of Sana'a?",
    options: [
      "Yemen",
      "Oman",
      "Saudi Arabia",
      "United Arab Emirates"
    ],
    answer: "Yemen"
  },
  {
    question: "Which is the largest Muslim-majority country in Southeast Europe?",
    options: [
      "Albania",
      "Bosnia and Herzegovina",
      "Kosovo",
      "North Macedonia"
    ],
    answer: "Bosnia and Herzegovina"
  },
  {
    question: "Which city is known as the 'Islamic Capital of Russia'?",
    options: [
      "Moscow",
      "St. Petersburg",
      "Kazan",
      "Yekaterinburg"
    ],
    answer: "Kazan"
  },
  {
    question: "Which country is home to the ancient city of Merv?",
    options: [
      "Turkmenistan",
      "Uzbekistan",
      "Tajikistan",
      "Kazakhstan"
    ],
    answer: "Turkmenistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Balkans?",
    options: [
      "Albania",
      "Bosnia and Herzegovina",
      "Kosovo",
      "North Macedonia"
    ],
    answer: "Albania"
  },
  {
    question: "Which city is known as the 'Gate to the Desert'?",
    options: [
      "Marrakesh",
      "Fez",
      "Casablanca",
      "Ouarzazate"
    ],
    answer: "Ouarzazate"
  },
  {
    question: "Which country is home to the ancient city of Bukhara?",
    options: [
      "Uzbekistan",
      "Turkmenistan",
      "Tajikistan",
      "Kazakhstan"
    ],
    answer: "Uzbekistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Caribbean?",
    options: [
      "Trinidad and Tobago",
      "Guyana",
      "Suriname",
      "Jamaica"
    ],
    answer: "Trinidad and Tobago"
  },
  {
    question: "Which city is known as the 'Bride of the Desert'?",
    options: [
      "Marrakesh",
      "Fez",
      "Algiers",
      "Tozeur"
    ],
    answer: "Tozeur"
  },
  {
    question: "Which country is home to the ancient city of Leptis Magna?",
    options: [
      "Libya",
      "Tunisia",
      "Algeria",
      "Morocco"
    ],
    answer: "Libya"
  },
  {
    question: "Which is the largest Muslim-majority country in the Americas?",
    options: [
      "United States",
      "Canada",
      "Mexico",
      "Brazil"
    ],
    answer: "United States"
  },
  {
    question: "Which city is known as the 'Istanbul of the East'?",
    options: [
      "Kuala Lumpur",
      "Jakarta",
      "Bangkok",
      "Singapore"
    ],
    answer: "Kuala Lumpur"
  },
  {
    question: "Which country is home to the ancient city of Carthage?",
    options: [
      "Tunisia",
      "Libya",
      "Algeria",
      "Morocco"
    ],
    answer: "Tunisia"
  },
  {
    question: "Which is the largest Muslim-majority country in the Pacific?",
    options: [
      "Indonesia",
      "Malaysia",
      "Papua New Guinea",
      "Fiji"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which city is known as the 'City of Mausoleums'?",
    options: [
      "Samarkand",
      "Bukhara",
      "Khiva",
      "Merv"
    ],
    answer: "Samarkand"
  },
  {
    question: "Which country is home to the ancient city of Persepolis?",
    options: [
      "Iran",
      "Iraq",
      "Syria",
      "Turkey"
    ],
    answer: "Iran"
  },
  {
    question: "Which is the largest Muslim-majority country in the Horn of Africa?",
    options: [
      "Somalia",
      "Eritrea",
      "Djibouti",
      "Ethiopia"
    ],
    answer: "Somalia"
  },
  {
    question: "Which city is known as the 'City of Mosques'?",
    options: [
      "Lahore",
      "Karachi",
      "Islamabad",
      "Peshawar"
    ],
    answer: "Lahore"
  },
  {
    question: "Which country is home to the ancient city of Volubilis?",
    options: [
      "Morocco",
      "Algeria",
      "Tunisia",
      "Libya"
    ],
    answer: "Morocco"
  },
  {
    question: "Which is the largest Muslim-majority country in the Levant region?",
    options: [
      "Syria",
      "Lebanon",
      "Jordan",
      "Palestine"
    ],
    answer: "Syria"
  },
  {
    question: "Which city is known as the 'Islamic Capital of India'?",
    options: [
      "Delhi",
      "Mumbai",
      "Lucknow",
      "Hyderabad"
    ],
    answer: "Lucknow"
  },
  {
    question: "Which country is home to the ancient city of Al-Qadisiyyah?",
    options: [
      "Iraq",
      "Iran",
      "Syria",
      "Saudi Arabia"
    ],
    answer: "Iraq"
  },
  {
    question: "Which is the largest Muslim-majority country in Europe by land area?",
    options: [
      "Russia",
      "Kazakhstan",
      "France",
      "Germany"
    ],
    answer: "Kazakhstan"
  },
  {
    question: "Which city is known as the 'Mother of Cities'?",
    options: [
      "Damascus",
      "Aleppo",
      "Beirut",
      "Amman"
    ],
    answer: "Damascus"
  },
  {
    question: "Which country is home to the ancient city of Fatehpur Sikri?",
    options: [
      "India",
      "Pakistan",
      "Bangladesh",
      "Afghanistan"
    ],
    answer: "India"
  },
  {
    question: "Which is the largest Muslim-majority country in the Maghreb region?",
    options: [
      "Algeria",
      "Morocco",
      "Tunisia",
      "Libya"
    ],
    answer: "Algeria"
  },
  {
    question: "Which city is known as the 'City of Saints'?",
    options: [
      "Multan",
      "Peshawar",
      "Quetta",
      "Faisalabad"
    ],
    answer: "Multan"
  },
  {
    question: "Which country is home to the ancient city of Axum?",
    options: [
      "Ethiopia",
      "Eritrea",
      "Djibouti",
      "Somalia"
    ],
    answer: "Ethiopia"
  },
  {
    question: "Which is the largest Muslim-majority country in Central Africa?",
    options: [
      "Chad",
      "Sudan",
      "Niger",
      "Central African Republic"
    ],
    answer: "Chad"
  },
  {
    question: "Which city is considered the spiritual center of Islam?",
    options: [
      "Cairo",
      "Mecca",
      "Istanbul",
      "Jerusalem"
    ],
    answer: "Mecca"
  },
  {
    question: "What is the capital city of the Islamic Caliphate during the Umayyad period?",
    options: [
      "Baghdad",
      "Damascus",
      "Cordoba",
      "Medina"
    ],
    answer: "Damascus"
  },
  {
    question: "In which modern-day country is the ancient Islamic city of Samarra located?",
    options: [
      "Iran",
      "Iraq",
      "Syria",
      "Jordan"
    ],
    answer: "Iraq"
  },
  {
    question: "Which river is Baghdad, the capital of the Abbasid Caliphate, located on?",
    options: [
      "Nile",
      "Euphrates",
      "Tigris",
      "Jordan"
    ],
    answer: "Tigris"
  },
  {
    question: "What is the most populous Muslim-majority country in the world?",
    options: [
      "Saudi Arabia",
      "Egypt",
      "Indonesia",
      "Pakistan"
    ],
    answer: "Indonesia"
  },
  {
    question: "Al-Azhar University, one of the oldest universities in the world, is located in which city?",
    options: [
      "Mecca",
      "Medina",
      "Cairo",
      "Istanbul"
    ],
    answer: "Cairo"
  },
  {
    question: "The Great Mosque of Cordoba is located in which country?",
    options: [
      "Morocco",
      "Spain",
      "Turkey",
      "Iran"
    ],
    answer: "Spain"
  },
  {
    question: "Which Islamic empire was centered in modern-day Turkey?",
    options: [
      "Umayyad Caliphate",
      "Abbasid Caliphate",
      "Ottoman Empire",
      "Fatimid Caliphate"
    ],
    answer: "Ottoman Empire"
  },
  {
    question: "The city of Medina is significant in Islam because it is where:",
    options: [
      "Islam was founded",
      "Muhammad was born",
      "Muhammad was buried",
      "The Quran was written"
    ],
    answer: "Muhammad was buried"
  },
  {
    question: "The Islamic holy month of Ramadan begins with the sighting of the new moon in which city?",
    options: [
      "Cairo",
      "Mecca",
      "Istanbul",
      "Riyadh"
    ],
    answer: "Mecca"
  },
  {
    question: "Which city was the first capital of the Islamic Caliphate?",
    options: [
      "Medina",
      "Mecca",
      "Damascus",
      "Baghdad"
    ],
    answer: "Medina"
  },
  {
    question: "The Dome of the Rock, an Islamic shrine, is located in which city?",
    options: [
      "Medina",
      "Jerusalem",
      "Damascus",
      "Cairo"
    ],
    answer: "Jerusalem"
  },
  {
    question: "In which country is the city of Qom, a major center for Shia scholarship, located?",
    options: [
      "Saudi Arabia",
      "Iran",
      "Iraq",
      "Syria"
    ],
    answer: "Iran"
  },
  {
    question: "Which sea does the Suez Canal connect to the Red Sea?",
    options: [
      "Mediterranean Sea",
      "Black Sea",
      "Arabian Sea",
      "Caspian Sea"
    ],
    answer: "Mediterranean Sea"
  },
  {
    question: "The city of Timbuktu, known for its historic Islamic universities and manuscripts, is in which country?",
    options: [
      "Mali",
      "Morocco",
      "Egypt",
      "Saudi Arabia"
    ],
    answer: "Mali"
  },
  {
    question: "The historical region of Andalusia, known for its Islamic architecture and heritage, is in which country?",
    options: [
      "Morocco",
      "Spain",
      "Turkey",
      "Iran"
    ],
    answer: "Spain"
  },
  {
    question: "What is the primary language of instruction in traditional Islamic madrasas?",
    options: [
      "Persian",
      "Turkish",
      "Arabic",
      "Urdu"
    ],
    answer: "Arabic"
  },
  {
    question: "Which of the following countries has a coastline on both the Mediterranean Sea and the Red Sea?",
    options: [
      "Egypt",
      "Jordan",
      "Saudi Arabia",
      "Syria"
    ],
    answer: "Egypt"
  },
  {
    question: "The historical Islamic city of Granada is famous for which palace?",
    options: [
      "Topkapi Palace",
      "Alhambra",
      "Potala Palace",
      "Versailles"
    ],
    answer: "Alhambra"
  },
  {
    question: "Which modern country contains the ancient city of Merv, an important Islamic cultural center?",
    options: [
      "Turkmenistan",
      "Iran",
      "Saudi Arabia",
      "Uzbekistan"
    ],
    answer: "Turkmenistan"
  },
  {
    question: "The Umayyad Mosque, one of the oldest mosques in the world, is located in which city?",
    options: [
      "Cairo",
      "Baghdad",
      "Damascus",
      "Jerusalem"
    ],
    answer: "Damascus"
  },
  {
    question: "The Kaaba is located within which mosque?",
    options: [
      "Al-Masjid an-Nabawi",
      "Masjid al-Haram",
      "Al-Aqsa Mosque",
      "Great Mosque of Mecca"
    ],
    answer: "Masjid al-Haram"
  },
  {
    question: "The Islamic Golden Age was a period of cultural, economic, and scientific flourishing in the history of Islam traditionally dated from the 8th century to the 14th century. Which city was a major center of this Golden Age?",
    options: [
      "Cairo",
      "Baghdad",
      "Cordoba",
      "Istanbul"
    ],
    answer: "Baghdad"
  },
  {
    question: "In which country is the ancient Islamic city of Bukhara located?",
    options: [
      "Kazakhstan",
      "Tajikistan",
      "Uzbekistan",
      "Kyrgyzstan"
    ],
    answer: "Uzbekistan"
  },
  {
    question: "What is the name of the strait that separates the Asian and European parts of Turkey?",
    options: [
      "Strait of Gibraltar",
      "Bosporus Strait",
      "Dardanelles Strait",
      "Suez Strait"
    ],
    answer: "Bosporus Strait"
  },
  {
    question: "Which island nation is the largest entirely Muslim country by population?",
    options: [
      "Indonesia",
      "Maldives",
      "Bahrain",
      "Comoros"
    ],
    answer: "Indonesia"
  },
  {
    question: "The historic city of Harar, known for its ancient Islamic architecture and heritage, is located in which country?",
    options: [
      "Ethiopia",
      "Yemen",
      "Oman",
      "Qatar"
    ],
    answer: "Ethiopia"
  },
  {
    question: "Which city is known as the City of a Thousand Minarets?",
    options: [
      "Marrakech",
      "Cairo",
      "Istanbul",
      "Dubai"
    ],
    answer: "Cairo"
  },
  {
    question: "Jannatul Mualla, the famous Islamic cemetery, is located in which city?",
    options: [
      "Medina",
      "Mecca",
      "Jerusalem",
      "Riyadh"
    ],
    answer: "Mecca"
  },
  {
    question: "What is the second holiest city in Islam?",
    options: [
      "Mecca",
      "Medina",
      "Jerusalem",
      "Istanbul"
    ],
    answer: "Medina"
  },
  {
    question: "Fes, known for its walled medina and ancient Islamic architecture, is in which country?",
    options: [
      "Morocco",
      "Algeria",
      "Tunisia",
      "Egypt"
    ],
    answer: "Morocco"
  },
  {
    question: "The Nabawi Mosque, which contains Muhammad's tomb, is located in which city?",
    options: [
      "Mecca",
      "Medina",
      "Jerusalem",
      "Damascus"
    ],
    answer: "Medina"
  },
  {
    question: "The oldest surviving Islamic astronomical observatory, the Maragha Observatory, is in which country?",
    options: [
      "Iran",
      "Iraq",
      "Turkey",
      "Syria"
    ],
    answer: "Iran"
  },
  {
    question: "Which city was the capital of the Ottoman Empire after the conquest of Constantinople?",
    options: [
      "Bursa",
      "Edirne",
      "Istanbul",
      "Ankara"
    ],
    answer: "Istanbul"
  },
  {
    question: "What body of water does the Hejaz region of Saudi Arabia border?",
    options: [
      "Persian Gulf",
      "Red Sea",
      "Arabian Sea",
      "Mediterranean Sea"
    ],
    answer: "Red Sea"
  },
  {
    question: "The Alhambra, a famous example of Muslim art and architecture, is located in which Spanish city?",
    options: [
      "Seville",
      "Granada",
      "Cordoba",
      "Madrid"
    ],
    answer: "Granada"
  },
  {
    question: "In which modern country was the Islamic scholar and poet Rumi born?",
    options: [
      "Iran",
      "Turkey",
      "Afghanistan",
      "Uzbekistan"
    ],
    answer: "Afghanistan"
  },
  {
    question: "Which city is known as the 'City of Knowledge'?",
    options: [
      "Baghdad",
      "Damascus",
      "Cairo",
      "Cordoba"
    ],
    answer: "Baghdad"
  },
  {
    question: "Which is the largest Muslim-majority country in the Caspian Sea region?",
    options: [
      "Iran",
      "Azerbaijan",
      "Kazakhstan",
      "Turkmenistan"
    ],
    answer: "Iran"
  },
  {
    question: "Which country is home to the ancient city of Timgad?",
    options: [
      "Algeria",
      "Tunisia",
      "Morocco",
      "Libya"
    ],
    answer: "Algeria"
  },
  {
    question: "Which sea separates the Arabian Peninsula from the Horn of Africa?",
    options: [
      "Red Sea",
      "Persian Gulf",
      "Arabian Sea",
      "Gulf of Aden"
    ],
    answer: "Gulf of Aden"
  },
  {
    question: "Which city is known as the 'Islamic Capital of China'?",
    options: [
      "Beijing",
      "Shanghai",
      "Kashgar",
      "Urumqi"
    ],
    answer: "Kashgar"
  },
  {
    question: "Which country is home to the ancient city of Balkh?",
    options: [
      "Afghanistan",
      "Pakistan",
      "Uzbekistan",
      "Tajikistan"
    ],
    answer: "Afghanistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Sahel region?",
    options: [
      "Niger",
      "Mali",
      "Burkina Faso",
      "Mauritania"
    ],
    answer: "Niger"
  },
  {
    question: "Which city is known as the 'Islamic Capital of Spain'?",
    options: [
      "Madrid",
      "Barcelona",
      "Cordoba",
      "Seville"
    ],
    answer: "Cordoba"
  },
  {
    question: "Which country is home to the ancient city of Marib?",
    options: [
      "Yemen",
      "Oman",
      "Saudi Arabia",
      "United Arab Emirates"
    ],
    answer: "Yemen"
  },
  {
    question: "Which is the largest Muslim-majority country in the Caucasus Mountains region?",
    options: [
      "Azerbaijan",
      "Armenia",
      "Georgia",
      "Russia"
    ],
    answer: "Azerbaijan"
  },
  {
    question: "Which city is known as the 'City of Caliphs'?",
    options: [
      "Baghdad",
      "Cairo",
      "Istanbul",
      "Damascus"
    ],
    answer: "Baghdad"
  },
  {
    question: "Which country is home to the ancient city of Anjar?",
    options: [
      "Lebanon",
      "Syria",
      "Jordan",
      "Palestine"
    ],
    answer: "Lebanon"
  },
  {
    question: "Which is the largest Muslim-majority country in the Indochina region?",
    options: [
      "Indonesia",
      "Malaysia",
      "Thailand",
      "Myanmar"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which city is known as the 'Islamic Capital of Britain'?",
    options: [
      "London",
      "Birmingham",
      "Manchester",
      "Glasgow"
    ],
    answer: "Birmingham"
  },
  {
    question: "Which country is home to the ancient city of Moenjodaro?",
    options: [
      "Pakistan",
      "India",
      "Bangladesh",
      "Afghanistan"
    ],
    answer: "Pakistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Southern Cone region?",
    options: [
      "Argentina",
      "Chile",
      "Uruguay",
      "Paraguay"
    ],
    answer: "Argentina"
  },
  {
    question: "Which city is known as the 'City of Imams'?",
    options: [
      "Najaf",
      "Karbala",
      "Samarra",
      "Kufa"
    ],
    answer: "Najaf"
  },
  {
    question: "Which country is home to the ancient city of Harappa?",
    options: [
      "Pakistan",
      "India",
      "Bangladesh",
      "Afghanistan"
    ],
    answer: "Pakistan"
  },
  {
    question: "Which is the largest Muslim-majority country in the Andaman Sea region?",
    options: [
      "Indonesia",
      "Malaysia",
      "Thailand",
      "Myanmar"
    ],
    answer: "Indonesia"
  },
  {
    question: "Which city is known as the 'City of Mosques and Palaces'?",
    options: [
      "Istanbul",
      "Fez",
      "Cordoba",
      "Isfahan"
    ],
    answer: "Isfahan"
  }
];

export default islamicGeographyQuestions;