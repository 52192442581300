import React from "react";
import { Link } from "react-router-dom";
import ContentLayout from "../ContentLayout/ContentLayout";
import { MdOutlineQuiz } from "react-icons/md";
import { LiaQuranSolid } from "react-icons/lia";
import { FaPrayingHands } from "react-icons/fa";
import { BsChatLeftQuoteFill } from "react-icons/bs";
import styles from "./MyComponent.module.css";

import {
  FaBookQuran,
  FaXTwitter,
  FaInstagram,
  FaSquareFacebook,
} from "react-icons/fa6";

// Import other icons similarly

// Badge component
const Badge = ({ text, type }) => {
  // Define the base classes for the badge
  const baseClasses =
    "text-xs font-normal px-2.5 py-0.5 rounded-full self-center";
  // Define classes for each badge type
  const typeClasses = {
    hot: "bg-red-500 text-white",
    new: "bg-green-500 text-white",
  };
  // Combine base classes with type-specific classes
  const badgeClasses = `${baseClasses} ${typeClasses[type] || ""}`;

  return <span className={badgeClasses}>{text}</span>;
};

const MainContent = () => {
  return (
    <ContentLayout>
      {/* <h1 className="text-deeperPurple font-bold text-xl text-center uppercase">
        Muslim Nuggets
      </h1> */}
      <div className={styles["gradient-text"]}>MUSLIM NUGGETS</div>
      <p className="text-deeperPurple text-base text-center my-2 px-8 pb-4">
        Boost your Islamic knowledge on-the-go! <br />
        May Peace & Blessings of God be upon you.
      </p>
      {/* Link list for features */}
      <div className="space-y-4">
        <Link
          to="/trivia"
          className="bg-white hover:bg-deeperPurple hover:text-white rounded-lg shadow-md block text-base transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102"
        >
          <div className="flex justify-between items-center p-4">
            <div className="flex items-center space-x-2">
              <MdOutlineQuiz size="32px" />
              <span>Trivia: Test your knowledge of Islam.</span>
            </div>
            <Badge text="HOT" type="hot" />
          </div>
        </Link>
        <Link
          to="/quraninsights"
          className="bg-white hover:bg-deeperPurple hover:text-white rounded-lg shadow-md block text-base transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102"
        >
          <div className="flex justify-between items-center p-4">
            <div className="flex items-center space-x-2">
              <FaBookQuran size="32px" />
              <span>Quran Chapters Insights.</span>
            </div>
            <Badge text="NEW" type="new" />
          </div>
        </Link>

        <div className="bg-white hover:bg-deeperPurple hover:text-white rounded-lg shadow-md">
          <Link
            to="/hadiths"
            className="flex items-center space-x-2 block p-4 rounded-lg bg-white shadow-md hover:bg-deeperPurple hover:text-white text-base transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102"
          >
            <LiaQuranSolid size="32px" />
            <span>Shareable Hadiths: Generate & Share.</span>
          </Link>
        </div>
        <div className="bg-white hover:bg-deeperPurple hover:text-white rounded-lg shadow-md">
          <Link
            to="/rabanna"
            className="flex items-center space-x-2 block p-4 rounded-lg bg-white shadow-md hover:bg-deeperPurple hover:text-white text-base transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102"
          >
            <FaPrayingHands size="32px" />
            <span>40 Rabanna: Heartfelt Quranic prayers.</span>
          </Link>
        </div>
        <div className="bg-white hover:bg-deeperPurple hover:text-white rounded-lg shadow-md">
          <Link
            to="/nuggets"
            className="flex items-center space-x-2 block p-4 rounded-lg bg-white shadow-md hover:bg-deeperPurple hover:text-white text-base transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-102"
          >
            <BsChatLeftQuoteFill size="32px" />
            <span>Nuggets from Muslim Legends.</span>
          </Link>
        </div>
        <div className="pt-10 font-semibold">
          Please, follow us. Peace be upon you.
        </div>
        <div className="-m-3">
          <div className="flex place-content-start p-2 rounded-lg shadow-sm mb-10">
            <div className="p-1 rounded hover:bg-deeperPurple hover:text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <a
                href="https://twitter.com/muslimnuggets"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter size="30px" />
              </a>
            </div>
            <div className="p-1 rounded hover:bg-deeperPurple hover:text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <a
                href="https://www.instagram.com/muslimnuggets/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size="30px" />
              </a>
            </div>
            <div className="p-1 rounded hover:bg-deeperPurple hover:text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <a
                href="https://www.facebook.com/muslimnuggets"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareFacebook size="30px" />
              </a>
            </div>
          </div>
        </div>

        {/* Repeat for each feature */}
      </div>
    </ContentLayout>
  );
};

export default MainContent;
