const islamicRamadanQuestions = [
  {
    question: "What does Ramadan commemorate in Islamic belief?",
    options: [
      "The birth of Muhammad",
      "The night of Isra and Mi'raj",
      "The revelation of the Quran to Muhammad",
      "The Battle of Badr",
    ],
    answer: "The revelation of the Quran to Muhammad",
  },
  {
    question: "Which pillar of Islam is Ramadan associated with?",
    options: [
      "Shahada (Faith)",
      "Salat (Prayer)",
      "Zakat (Charity)",
      "Sawm (Fasting)",
    ],
    answer: "Sawm (Fasting)",
  },
  {
    question:
      "What is the significant night towards the end of Ramadan called?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Baraat",
      "Laylat al-Miraj",
      "Laylat al-Jumu'ah",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question:
      "What is the pre-dawn meal consumed by Muslims during Ramadan called?",
    options: ["Iftar", "Suhur", "Tahajjud", "Taraweeh"],
    answer: "Suhur",
  },
  {
    question:
      "What is the meal called that breaks the fast after sunset during Ramadan?",
    options: ["Suhur", "Iftar", "Nafil", "Maghrib"],
    answer: "Iftar",
  },
  {
    question:
      "Which of the following acts is encouraged and rewarded more during Ramadan?",
    options: [
      "Giving charity",
      "Recitation of the Quran",
      "Performing Umrah",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question: "What do Muslims seek during the last ten nights of Ramadan?",
    options: [
      "Laylat al-Qadr",
      "To complete reading the Quran",
      "To make Itikaf",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "Which battle took place in Ramadan, marking a significant event in early Islamic history?",
    options: [
      "Battle of Badr",
      "Battle of Uhud",
      "Battle of the Trench",
      "Battle of Khaybar",
    ],
    answer: "Battle of Badr",
  },
  {
    question: "What is Zakat al-Fitr?",
    options: [
      "A fixed percentage of income given to the poor",
      "A specific amount of food given to the poor at the end of Ramadan",
      "Voluntary charity given anytime during the year",
      "Money paid for fasting missed intentionally",
    ],
    answer: "A specific amount of food given to the poor at the end of Ramadan",
  },
  {
    question: "What is the purpose of Taraweeh prayers during Ramadan?",
    options: [
      "To complete the recitation of the Quran",
      "To seek forgiveness for past sins",
      "To prepare for Eid-ul-Fitr",
      "All of the above",
    ],
    answer: "To complete the recitation of the Quran",
  },
  {
    question:
      "In which month of the Islamic lunar calendar does Ramadan occur?",
    options: ["Rajab", "Shawwal", "Ramadan", "Dhu al-Hijjah"],
    answer: "Ramadan",
  },
  {
    question:
      "What action is specifically forbidden during the daylight hours of Ramadan?",
    options: ["Sleeping", "Eating and drinking", "Speaking", "Walking"],
    answer: "Eating and drinking",
  },
  {
    question:
      "Which prayer is uniquely performed during the nights of Ramadan?",
    options: ["Isha", "Taraweeh", "Fajr", "Dhuhr"],
    answer: "Taraweeh",
  },
  {
    question: "What event does Eid ul-Fitr celebrate?",
    options: [
      "The start of Ramadan",
      "The end of Ramadan",
      "The Battle of Badr",
      "The migration to Medina",
    ],
    answer: "The end of Ramadan",
  },
  {
    question: "How is the start of Ramadan determined in the Islamic world?",
    options: [
      "Fixed date in the Gregorian calendar",
      "Sighting of the moon",
      "Islamic leader’s declaration",
      "Fixed date in the Islamic calendar",
    ],
    answer: "Sighting of the moon",
  },
  {
    question: "What is Itikaf?",
    options: [
      "A special prayer",
      "Charity given during Ramadan",
      "A period of retreat in the mosque during the last ten days of Ramadan",
      "Breaking the fast with water",
    ],
    answer:
      "A period of retreat in the mosque during the last ten days of Ramadan",
  },
  {
    question: "Who are exempt from fasting during Ramadan?",
    options: [
      "Elderly",
      "Pregnant women",
      "Children before puberty",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question: "What is the spiritual significance of Laylat al-Qadr?",
    options: [
      "The night Muhammad was born",
      "The night the Quran was revealed to Muhammad",
      "The night Muhammad migrated to Medina",
      "The night of the Battle of Badr",
    ],
    answer: "The night the Quran was revealed to Muhammad",
  },
  {
    question: "Which of the following deeds is NOT associated with Ramadan?",
    options: [
      "Fasting from dawn till sunset",
      "Performing Hajj",
      "Reciting the Quran",
      "Giving Zakat",
    ],
    answer: "Performing Hajj",
  },
  {
    question: "What does the term 'Sawm' literally mean?",
    options: ["Prayer", "Charity", "Fasting", "Pilgrimage"],
    answer: "Fasting",
  },
  {
    question: "What is the main purpose of fasting during Ramadan?",
    options: [
      "To lose weight",
      "To detoxify the body",
      "To develop compassion and self-discipline",
      "To prepare for athletic events",
    ],
    answer: "To develop compassion and self-discipline",
  },
  {
    question:
      "Which food is traditionally eaten to break the fast in many Muslim countries?",
    options: ["Bread", "Dates", "Rice", "Soup"],
    answer: "Dates",
  },
  {
    question: "What act is highly recommended during Ramadan besides fasting?",
    options: [
      "Writing a diary",
      "Reading fiction books",
      "Engaging in charitable deeds",
      "Starting a new hobby",
    ],
    answer: "Engaging in charitable deeds",
  },
  {
    question: "What happens to the daily prayers during Ramadan?",
    options: [
      "They are doubled",
      "They are shortened",
      "They remain the same",
      "Only Taraweeh is prayed",
    ],
    answer: "They remain the same",
  },
  {
    question: "What is NOT a common practice during Ramadan?",
    options: [
      "Fasting from dawn until dusk",
      "Abstaining from sinful behavior",
      "Excessive eating during the night",
      "Reading the Quran",
    ],
    answer: "Excessive eating during the night",
  },
  {
    question: "Who first established the practice of fasting during Ramadan?",
    options: [
      "Prophet Muhammad",
      "Prophet Ibrahim",
      "Prophet Musa",
      "Prophet Isa",
    ],
    answer: "Prophet Muhammad",
  },
  {
    question: "In addition to fasting, what is avoided during Ramadan?",
    options: ["Sleeping", "Arguing and fighting", "Working", "Exercise"],
    answer: "Arguing and fighting",
  },
  {
    question:
      "What is the reward for hosting and feeding a fasting person to break their fast?",
    options: [
      "Financial gain",
      "Public recognition",
      "The same reward as the fasting person without diminishing either reward",
      "A place in heaven",
    ],
    answer:
      "The same reward as the fasting person without diminishing either reward",
  },
  {
    question: "How is the end of Ramadan officially declared?",
    options: [
      "After 30 days of fasting",
      "Sighting of the new moon",
      "Government declaration",
      "Completion of the Quran recitation",
    ],
    answer: "Sighting of the new moon",
  },
  {
    question:
      "What practice is recommended to seek during the last ten nights of Ramadan, in hope of catching Laylat al-Qadr?",
    options: [
      "Itikaf (seclusion in a mosque for worship)",
      "Zakat (giving of alms)",
      "Hajj (pilgrimage to Mecca)",
      "Tawaf (circling the Kaaba)",
    ],
    answer: "Itikaf (seclusion in a mosque for worship)",
  },
  {
    question: "What is the ninth month of the Islamic calendar?",
    options: ["Ramadan", "Shawwal", "Dhul-Hijjah", "Muharram"],
    answer: "Ramadan",
  },
  {
    question:
      "During Ramadan, Muslims are required to do what from dawn to dusk?",
    options: ["Pray", "Fast", "Give charity", "Recite the Quran"],
    answer: "Fast",
  },
  {
    question: "When does Ramadan begin each year?",
    options: [
      "On a fixed date",
      "Based on the lunar calendar",
      "Based on the solar calendar",
      "On the first day of the year",
    ],
    answer: "Based on the lunar calendar",
  },
  {
    question: "What is the primary purpose of fasting during Ramadan?",
    options: [
      "To lose weight",
      "To build self-discipline",
      "To commemorate a historical event",
      "All of the above",
    ],
    answer: "To build self-discipline",
  },
  {
    question: "Which meal is eaten before dawn during Ramadan?",
    options: ["Iftar", "Suhoor", "Dinner", "Lunch"],
    answer: "Suhoor",
  },
  {
    question:
      "What is the name of the night when the Quran was first revealed to Prophet Muhammad?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Miraj",
      "Laylat al-Isra",
      "Laylat al-Bara'ah",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to do what in addition to fasting?",
    options: [
      "Give charity",
      "Read the Quran",
      "Perform extra prayers",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question: "What is the celebration called that marks the end of Ramadan?",
    options: ["Eid al-Fitr", "Eid al-Adha", "Mawlid al-Nabi", "Ashura"],
    answer: "Eid al-Fitr",
  },
  {
    question:
      "What is the primary reason for breaking the fast during Ramadan?",
    options: ["Illness", "Travel", "Menstruation", "All of the above"],
    answer: "All of the above",
  },
  {
    question:
      "During Ramadan, Muslims are expected to abstain from what in addition to food and drink?",
    options: ["Smoking", "Gossip", "Anger", "All of the above"],
    answer: "All of the above",
  },
  {
    question:
      "What is the special night prayer performed during Ramadan called?",
    options: ["Taraweeh", "Tahajjud", "Qiyam al-Layl", "Fajr"],
    answer: "Taraweeh",
  },
  {
    question:
      "What is the reward for completing the fast and other obligations during Ramadan?",
    options: [
      "Forgiveness of sins",
      "Entry into Paradise",
      "Increased spiritual connection",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the name of the final night of Ramadan, when many Muslims stay up to worship?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Bara'ah",
      "Laylat al-Raghaib",
      "Laylat al-Isra",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to avoid what in addition to eating and drinking?",
    options: [
      "Listening to music",
      "Watching movies",
      "Arguing",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question: "What is the special charity given during Ramadan called?",
    options: ["Zakat", "Sadaqah", "Khums", "Fitrah"],
    answer: "Fitrah",
  },
  {
    question:
      "What is the purpose of the Eid al-Fitr celebration at the end of Ramadan?",
    options: [
      "To celebrate the end of fasting",
      "To give thanks to Allah",
      "To exchange gifts",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to read which book in its entirety?",
    options: ["The Quran", "The Bible", "The Torah", "The Gospels"],
    answer: "The Quran",
  },
  {
    question:
      "What is the name of the special night prayer performed during the last ten days of Ramadan?",
    options: ["Qiyam al-Layl", "Tahajjud", "Taraweeh", "Itikaaf"],
    answer: "Qiyam al-Layl",
  },
  {
    question:
      "What is the purpose of the Laylat al-Qadr, the Night of Power, during Ramadan?",
    options: [
      "To commemorate the first revelation of the Quran",
      "To celebrate the birth of Prophet Muhammad",
      "To mark the start of Ramadan",
      "To mark the end of Ramadan",
    ],
    answer: "To commemorate the first revelation of the Quran",
  },
  {
    question:
      "What is the name of the special retreat observed by some Muslims during the last ten days of Ramadan?",
    options: ["Itikaaf", "Sawm", "Zakat", "Hajj"],
    answer: "Itikaaf",
  },
  {
    question:
      "What is the Arabic term for breaking the fast at sunset during Ramadan?",
    options: ["Iftar", "Suhoor", "Eid", "Sadaqah"],
    answer: "Iftar",
  },
  {
    question:
      "Which day of the Islamic calendar marks the beginning of Ramadan?",
    options: [
      "1st of Ramadan",
      "15th of Sha'ban",
      "1st of Muharram",
      "10th of Dhul-Hijjah",
    ],
    answer: "1st of Ramadan",
  },
  {
    question:
      "What is the name of the special night prayer performed only during Ramadan?",
    options: ["Fajr", "Taraweeh", "Isha", "Maghrib"],
    answer: "Taraweeh",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to practice what in addition to fasting?",
    options: ["Patience", "Generosity", "Humility", "All of the above"],
    answer: "All of the above",
  },
  {
    question:
      "What is the purpose of fasting during Ramadan for those who are physically able?",
    options: [
      "To lose weight",
      "To test willpower",
      "To purify the soul",
      "All of the above",
    ],
    answer: "To purify the soul",
  },
  {
    question:
      "What is the Arabic term for the pre-dawn meal eaten before beginning the daily fast during Ramadan?",
    options: ["Suhoor", "Iftar", "Sadaqah", "Zakat"],
    answer: "Suhoor",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to avoid what in addition to food and drink?",
    options: ["Lying", "Cheating", "Backbiting", "All of the above"],
    answer: "All of the above",
  },
  {
    question:
      "What is the name of the special night during the last ten days of Ramadan when the Quran was first revealed?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Miraj",
      "Laylat al-Bara'ah",
      "Laylat al-Isra",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to do what in addition to fasting and praying?",
    options: [
      "Read the Quran",
      "Give charity",
      "Perform good deeds",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the name of the special charity given to the poor and needy during Ramadan?",
    options: ["Zakat", "Sadaqah", "Fitrah", "Khums"],
    answer: "Fitrah",
  },
  {
    question:
      "What is the purpose of fasting during Ramadan for those who are physically unable to do so?",
    options: [
      "To feed the poor",
      "To pay a fine",
      "To make up the missed fasts later",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the name of the celebration that marks the end of Ramadan?",
    options: ["Eid al-Fitr", "Eid al-Adha", "Mawlid al-Nabi", "Ashura"],
    answer: "Eid al-Fitr",
  },
  {
    question:
      "During Ramadan, Muslims are encouraged to do what in addition to refraining from food and drink?",
    options: [
      "Avoid sinful behavior",
      "Increase acts of worship",
      "Strengthen family ties",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Arabic term for the special night vigil observed by some Muslims during the last ten days of Ramadan?",
    options: ["Itikaaf", "Sawm", "Taraweeh", "Qiyam"],
    answer: "Itikaaf",
  },
  {
    question:
      "What is the purpose of the Taraweeh prayers performed during Ramadan?",
    options: [
      "To complete the recitation of the Quran",
      "To seek forgiveness",
      "To strengthen community bonds",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Arabic term for the special reward or blessing associated with Ramadan?",
    options: ["Barakah", "Sawab", "Rizq", "Taqwa"],
    answer: "Barakah",
  },
  {
    question:
      "What is the Arabic term for the crescent moon sighting that marks the beginning of Ramadan?",
    options: ["Hilal", "Qamar", "Falak", "Kawakib"],
    answer: "Hilal",
  },
  {
    question:
      "What is the name of the special night prayer performed during the last ten nights of Ramadan?",
    options: ["Qiyam al-Layl", "Tahajjud", "Taraweeh", "Fajr"],
    answer: "Qiyam al-Layl",
  },
  {
    question:
      "What is the Arabic term for the obligatory charity given to the poor before the Eid al-Fitr celebration?",
    options: ["Zakat", "Sadaqah", "Fitrah", "Khums"],
    answer: "Fitrah",
  },
  {
    question:
      "What is the purpose of the special night prayer called Qiyam al-Layl during the last ten nights of Ramadan?",
    options: [
      "To seek forgiveness",
      "To commemorate the revelation of the Quran",
      "To increase spiritual connection",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the significance of the Laylat al-Qadr, the Night of Power, during Ramadan?",
    options: [
      "It commemorates the first revelation of the Quran",
      "It marks the birth of Prophet Muhammad",
      "It celebrates the end of Ramadan",
      "It signifies the start of the Islamic calendar",
    ],
    answer: "It commemorates the first revelation of the Quran",
  },
  {
    question:
      "What is the Arabic term for the special reward or blessing associated with good deeds performed during Ramadan?",
    options: ["Ajr", "Thawaab", "Barakah", "Sawab"],
    answer: "Barakah",
  },
  {
    question:
      "What is the significance of the Taraweeh prayers performed during Ramadan?",
    options: [
      "To complete the recitation of the Quran",
      "To seek forgiveness",
      "To increase spiritual connection",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Arabic term for the special reward or blessing associated with fasting during Ramadan?",
    options: ["Sawab", "Thawaab", "Ajr", "Barakah"],
    answer: "Barakah",
  },
  {
    question:
      "What is the significance of the Eid al-Fitr celebration at the end of Ramadan?",
    options: [
      "To mark the completion of fasting",
      "To celebrate the revelation of the Quran",
      "To commemorate the Prophet's migration",
      "All of the above",
    ],
    answer: "To mark the completion of fasting",
  },
  {
    question:
      "What is the Arabic term for the pre-dawn meal eaten before starting the fast during Ramadan?",
    options: ["Suhoor", "Iftar", "Sadaqah", "Zakat"],
    answer: "Suhoor",
  },
  {
    question:
      "What is the name of the special charity given to the poor and needy before the Eid al-Fitr celebration?",
    options: ["Zakat", "Sadaqah", "Fitrah", "Khums"],
    answer: "Fitrah",
  },
  {
    question:
      "What is the Arabic term for the special night prayer performed during the last ten nights of Ramadan?",
    options: ["Qiyam al-Layl", "Tahajjud", "Taraweeh", "Fajr"],
    answer: "Qiyam al-Layl",
  },
  {
    question:
      "What is the purpose of the Itikaaf, the special retreat observed by some Muslims during the last ten days of Ramadan?",
    options: [
      "To increase spiritual connection",
      "To seek forgiveness and blessings",
      "To complete the recitation of the Quran",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Arabic term for the meal eaten to break the fast at sunset during Ramadan?",
    options: ["Iftar", "Suhoor", "Sadaqah", "Zakat"],
    answer: "Iftar",
  },
  {
    question:
      "What is the purpose of the Qiyam al-Layl, the special night prayer performed during the last ten nights of Ramadan?",
    options: [
      "To commemorate the Night of Power (Laylat al-Qadr)",
      "To seek forgiveness and blessings",
      "To complete the recitation of the Quran",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Arabic term for the special charity given to the poor and needy before the Eid al-Fitr celebration?",
    options: ["Zakat", "Sadaqah", "Fitrah", "Khums"],
    answer: "Fitrah",
  },
];

export default islamicRamadanQuestions;
