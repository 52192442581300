import React from "react";
import { Link } from "react-router-dom";
import triviaIcon from "../../assets/trivia-color.png";
import hadithsIcon from "../../assets/hadith-color.png";
import homeIcon from "../../assets/home-color.png";
import moreIcon from "../../assets/more.png";
import quranIcon from "../../assets/quran.png";

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 flex justify-around items-center lg:w-1/2 mx-auto p-4 bg-slate-100 text-xs">
      <Link to="/trivia" className="flex flex-col items-center">
        <img src={triviaIcon} alt="Trivia" className="h-10" />
        <span className="text-deeperPurple font-bold">Trivia</span>
      </Link>
      <Link to="/quraninsights" className="flex flex-col items-center">
        <img src={quranIcon} alt="Quran+" className="h-10" />
        <span className="text-deeperPurple font-bold">Quran+</span>
      </Link>
      <Link to="/" className="flex flex-col items-center">
        <img src={homeIcon} alt="Home" className="h-10" />
        <span className="text-deeperPurple font-bold">Home</span>
      </Link>
      <Link to="/hadiths" className="flex flex-col items-center">
        <img src={hadithsIcon} alt="Hadiths" className="h-10" />
        <span className="text-deeperPurple font-bold">Hadiths</span>
      </Link>
      <Link to="/moreapps" className="flex flex-col items-center">
        <img src={moreIcon} alt="More" className="h-10" />
        <span className="text-deeperPurple font-bold">More</span>
      </Link>
    </footer>
  );
};

export default Footer;
