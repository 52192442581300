const islamicPracticesQuestions = [
  {
    question: "What is the term for the mandatory charity given by Muslims?",
    options: ["Zakat", "Sadaqah", "Fitrana", "Kaffara"],
    answer: "Zakat",
  },
  {
    question:
      "How many times a day are Muslims required to perform Salah (prayer)?",
    options: ["3", "5", "7", "1"],
    answer: "5",
  },
  {
    question:
      "What is the name of the pilgrimage to Mecca that every Muslim is required to make at least once in their lifetime, if able?",
    options: ["Umrah", "Hajj", "Jihad", "Salah"],
    answer: "Hajj",
  },
  {
    question: "During which Islamic month is Ramadan fasting observed?",
    options: ["Rabi' al-awwal", "Ramadan", "Shawwal", "Dhul Hijjah"],
    answer: "Ramadan",
  },
  {
    question: "What is the Arabic term for fasting from dawn until sunset?",
    options: ["Salah", "Siyam", "Zakat", "Hajj"],
    answer: "Siyam",
  },
  {
    question: "Which prayer is performed by Muslims on Friday?",
    options: ["Isha", "Dhuhr", "Asr", "Jumu'ah"],
    answer: "Jumu'ah",
  },
  {
    question:
      "What do Muslims call the 'Night of Decree', considered the holiest night in Ramadan?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Bara'at",
      "Laylat al-Miraj",
      "Laylat al-Isra",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question: "Which of the following is not one of the Five Pillars of Islam?",
    options: ["Jihad", "Salah", "Hajj", "Sawm"],
    answer: "Jihad",
  },
  {
    question:
      "What is the Islamic practice of non-obligatory, personal supplication called?",
    options: ["Dua", "Zikr", "Fard", "Witr"],
    answer: "Dua",
  },
  {
    question: "Which one of these is a major sin in Islam?",
    options: [
      "Eating pork",
      "Missing a Salah",
      "Shirk (associating partners with Allah)",
      "Not paying Zakat",
    ],
    answer: "Shirk (associating partners with Allah)",
  },
  {
    question: "How many times a day are Muslims required to pray?",
    options: ["3", "5", "7", "1"],
    answer: "5",
  },
  {
    question: "What month do Muslims fast during daylight hours?",
    options: ["Ramadan", "Shawwal", "Dhul Hijjah", "Rajab"],
    answer: "Ramadan",
  },
  {
    question:
      "What is the pilgrimage to Mecca called, which is required to be undertaken by Muslims at least once in their lifetime if they are able?",
    options: ["Umrah", "Hajj", "Ziyarah", "Jihad"],
    answer: "Hajj",
  },
  {
    question: "Which prayer is performed only during Ramadan at night?",
    options: ["Taraweeh", "Isha", "Fajr", "Dhuhr"],
    answer: "Taraweeh",
  },
  {
    question: "What do Muslims say before eating to bless their food?",
    options: ["Alhamdulillah", "Bismillah", "Subhanallah", "Allahu Akbar"],
    answer: "Bismillah",
  },
  {
    question: "During which Islamic month is the Hajj pilgrimage performed?",
    options: ["Ramadan", "Rajab", "Shawwal", "Dhul Hijjah"],
    answer: "Dhul Hijjah",
  },
  {
    question:
      "What is the cube-shaped structure located in Mecca that Muslims face during prayer?",
    options: ["Kaaba", "Masjid al-Haram", "Masjid an-Nabawi", "Aqsa"],
    answer: "Kaaba",
  },
  {
    question:
      "Which night in the last ten days of Ramadan is believed to be better than a thousand months?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Bara'at",
      "Laylat al-Miraj",
      "Laylat al-Isra",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question: "Which of the following is considered a major sin in Islam?",
    options: [
      "Eating pork",
      "Missing a prayer unintentionally",
      "Associating partners with Allah (Shirk)",
      "Fasting on Eid day",
    ],
    answer: "Associating partners with Allah (Shirk)",
  },
  {
    question: "What is the term for the ritual prayer performed by Muslims?",
    options: ["Dua", "Salah", "Zikr", "Sawm"],
    answer: "Salah",
  },
  {
    question:
      "What is the Islamic term for almsgiving other than Zakat, which is voluntary charity?",
    options: ["Sadaqah", "Fitrana", "Hajj", "Umrah"],
    answer: "Sadaqah",
  },
  {
    question:
      "Which day of the week is considered the most sacred and a weekly Eid for Muslims?",
    options: ["Monday", "Friday", "Saturday", "Sunday"],
    answer: "Friday",
  },
  {
    question:
      "What is the term used for the moral and ethical way of slaughtering animals for consumption in Islam?",
    options: ["Halal", "Haram", "Tayyib", "Makruh"],
    answer: "Halal",
  },
  {
    question: "What action is recommended before entering a mosque?",
    options: [
      "Performing Wudu (ablution)",
      "Reciting Surah Al-Fatiha",
      "Giving Sadaqah",
      "Reading Quran",
    ],
    answer: "Performing Wudu (ablution)",
  },
  {
    question: "Which practice is recommended on the night of Laylat al-Qadr?",
    options: [
      "Fasting",
      "Performing Umrah",
      "Engaging in prayer and recitation of the Quran",
      "Distributing Zakat",
    ],
    answer: "Engaging in prayer and recitation of the Quran",
  },
  {
    question:
      "What is the significance of the white and black threads in the context of fasting?",
    options: [
      "Marking the beginning and end of Ramadan",
      "Indicating lawful and unlawful actions",
      "Distinguishing night from day for the pre-dawn meal",
      "Representing purity and sin",
    ],
    answer: "Distinguishing night from day for the pre-dawn meal",
  },
  {
    question: "What is Ghusl?",
    options: [
      "A type of charity",
      "A short prayer",
      "A full body ritual purification",
      "A specific posture in prayer",
    ],
    answer: "A full body ritual purification",
  },
  {
    question: "What do Muslims say when completing their prayer?",
    options: [
      "Subhanallah",
      "Alhamdulillah",
      "Allahu Akbar",
      "As-salamu alaykum",
    ],
    answer: "As-salamu alaykum",
  },
  {
    question:
      "What is the Islamic term for the obligatory prayer performed five times a day?",
    options: ["Salat", "Sawm", "Zakat", "Hajj"],
    answer: "Salat",
  },
  {
    question: "Which of the following is one of the Five Pillars of Islam?",
    options: [
      "Reciting the Quran",
      "Fasting during Ramadan",
      "Celebrating Eid",
      "Performing Umrah",
    ],
    answer: "Fasting during Ramadan",
  },
  {
    question: "What is the name given to the annual pilgrimage to Mecca?",
    options: ["Salat", "Sawm", "Zakat", "Hajj"],
    answer: "Hajj",
  },
  {
    question:
      "Which of the following is an obligatory act of worship in Islam?",
    options: [
      "Giving charity",
      "Studying the Quran",
      "Performing Hajj",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question: "What is the Islamic term for the declaration of faith?",
    options: ["Shahadah", "Salat", "Zakat", "Sawm"],
    answer: "Shahadah",
  },
  {
    question:
      "Which of the following is considered one of the Five Pillars of Islam?",
    options: [
      "Reciting the Quran daily",
      "Giving charity to the poor",
      "Performing Umrah",
      "Celebrating Eid al-Fitr",
    ],
    answer: "Giving charity to the poor",
  },
  {
    question:
      "What is the name given to the obligatory annual charity in Islam?",
    options: ["Sawm", "Zakat", "Hajj", "Shahadah"],
    answer: "Zakat",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Ramadan?",
    options: [
      "Fasting from dawn to dusk",
      "Performing Hajj",
      "Giving Zakat",
      "Reciting the entire Quran",
    ],
    answer: "Fasting from dawn to dusk",
  },
  {
    question:
      "What is the Islamic term for the ritual washing or ablution performed before prayer?",
    options: ["Wudu", "Salat", "Sawm", "Zakat"],
    answer: "Wudu",
  },
  {
    question:
      "Which of the following is considered a highly recommended act of worship in Islam?",
    options: [
      "Performing Umrah",
      "Reciting the Quran daily",
      "Giving charity",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced outside of Ramadan?",
    options: ["Sawm", "Zakat", "Hajj", "Nafl"],
    answer: "Nafl",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Dhul-Hijjah?",
    options: [
      "Fasting from dawn to dusk",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Reciting the entire Quran",
    ],
    answer: "Performing Hajj",
  },
  {
    question:
      "What is the Islamic term for the prayer performed at specific times throughout the day?",
    options: ["Salat", "Sawm", "Zakat", "Hajj"],
    answer: "Salat",
  },
  {
    question:
      "What is the Islamic term for the ritual purification practiced before prayer or other acts of worship?",
    options: ["Wudu", "Salat", "Sawm", "Zakat"],
    answer: "Wudu",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Muharram?",
    options: [
      "Fasting on the Day of Ashura",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the Day of Ashura",
  },
  {
    question:
      "What is the Islamic term for the voluntary acts of worship performed in addition to the obligatory ones?",
    options: ["Nafl", "Sawm", "Zakat", "Hajj"],
    answer: "Nafl",
  },
  {
    question:
      "What is the Islamic term for the night prayer performed after the obligatory evening prayer?",
    options: ["Tahajjud", "Salat", "Sawm", "Zakat"],
    answer: "Tahajjud",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Shawwal?",
    options: [
      "Fasting on the Day of Ashura",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Celebrating Eid al-Fitr",
  },
  {
    question:
      "What is the Islamic term for the ritual cleansing of the body before prayer?",
    options: ["Wudu", "Salat", "Zakat", "Sawm"],
    answer: "Wudu",
  },
  {
    question:
      "What is the Islamic term for the voluntary night prayer performed after the obligatory evening prayer?",
    options: ["Tahajjud", "Fajr", "Maghrib", "Isha"],
    answer: "Tahajjud",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Dhul-Qa'dah?",
    options: [
      "Fasting from dawn to dusk",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving charity",
    ],
    answer: "Giving charity",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on Mondays and Thursdays?",
    options: ["Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah", "Sawm al-Nafi"],
    answer: "Sawm al-Sunnah",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Rajab?",
    options: [
      "Fasting on the 27th night",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting on the 27th night",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory evening prayer?",
    options: ["Tahajjud", "Qiyam al-Layl", "Nafl Salat", "Isha"],
    answer: "Qiyam al-Layl",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Sha'ban?",
    options: [
      "Fasting on the 15th night",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 15th night",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the 13th, 14th, and 15th of each lunar month?",
    options: [
      "Sawm al-Ayyam al-Bid",
      "Sawm al-Witr",
      "Nafl Sawm",
      "Sawm al-Sunnah",
    ],
    answer: "Sawm al-Ayyam al-Bid",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Safar?",
    options: [
      "Fasting on the first Thursday",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting on the first Thursday",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory dawn prayer?",
    options: ["Ishraq", "Duha", "Tahajjud", "Qiyam al-Layl"],
    answer: "Ishraq",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Rabi' al-Awwal?",
    options: [
      "Fasting on the 12th day",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 12th day",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the 9th and 10th of Muharram?",
    options: ["Sawm al-Ashura", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Ashura",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Jumada al-Awwal?",
    options: [
      "Fasting on the 27th day",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting on the 27th day",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed between the obligatory noon and afternoon prayers?",
    options: ["Duha", "Ishraq", "Qiyam al-Duha", "Qiyam al-Zhuhr"],
    answer: "Qiyam al-Duha",
  },
  {
    question:
      "Which of the following is an important Islamic practice during the month of Jumada al-Akhirah?",
    options: [
      "Fasting on the 15th day",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 15th day",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Rabi' al-Thani?",
    options: [
      "Fasting on the first Monday",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting on the first Monday",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory noon prayer?",
    options: ["Duha", "Ishraq", "Qiyam al-Zhuhr", "Qiyam al-Duha"],
    answer: "Qiyam al-Zhuhr",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the Day of Arafat during the Hajj season?",
    options: ["Sawm al-Arafah", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Arafah",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Dhu al-Hijjah?",
    options: [
      "Fasting on the 9th day",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 9th day",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory Friday prayer?",
    options: [
      "Salat al-Jumu'ah",
      "Qiyam al-Jumu'ah",
      "Nafl Salat",
      "Salat al-Zuhr",
    ],
    answer: "Qiyam al-Jumu'ah",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Dhu al-Qa'dah?",
    options: [
      "Fasting on the 15th day",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting on the 15th day",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Shawwal?",
    options: [
      "Fasting on the 6th day",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 6th day",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory morning prayer?",
    options: ["Ishraq", "Duha", "Qiyam al-Fajr", "Tahajjud"],
    answer: "Qiyam al-Fajr",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Muharram?",
    options: [
      "Fasting on the 10th day",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 10th day",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the 9th of Dhu al-Hijjah?",
    options: ["Sawm al-Tasu'a", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Tasu'a",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed between the obligatory sunset and evening prayers?",
    options: ["Awwabeen", "Ishraq", "Qiyam al-Maghrib", "Tahajjud"],
    answer: "Awwabeen",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Rabi' al-Awwal?",
    options: [
      "Fasting on the 12th day",
      "Performing Hajj",
      "Celebrating the Prophet's Birthday",
      "Giving Zakat",
    ],
    answer: "Celebrating the Prophet's Birthday",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the 27th of Rajab?",
    options: [
      "Sawm Laylat al-Isra' wal-Mi'raj",
      "Sawm al-Witr",
      "Nafl Sawm",
      "Sawm al-Sunnah",
    ],
    answer: "Sawm Laylat al-Isra' wal-Mi'raj",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory night prayer?",
    options: ["Qiyam al-Layl", "Tahajjud", "Nafl Salat", "Awwabeen"],
    answer: "Tahajjud",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Jumada al-Akhirah?",
    options: [
      "Fasting on the last Monday",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the last Monday",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory afternoon prayer?",
    options: ["Qiyam al-Asr", "Ishraq", "Duha", "Tahajjud"],
    answer: "Qiyam al-Asr",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory sunrise prayer?",
    options: ["Ishraq", "Duha", "Qiyam al-Fajr", "Tahajjud"],
    answer: "Duha",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Sha'ban?",
    options: [
      "Fasting on the 15th night",
      "Performing Hajj",
      "Celebrating Eid al-Fitr",
      "Giving Zakat",
    ],
    answer: "Fasting on the 15th night",
  },
  {
    question:
      "Which of the following is considered an important Islamic practice during the month of Ramadan?",
    options: [
      "Fasting from dawn to dusk",
      "Performing Hajj",
      "Celebrating Eid al-Adha",
      "Giving Zakat",
    ],
    answer: "Fasting from dawn to dusk",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory late evening prayer?",
    options: ["Qiyam al-Layl", "Tahajjud", "Nafl Salat", "Awwabeen"],
    answer: "Tahajjud",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the 27th night of Ramadan?",
    options: [
      "Sawm Laylat al-Qadr",
      "Sawm al-Witr",
      "Nafl Sawm",
      "Sawm al-Sunnah",
    ],
    answer: "Sawm Laylat al-Qadr",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed after the obligatory sunset prayer?",
    options: [
      "Salat al-Maghrib",
      "Qiyam al-Maghrib",
      "Nafl Salat",
      "Salat al-Isha",
    ],
    answer: "Qiyam al-Maghrib",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the first 9 days of Dhu al-Hijjah?",
    options: [
      "Sawm al-Ayyam al-Bid",
      "Sawm al-Witr",
      "Nafl Sawm",
      "Sawm al-Tasu'a",
    ],
    answer: "Sawm al-Tasu'a",
  },
  {
    question:
      "What is the Islamic term for the voluntary prayer performed between the obligatory evening and night prayers?",
    options: ["Awwabeen", "Ishraq", "Qiyam al-Isha", "Tahajjud"],
    answer: "Awwabeen",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the Day of Arafa during the Hajj season?",
    options: ["Sawm al-Arafah", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Arafah",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the day before Eid al-Fitr?",
    options: ["Sawm al-Wisal", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Wisal",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the day of Eid al-Adha?",
    options: ["Sawm al-Eid", "Sawm al-Witr", "Nafl Sawm", "Sawm al-Sunnah"],
    answer: "Sawm al-Eid",
  },
  {
    question:
      "What is the Islamic term for the voluntary fasting practiced on the day before Eid al-Adha?",
    options: [
      "Sawm al-Ayyam al-Bid",
      "Sawm al-Witr",
      "Nafl Sawm",
      "Sawm al-Arafah",
    ],
    answer: "Sawm al-Arafah",
  },
];

export default islamicPracticesQuestions;
