const islamicFiguresQuestions = [
  {
    question: "Who was the last prophet of Islam?",
    options: ["Muhammad (PBUH)", "Ibrahim (AS)", "Musa (AS)", "Isa (AS)"],
    answer: "Muhammad (PBUH)",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the first Caliph of Islam after Prophet Muhammad (PBUH)?",
    options: [
      "Umar ibn Al-Khattab (RA)",
      "Uthman ibn Affan (RA)",
      "Ali ibn Abi Talib (RA)",
      "Abu Bakr Al-Siddiq (RA)",
    ],
    answer: "Abu Bakr Al-Siddiq (RA)",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the wife of Prophet Muhammad (PBUH) and the mother of the believers?",
    options: ["Khadijah (RA)", "Aisha (RA)", "Hafsa (RA)", "Umm Salamah (RA)"],
    answer: "Aisha (RA)",
    difficulty: "Easy",
  },
  {
    question: "Who was the first Muezzin (caller to prayer) in Islam?",
    options: [
      "Bilal ibn Rabah (RA)",
      "Abu Bakr (RA)",
      "Umar (RA)",
      "Uthman (RA)",
    ],
    answer: "Bilal ibn Rabah (RA)",
    difficulty: "Easy",
  },
  {
    question: "Who is considered the first Muslim?",
    options: ["Adam", "Ibrahim (Abraham)", "Muhammad", "Isa (Jesus)"],
    answer: "Adam",
    difficulty: "Easy",
  },
  {
    question: "Who was the first woman to accept Islam?",
    options: [
      "Aisha bint Abi Bakr",
      "Fatimah bint Muhammad",
      "Khadija bint Khuwaylid",
      "Hafsa bint Umar",
    ],
    answer: "Khadija bint Khuwaylid",
    difficulty: "Easy",
  },
  {
    question: "What is the holy book of Islam?",
    options: ["Quran", "Bible", "Torah", "Vedas"],
    answer: "Quran",
    difficulty: "Easy",
  },
  {
    question: "Where is the Kaaba located?",
    options: ["Mecca", "Medina", "Jerusalem", "Cairo"],
    answer: "Mecca",
    difficulty: "Easy",
  },
  {
    question: "What is the name of the annual pilgrimage to Mecca?",
    options: ["Hajj", "Umrah", "Eid", "Ramadan"],
    answer: "Hajj",
    difficulty: "Easy",
  },
  {
    question: "What is the Islamic month of fasting?",
    options: ["Ramadan", "Shawwal", "Dhu al-Hijjah", "Rajab"],
    answer: "Ramadan",
    difficulty: "Easy",
  },
  {
    question: "What are the five pillars of Islam?",
    options: [
      "Faith, Prayer, Charity, Fasting, Pilgrimage",
      "Faith, Hope, Charity, Fasting, Pilgrimage",
      "Faith, Prayer, Charity, Fasting, Jihad",
      "Faith, Prayer, Charity, Hajj, Umrah",
    ],
    answer: "Faith, Prayer, Charity, Fasting, Pilgrimage",
    difficulty: "Easy",
  },
  {
    question: "Who is known as the 'Friend of Allah'?",
    options: ["Musa (Moses)", "Ibrahim (Abraham)", "Muhammad", "Dawud (David)"],
    answer: "Ibrahim (Abraham)",
    difficulty: "Easy",
  },
  {
    question: "Which prophet is known for building the Kaaba?",
    options: ["Ismail (Ishmael)", "Ibrahim (Abraham)", "Muhammad", "Adam"],
    answer: "Ibrahim (Abraham)",
    difficulty: "Easy",
  },
  {
    question: "Which prophet is known for surviving a great flood?",
    options: ["Nuh (Noah)", "Musa (Moses)", "Salih", "Hud"],
    answer: "Nuh (Noah)",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the angel tasked with revealing the Quran to Prophet Muhammad?",
    options: ["Jibril (Gabriel)", "Mikail (Michael)", "Israfil", "Azrael"],
    answer: "Jibril (Gabriel)",
    difficulty: "Easy",
  },
  {
    question:
      "Which prophet is known for his long lifespan in Islamic tradition?",
    options: ["Nuh (Noah)", "Adam", "Musa (Moses)", "Idris (Enoch)"],
    answer: "Nuh (Noah)",
    difficulty: "Easy",
  },
  {
    question:
      "Which prophet is known for his story with the whale in the Quran?",
    options: ["Yunus (Jonah)", "Musa (Moses)", "Nuh (Noah)", "Isa (Jesus)"],
    answer: "Yunus (Jonah)",
    difficulty: "Easy",
  },
  {
    question: "Who was the leader of the faithful (Amir al-Mu'minin)?",
    options: [
      "Umar ibn Al-Khattab",
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
      "Abu Bakr",
    ],
    answer: "Ali ibn Abi Talib",
    difficulty: "Easy",
  },
  {
    question: "What is Bilal ibn Rabah most known for in Islamic history?",
    options: [
      "Being a scribe of the Prophet",
      "Being the Prophet's physician",
      "Being the first Muezzin",
      "Leading the Battle of Badr",
    ],
    answer: "Being the first Muezzin",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the prophet known for his beautiful voice and psalms in Islam?",
    options: [
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Yunus (Jonah)",
      "Zakariya (Zechariah)",
    ],
    answer: "Dawud (David)",
    difficulty: "Easy",
  },
  {
    question:
      "Who was known as As-Siddiq (The Truthful) among Prophet Muhammad’s companions?",
    options: [
      "Umar ibn Al-Khattab",
      "Abu Bakr",
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
    ],
    answer: "Abu Bakr",
    difficulty: "Easy",
  },
  {
    question: "Which prophet is known for his patience in Islam?",
    options: ["Yusuf (Joseph)", "Ayyub (Job)", "Musa (Moses)", "Yunus (Jonah)"],
    answer: "Ayyub (Job)",
    difficulty: "Easy",
  },
  {
    question:
      "Who was the wealthy business person that supported the early Muslim community financially?",
    options: [
      "Abdul Rahman bin Auf",
      "Uthman ibn Affan",
      "Abu Bakr",
      "Khadijah",
    ],
    answer: "Khadijah",
    difficulty: "Easy",
  },
  {
    question:
      "What was the profession of Musa (Moses) before he became a prophet in Islamic tradition?",
    options: ["Shepherd", "Carpenter", "Merchant", "Scribe"],
    answer: "Shepherd",
    difficulty: "Easy",
  },
  {
    question: "Who is the mother of Ishmael (Ismail) in Islamic tradition?",
    options: ["Sara", "Hajar", "Maryam", "Asiya"],
    answer: "Hajar",
    difficulty: "Easy",
  },
  {
    question:
      "Which companion of Prophet Muhammad was known for his bravery and skill in battle?",
    options: [
      "Khalid ibn al-Walid (RA)",
      "Abu Bakr (RA)",
      "Umar (RA)",
      "Uthman (RA)",
    ],
    answer: "Khalid ibn al-Walid (RA)",
    difficulty: "Moderate",
  },
  {
    question: "Which prophet is mentioned the most in the Quran?",
    options: ["Musa (Moses)", "Isa (Jesus)", "Muhammad", "Nuh (Noah)"],
    answer: "Musa (Moses)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet is known for his wisdom and ruling between people with justice?",
    options: [
      "Sulaiman (Solomon)",
      "Dawud (David)",
      "Yusuf (Joseph)",
      "Ayub (Job)",
    ],
    answer: "Sulaiman (Solomon)",
    difficulty: "Moderate",
  },
  {
    question: "Who succeeded Umar ibn Al-Khattab as the third caliph of Islam?",
    options: [
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
      "Abu Bakr",
      "Muawiya ibn Abi Sufyan",
    ],
    answer: "Uthman ibn Affan",
    difficulty: "Moderate",
  },
  {
    question:
      "Which daughter of Prophet Muhammad was married to Ali ibn Abi Talib?",
    options: ["Fatimah", "Zainab", "Umm Kulthum", "Ruqayyah"],
    answer: "Fatimah",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet is associated with the miracle of splitting the moon?",
    options: ["Muhammad", "Musa (Moses)", "Isa (Jesus)", "Ibrahim (Abraham)"],
    answer: "Muhammad",
    difficulty: "Moderate",
  },
  {
    question:
      "Which companion of Prophet Muhammad was known for his extensive narration of Hadith?",
    options: [
      "Abu Bakr (RA)",
      "Umar ibn Al-Khattab (RA)",
      "Abdullah ibn Umar (RA)",
      "Abu Hurairah (RA)",
    ],
    answer: "Abu Hurairah (RA)",
    difficulty: "Moderate",
  },
  {
    question:
      "Who was thrown into a fire by Nimrod, according to Islamic tradition?",
    options: [
      "Isa (Jesus)",
      "Ibrahim (Abraham)",
      "Musa (Moses)",
      "Yunus (Jonah)",
    ],
    answer: "Ibrahim (Abraham)",
    difficulty: "Moderate",
  },
  {
    question: "Which prophet is famous for interpreting dreams?",
    options: [
      "Yusuf (Joseph)",
      "Sulaiman (Solomon)",
      "Dawud (David)",
      "Musa (Moses)",
    ],
    answer: "Yusuf (Joseph)",
    difficulty: "Moderate",
  },
  {
    question:
      "Who was given the Taurat (Torah) according to Islamic tradition?",
    options: [
      "Musa (Moses)",
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Isa (Jesus)",
    ],
    answer: "Musa (Moses)",
    difficulty: "Moderate",
  },
  {
    question: "Which prophet spoke to Allah without any intermediaries?",
    options: ["Musa (Moses)", "Muhammad", "Ibrahim (Abraham)", "Isa (Jesus)"],
    answer: "Musa (Moses)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet is known to have been ascended to heaven and will return before the Day of Judgment?",
    options: ["Isa (Jesus)", "Muhammad", "Idris (Enoch)", "Musa (Moses)"],
    answer: "Isa (Jesus)",
    difficulty: "Moderate",
  },
  {
    question: "Who was the prophet sent to the people of 'Ad?",
    options: ["Hud", "Salih", "Shu'ayb", "Lut (Lot)"],
    answer: "Hud",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet's people were transformed into apes for their disobedience?",
    options: ["Musa (Moses)", "Nuh (Noah)", "Salih", "Shu'ayb"],
    answer: "Musa (Moses)",
    difficulty: "Moderate",
  },
  {
    question:
      "What was the relationship of Ali ibn Abi Talib to Prophet Muhammad?",
    options: ["Brother", "Cousin and son-in-law", "Father-in-law", "Nephew"],
    answer: "Cousin and son-in-law",
    difficulty: "Moderate",
  },
  {
    question:
      "Which companion of Prophet Muhammad was known for his honesty and was titled Al-Siddiq?",
    options: [
      "Umar ibn Al-Khattab",
      "Uthman ibn Affan",
      "Ali ibn Abi Talib",
      "Abu Bakr",
    ],
    answer: "Abu Bakr",
    difficulty: "Moderate",
  },
  {
    question: "Who was the first male to accept Islam?",
    options: [
      "Ali ibn Abi Talib",
      "Zaid ibn Haritha",
      "Abu Bakr",
      "Bilal ibn Rabah",
    ],
    answer: "Ali ibn Abi Talib",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet in Islam is known for his exceptional wisdom and ruling a great kingdom?",
    options: [
      "Dawud (David)",
      "Sulaiman (Solomon)",
      "Yusuf (Joseph)",
      "Dhul-Qarnayn",
    ],
    answer: "Sulaiman (Solomon)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet is known for his miraculous birth in Islamic tradition?",
    options: ["Isa (Jesus)", "Musa (Moses)", "Adam", "Yahya (John)"],
    answer: "Isa (Jesus)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which companion of Prophet Muhammad is known for compiling the Quran into a single book?",
    options: [
      "Uthman ibn Affan",
      "Abu Bakr",
      "Umar ibn Al-Khattab",
      "Ali ibn Abi Talib",
    ],
    answer: "Uthman ibn Affan",
    difficulty: "Moderate",
  },
  {
    question:
      "Who was the angel responsible for blowing the trumpet on the Day of Judgment according to Islamic belief?",
    options: ["Mikail (Michael)", "Israfil", "Jibril (Gabriel)", "Azrael"],
    answer: "Israfil",
    difficulty: "Moderate",
  },
  {
    question:
      "Who is considered the father of the Arab people in Islamic tradition?",
    options: ["Ismail (Ishmael)", "Ibrahim (Abraham)", "Adnan", "Qedar"],
    answer: "Ismail (Ishmael)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which prophet is known for having been given the Injeel (Gospel) according to Islam?",
    options: ["Musa (Moses)", "Dawud (David)", "Isa (Jesus)", "Yahya (John)"],
    answer: "Isa (Jesus)",
    difficulty: "Moderate",
  },
  {
    question:
      "Which figure is mentioned as a righteous servant of Allah, not a prophet, who stood up against injustice?",
    options: ["Luqman", "Dhul-Qarnayn", "Salman the Persian", "Al-Khidr"],
    answer: "Luqman",
    difficulty: "Moderate",
  },
  {
    question: "Who was the first Muslim ruler of Egypt?",
    options: [
      "Amr ibn al-As (RA)",
      "Khalid ibn al-Walid (RA)",
      "Saladin (RA)",
      "Umar ibn Al-Khattab (RA)",
    ],
    answer: "Amr ibn al-As (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim governor of Basra?",
    options: [
      "Utbah ibn Ghazwan (RA)",
      "Abu Musa al-Ashari (RA)",
      "Al-Mughira ibn Shu'ba (RA)",
      "Abdullah ibn Abi Sarh (RA)",
    ],
    answer: "Utbah ibn Ghazwan (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim ruler of Spain?",
    options: [
      "Tariq ibn Ziyad (RA)",
      "Musa ibn Nusair (RA)",
      "Abd al-Rahman I (RA)",
      "Al-Walid I (RA)",
    ],
    answer: "Tariq ibn Ziyad (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first female scholar of Islam?",
    options: ["Aisha (RA)", "Fatimah (RA)", "Umm Salamah (RA)", "Hafsa (RA)"],
    answer: "Aisha (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the first Muslim to be buried in Jannat al-Baqi' (the cemetery in Medina)?",
    options: [
      "Uthman ibn Affan (RA)",
      "Abu Bakr (RA)",
      "Umar (RA)",
      "Ali (RA)",
    ],
    answer: "Uthman ibn Affan (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim ruler of India?",
    options: [
      "Mahmud of Ghazni (RA)",
      "Muhammad bin Qasim (RA)",
      "Aurangzeb (RA)",
      "Akbar (RA)",
    ],
    answer: "Mahmud of Ghazni (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim explorer to reach the Americas?",
    options: [
      "Ibn Battuta (RA)",
      "Ahmad ibn Majid (RA)",
      "Muhammad al-Idrisi (RA)",
      "Zheng He (RA)",
    ],
    answer: "Ahmad ibn Majid (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim woman to lead an army?",
    options: [
      "Nusaybah bint Ka'b (RA)",
      "Aisha (RA)",
      "Fatimah (RA)",
      "Umm Salamah (RA)",
    ],
    answer: "Nusaybah bint Ka'b (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the first Muslim scientist to develop a theory of optics?",
    options: [
      "Ibn al-Haytham (RA)",
      "Al-Khwarizmi (RA)",
      "Al-Razi (RA)",
      "Al-Biruni (RA)",
    ],
    answer: "Ibn al-Haytham (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the first Muslim to translate the Quran into a foreign language?",
    options: [
      "Salman al-Farisi (RA)",
      "Abdullah ibn Abbas (RA)",
      "Zayd ibn Thabit (RA)",
      "Hudhayfa ibn al-Yaman (RA)",
    ],
    answer: "Salman al-Farisi (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim ruler of Persia?",
    options: [
      "Saad ibn Abi Waqqas (RA)",
      "Al-Qasim ibn Muhammad ibn Abi Bakr (RA)",
      "Khalid ibn al-Walid (RA)",
      "Abdullah ibn Aamir (RA)",
    ],
    answer: "Saad ibn Abi Waqqas (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim to establish a postal service?",
    options: [
      "Malik ibn Anas (RA)",
      "Abu Bakr (RA)",
      "Umar ibn Al-Khattab (RA)",
      "Uthman ibn Affan (RA)",
    ],
    answer: "Umar ibn Al-Khattab (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the first Muslim woman to be granted the title of 'Sayyidah' (Lady)?",
    options: [
      "Fatimah (RA)",
      "Khadijah (RA)",
      "Aisha (RA)",
      "Umm Salamah (RA)",
    ],
    answer: "Fatimah (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim ruler of Afghanistan?",
    options: [
      "Mahmud of Ghazni (RA)",
      "Ahmad Shah Durrani (RA)",
      "Babur (RA)",
      "Akbar (RA)",
    ],
    answer: "Ahmad Shah Durrani (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Which companion of the Prophet was known as the 'Liberator of Jerusalem'?",
    options: [
      "Salahuddin Ayyubi",
      "Khalid ibn al-Walid",
      "Umar ibn Al-Khattab",
      "Abu Ubaidah ibn al-Jarrah",
    ],
    answer: "Umar ibn Al-Khattab",
    difficulty: "Challenging",
  },
  {
    question:
      "What title is given to Maryam (Mary), the mother of Isa (Jesus) in Islam?",
    options: ["Umm ul-Mu'minin", "Sayyidatun Nisa", "Umm Allah", "Sayyidatina"],
    answer: "Sayyidatina",
    difficulty: "Challenging",
  },
  {
    question: "Who is the archenemy of mankind mentioned in the Quran?",
    options: ["Harut", "Marut", "Iblis", "Jibril"],
    answer: "Iblis",
    difficulty: "Challenging",
  },
  {
    question:
      "Which companion of Prophet Muhammad was known for his leadership in the Battle of Yarmouk?",
    options: [
      "Khalid ibn al-Walid",
      "Amr ibn al-As",
      "Sa'd ibn Abi Waqqas",
      "Abu Ubaidah ibn al-Jarrah",
    ],
    answer: "Khalid ibn al-Walid",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the governor of Egypt appointed by the Prophet Muhammad?",
    options: [
      "Amr ibn al-As",
      "Mu'awiyah I",
      "Abu Ubaidah ibn al-Jarrah",
      "Ali ibn Abi Talib",
    ],
    answer: "Amr ibn al-As",
    difficulty: "Challenging",
  },
  {
    question:
      "Which prophet is often associated with the miracle of reviving the dead in Islamic texts?",
    options: [
      "Isa (Jesus)",
      "Musa (Moses)",
      "Ibrahim (Abraham)",
      "Elias (Elijah)",
    ],
    answer: "Isa (Jesus)",
    difficulty: "Challenging",
  },
  {
    question: "Who is known as the Seal of the Prophets in Islam?",
    options: ["Musa (Moses)", "Isa (Jesus)", "Muhammad", "Dawud (David)"],
    answer: "Muhammad",
    difficulty: "Challenging",
  },
  {
    question:
      "Which female figure is highly revered in Islam for her sacrifice at Karbala?",
    options: [
      "Aisha (RA)",
      "Khadijah (RA)",
      "Zainab bint Ali (RA)",
      "Fatimah (RA)",
    ],
    answer: "Zainab bint Ali (RA)",
    difficulty: "Challenging",
  },
  {
    question:
      "Who was the cousin and son-in-law of Prophet Muhammad, known for his knowledge and piety?",
    options: [
      "Uthman ibn Affan (RA)",
      "Abu Bakr (RA)",
      "Umar ibn Al-Khattab (RA)",
      "Ali ibn Abi Talib (RA)",
    ],
    answer: "Ali ibn Abi Talib (RA)",
    difficulty: "Challenging",
  },
  {
    question: "Who was the first Muslim to establish a university?",
    options: [
      "Al-Azhar University",
      "University of Cordoba",
      "University of Timbuktu",
      "Nizamiyah University",
    ],
    answer: "University of Cordoba",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the first Muslim to translate the works of Aristotle into Arabic?",
    options: [
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
      "Avicenna (RA)",
      "Al-Ghazali (RA)",
    ],
    answer: "Al-Kindi (RA)",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the first Muslim to translate the works of Euclid into Arabic?",
    options: [
      "Al-Hajjaj ibn Yusuf (RA)",
      "Al-Kindi (RA)",
      "Thabit ibn Qurra (RA)",
      "Al-Battani (RA)",
    ],
    answer: "Al-Hajjaj ibn Yusuf (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on medicine?",
    options: [
      "Al-Razi (RA)",
      "Ibn Sina (RA)",
      "Al-Zahrawi (RA)",
      "Ibn Rushd (RA)",
    ],
    answer: "Al-Razi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to develop a system of algebra?",
    options: [
      "Al-Khwarizmi (RA)",
      "Al-Biruni (RA)",
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
    ],
    answer: "Al-Khwarizmi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on geography?",
    options: [
      "Al-Idrisi (RA)",
      "Ibn Battuta (RA)",
      "Al-Biruni (RA)",
      "Al-Masudi (RA)",
    ],
    answer: "Al-Idrisi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on algebra?",
    options: [
      "Al-Khwarizmi (RA)",
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
      "Ibn Sina (RA)",
    ],
    answer: "Al-Khwarizmi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on astronomy?",
    options: [
      "Al-Battani (RA)",
      "Ibn al-Haytham (RA)",
      "Al-Biruni (RA)",
      "Al-Farghani (RA)",
    ],
    answer: "Al-Farghani (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on chemistry?",
    options: [
      "Jabir ibn Hayyan (RA)",
      "Al-Razi (RA)",
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
    ],
    answer: "Jabir ibn Hayyan (RA)",
    difficulty: "Difficult",
  },
  {
    question:
      "Who was the first Muslim to translate the works of Galen into Arabic?",
    options: [
      "Hunayn ibn Ishaq (RA)",
      "Al-Razi (RA)",
      "Al-Zahrawi (RA)",
      "Ibn Sina (RA)",
    ],
    answer: "Hunayn ibn Ishaq (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on philosophy?",
    options: [
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
      "Ibn Sina (RA)",
      "Al-Ghazali (RA)",
    ],
    answer: "Al-Kindi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on logic?",
    options: [
      "Al-Farabi (RA)",
      "Al-Kindi (RA)",
      "Ibn Sina (RA)",
      "Al-Ghazali (RA)",
    ],
    answer: "Al-Farabi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on psychology?",
    options: [
      "Al-Razi (RA)",
      "Ibn Sina (RA)",
      "Al-Ghazali (RA)",
      "Al-Farabi (RA)",
    ],
    answer: "Al-Razi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on sociology?",
    options: [
      "Ibn Khaldun (RA)",
      "Al-Farabi (RA)",
      "Al-Ghazali (RA)",
      "Al-Biruni (RA)",
    ],
    answer: "Ibn Khaldun (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on history?",
    options: [
      "Al-Tabari (RA)",
      "Al-Masudi (RA)",
      "Ibn Khaldun (RA)",
      "Al-Biruni (RA)",
    ],
    answer: "Al-Tabari (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on political science?",
    options: [
      "Al-Farabi (RA)",
      "Al-Mawardi (RA)",
      "Ibn Khaldun (RA)",
      "Al-Ghazali (RA)",
    ],
    answer: "Al-Farabi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on jurisprudence?",
    options: [
      "Imam Abu Hanifa (RA)",
      "Imam Malik (RA)",
      "Imam Shafi'i (RA)",
      "Imam Ahmad ibn Hanbal (RA)",
    ],
    answer: "Imam Abu Hanifa (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on mathematics?",
    options: [
      "Al-Khwarizmi (RA)",
      "Al-Kindi (RA)",
      "Al-Biruni (RA)",
      "Al-Battani (RA)",
    ],
    answer: "Al-Khwarizmi (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on ethics?",
    options: [
      "Al-Ghazali (RA)",
      "Al-Farabi (RA)",
      "Ibn Sina (RA)",
      "Al-Kindi (RA)",
    ],
    answer: "Al-Ghazali (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim to write a book on metaphysics?",
    options: [
      "Ibn Sina (RA)",
      "Al-Kindi (RA)",
      "Al-Farabi (RA)",
      "Al-Ghazali (RA)",
    ],
    answer: "Ibn Sina (RA)",
    difficulty: "Difficult",
  },
  {
    question: "Who was the first Muslim among the jinn?",
    options: ["Harut", "Marut", "Iblis", "Nasib"],
    answer: "Nasib",
    difficulty: "Difficult",
  },
  {
    question:
      "Which prophet in Islam is known for his wisdom and ruling over humans, jinn, and animals?",
    options: [
      "Sulaiman (Solomon)",
      "Dawud (David)",
      "Yusuf (Joseph)",
      "Ayub (Job)",
    ],
    answer: "Sulaiman (Solomon)",
    difficulty: "Difficult",
  },
  {
    question:
      "What was the name of Prophet Muhammad’s uncle who was a staunch supporter of Islam?",
    options: ["Abu Lahab", "Abu Talib", "Hamza", "Abbas"],
    answer: "Hamza",
    difficulty: "Difficult",
  },
  {
    question:
      "Which prophet is known for constructing the Ark in Islamic tradition?",
    options: ["Nuh (Noah)", "Musa (Moses)", "Yunus (Jonah)", "Lut (Lot)"],
    answer: "Nuh (Noah)",
    difficulty: "Difficult",
  },
];

export default islamicFiguresQuestions;
