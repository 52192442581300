// export default App;
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Trivia from "./components/Trivia/Trivia";
import Hadiths from "./components/Hadiths/Hadiths";
import MainLandingPage from "./components/MainContent/MainContent";
import Rabanna from "./components/Rabanna/Rabanna";
import Nuggets from "./components/Nuggets/Nuggets";
import MoreApps from "./components/MoreApps/MoreApps";
import About from "./components/About/About";
import QuranInsights from "./components/quranChapters/quranInsights";
import ChapterInsight from "./components/quranChapters/chapterInsight";
import TriviaPlus from "./components/TriviaPlus/TriviaPlus";

// Component to track page views
const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // When the location changes, send a pageview to GA
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null; // This component does not render anything
};

function App() {
  return (
    <BrowserRouter>
      <TrackPageViews /> {/* This will track page views on route changes */}
      <Header />
      <Routes>
        <Route path="/trivia" element={<Trivia />} />
        <Route path="/hadiths" element={<Hadiths />} />
        <Route path="/" element={<MainLandingPage />} />
        <Route path="/rabanna" element={<Rabanna />} />
        <Route path="/nuggets" element={<Nuggets />} />
        <Route path="/moreapps" element={<MoreApps />} />
        <Route path="/about" element={<About />} />
        <Route path="/quranInsights" element={<QuranInsights />} />
        <Route path="/triviaplus" element={<TriviaPlus />} />
        <Route
          path="/quranInsights/:chapterSlug"
          element={<ChapterInsight />}
        />
        <Route path="*" element={<MainLandingPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
