const islamicTextsQuestions = [
  {
    question: "What is the name of the holy book of Islam?",
    options: ["The Bible", "The Torah", "The Quran", "The Psalms"],
    answer: "The Quran",
  },
  {
    question: "Which language was the Quran originally revealed in?",
    options: ["English", "Arabic", "Hebrew", "Persian"],
    answer: "Arabic",
  },
  {
    question: "What is the meaning of 'Quran'?",
    options: ["The Book", "The Recitation", "The Word", "The Truth"],
    answer: "The Recitation",
  },
  {
    question:
      "Who is believed to have been the first person to compile the Quran into a single book?",
    options: [
      "Prophet Muhammad",
      "Caliph Umar",
      "Caliph Ali",
      "Caliph Abu Bakr",
    ],
    answer: "Caliph Abu Bakr",
  },
  {
    question: "What are the verses in the Quran called?",
    options: ["Chapters", "Hadith", "Ayahs", "Suras"],
    answer: "Ayahs",
  },
  {
    question: "Which is the longest chapter (Sura) in the Quran?",
    options: ["Al-Baqarah", "Al-Fatiha", "Al-Imran", "Al-Nisa"],
    answer: "Al-Baqarah",
  },
  {
    question:
      "What is the name given to the sayings and actions of Prophet Muhammad?",
    options: ["Quran", "Hadith", "Sira", "Fiqh"],
    answer: "Hadith",
  },
  {
    question: "Which Sura is recited in every unit of the Muslim prayer?",
    options: ["Al-Ikhlas", "Al-Mulk", "Al-Fatiha", "Al-Kafirun"],
    answer: "Al-Fatiha",
  },
  {
    question: "What is 'Tafsir'?",
    options: [
      "Compilation of Hadith",
      "Biography of Prophet Muhammad",
      "Interpretation of the Quran",
      "Islamic Jurisprudence",
    ],
    answer: "Interpretation of the Quran",
  },
  {
    question: "Which of the following texts contains collections of hadith?",
    options: ["Sahih Bukhari", "The Torah", "The Psalms", "The Gospel"],
    answer: "Sahih Bukhari",
  },
  {
    question: "Which Sura of the Quran is known as the heart of the Quran?",
    options: ["Yasin", "Al-Fatiha", "Al-Mulk", "Ar-Rahman"],
    answer: "Yasin",
  },
  {
    question: "What is the smallest Sura in the Quran?",
    options: ["Al-Ikhlas", "Al-Kawthar", "Al-Asr", "An-Nasr"],
    answer: "Al-Kawthar",
  },
  {
    question:
      "Who was the angel responsible for revealing the Quran to Prophet Muhammad?",
    options: ["Jubril", "Mikail", "Israfil", "Munkar"],
    answer: "Jubril",
  },
  {
    question:
      "In Islamic tradition, what night is known as the Night of Power?",
    options: [
      "Laylat al-Qadr",
      "Laylat al-Bara'at",
      "Laylat al-Miraj",
      "Laylat al-Isra",
    ],
    answer: "Laylat al-Qadr",
  },
  {
    question: "What does 'Surah Al-Asr' emphasize the importance of?",
    options: ["Patience", "Prayer", "Time", "Charity"],
    answer: "Time",
  },
  {
    question:
      "What type of verses are predominant in the Meccan Suras of the Quran?",
    options: [
      "Legal rulings",
      "Stories of previous prophets",
      "Descriptions of Paradise and Hell",
      "Family ethics",
    ],
    answer: "Stories of previous prophets",
  },
  {
    question: "What is the main theme of Surah Al-Hujurat?",
    options: ["Social conduct", "Economic justice", "Worship", "The Afterlife"],
    answer: "Social conduct",
  },
  {
    question: "Which Surah is known as the 'Beauty of the Quran'?",
    options: ["Al-Rahman", "Al-Mulk", "Yasin", "Al-Kahf"],
    answer: "Al-Rahman",
  },
  {
    question: "How many Juz' (parts) is the Quran divided into?",
    options: ["30", "114", "25", "99"],
    answer: "30",
  },
  {
    question:
      "Which Surah concludes with the words 'Indeed, to Us is their return'",
    options: ["Al-A'la", "Al-Nas", "Al-Kafirun", "Al-Balad"],
    answer: "Al-A'la",
  },
  {
    question: "What does 'Surah' mean in the context of the Quran?",
    options: ["Verse", "Chapter", "Book", "Story"],
    answer: "Chapter",
  },
  {
    question: "Which Surah of the Quran is known as the 'Heart of the Quran'?",
    options: ["Yasin", "Al-Fatiha", "Al-Ikhlas", "Al-Mulk"],
    answer: "Yasin",
  },
  {
    question: "In which city was the Quran first revealed?",
    options: ["Mecca", "Medina", "Jerusalem", "Damascus"],
    answer: "Mecca",
  },
  {
    question: "What is the significance of 'Bismillah' in the Quran?",
    options: [
      "A prayer for forgiveness",
      "The opening phrase of most Surahs",
      "A call to prayer",
      "An end-of-Surah salutation",
    ],
    answer: "The opening phrase of most Surahs",
  },
  {
    question: "Which Surah of the Quran does not begin with 'Bismillah'?",
    options: ["Al-Tawbah", "Al-Fatiha", "Al-Baqarah", "Al-Ikhlas"],
    answer: "Al-Tawbah",
  },
  {
    question:
      "Who is commonly considered the first martyr in Islam, known for her knowledge and narration of Hadiths?",
    options: ["Aisha", "Fatimah", "Khadijah", "Sumayyah"],
    answer: "Sumayyah",
  },
  {
    question: "What is the main theme of Surah Al-Ikhlas?",
    options: [
      "The Oneness of God",
      "The story of creation",
      "The Day of Judgment",
      "The Prophets",
    ],
    answer: "The Oneness of God",
  },
  {
    question: "Which is the smallest Surah in the Quran?",
    options: ["Al-Nas", "Al-Kawthar", "Al-Ikhlas", "Al-Falaq"],
    answer: "Al-Kawthar",
  },
  {
    question: "What is the most widely read and memorized book in Islam?",
    options: ["The Quran", "The Hadith", "The Sunnah", "The Sira"],
    answer: "The Quran",
  },
  {
    question: "What is the name of the final prophet in Islam?",
    options: ["Muhammad", "Abraham", "Moses", "Jesus"],
    answer: "Muhammad",
  },
  {
    question: "What is the name of the holy book revealed to Prophet Muhammad?",
    options: ["The Bible", "The Torah", "The Vedas", "The Quran"],
    answer: "The Quran",
  },
  {
    question: "Which of the following is NOT a major source of Islamic law?",
    options: ["Quran", "Hadith", "Ijma", "Bible"],
    answer: "Bible",
  },
  {
    question:
      "What is the term used for the recorded sayings and actions of Prophet Muhammad?",
    options: ["Sunnah", "Hadith", "Quran", "Fiqh"],
    answer: "Hadith",
  },
  {
    question:
      "Which of the following is considered one of the Five Pillars of Islam?",
    options: ["Fasting", "Pilgrimage", "Charity", "All of the above"],
    answer: "All of the above",
  },
  {
    question:
      "What is the name of the annual pilgrimage to Mecca, which is an obligation for Muslims?",
    options: ["Ramadan", "Hajj", "Eid al-Fitr", "Eid al-Adha"],
    answer: "Hajj",
  },
  {
    question:
      "Which of the following is NOT one of the main branches of Islam?",
    options: ["Sunni", "Shia", "Sufi", "Protestant"],
    answer: "Protestant",
  },
  {
    question:
      "What is the name of the Islamic practice of giving a portion of one's wealth to charity?",
    options: ["Zakat", "Sawm", "Salat", "Hajj"],
    answer: "Zakat",
  },
  {
    question:
      "Which of the following is considered one of the holiest sites in Islam?",
    options: [
      "The Kaaba",
      "The Dome of the Rock",
      "The Blue Mosque",
      "All of the above",
    ],
    answer: "All of the above",
  },
  {
    question:
      "What is the term used for the Islamic legal system derived from the Quran and Hadith?",
    options: ["Sunnah", "Sharia", "Ijma", "Qiyas"],
    answer: "Sharia",
  },
  {
    question:
      "Which of the following is NOT one of the Six Articles of Faith in Islam?",
    options: [
      "Belief in Allah",
      "Belief in Angels",
      "Belief in Prophets",
      "Belief in the Trinity",
    ],
    answer: "Belief in the Trinity",
  },
  {
    question:
      "What is the term used for the Islamic practice of ritual purification before prayer?",
    options: ["Wudu", "Sawm", "Zakat", "Hajj"],
    answer: "Wudu",
  },
  {
    question:
      "Which of the following is considered one of the four major schools of Islamic jurisprudence?",
    options: ["Hanafi", "Shia", "Sufi", "Wahhabi"],
    answer: "Hanafi",
  },
  {
    question:
      "What is the term used for the Islamic concept of struggle or striving?",
    options: ["Jihad", "Sunnah", "Hadith", "Sharia"],
    answer: "Jihad",
  },
  {
    question:
      "What is the term used for the Islamic practice of daily ritual prayers?",
    options: ["Salat", "Wudu", "Zakat", "Hajj"],
    answer: "Salat",
  },
  {
    question:
      "What is the term used for the Islamic concept of consensus among religious scholars?",
    options: ["Ijma", "Qiyas", "Fiqh", "Sharia"],
    answer: "Ijma",
  },
  {
    question:
      "Which of the following is considered one of the Six Articles of Faith in Islam?",
    options: [
      "Belief in Divine Decree",
      "Belief in the Trinity",
      "Belief in Reincarnation",
      "Belief in Purgatory",
    ],
    answer: "Belief in Divine Decree",
  },
  {
    question:
      "What is the Islamic term for the annual period of fasting observed during the month of Ramadan?",
    options: ["Sawm", "Salat", "Zakat", "Hajj"],
    answer: "Sawm",
  },
  {
    question:
      "Which of the following is considered one of the four Holy books in Islam?",
    options: ["The Quran", "The Upanishads", "The Analects", "The Talmud"],
    answer: "The Quran",
  },
  {
    question:
      "What is the term used for the Islamic practice of giving alms or charity?",
    options: ["Sadaqah", "Khums", "Waqf", "Zakat"],
    answer: "Zakat",
  },
  {
    question:
      "What is the term used for the Islamic practice of ritual ablution before prayer?",
    options: ["Ghusl", "Tayammum", "Wudu", "Khushoo"],
    answer: "Wudu",
  },
  {
    question:
      "Which of the following is NOT one of the five daily prayers observed by Muslims?",
    options: ["Fajr", "Zuhr", "Asr", "Maghrib", "Qiyam"],
    answer: "Qiyam",
  },
  {
    question:
      "What is the term used for the Islamic concept of the oneness of God?",
    options: ["Tawhid", "Sunnah", "Iman", "Taqwa"],
    answer: "Tawhid",
  },
  {
    question:
      "What is the term used for the Islamic practice of reciting from the Quran?",
    options: ["Tilawah", "Khutbah", "Adhan", "Dua"],
    answer: "Tilawah",
  },
  {
    question:
      "Which of the following is considered one of the major branches of Islamic jurisprudence?",
    options: ["Maliki", "Ismaili", "Sufi", "Baha'i"],
    answer: "Maliki",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking knowledge and understanding?",
    options: ["Ilm", "Taqwa", "Jihad", "Iman"],
    answer: "Ilm",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking guidance and forgiveness from God?",
    options: ["Istighfar", "Dhikr", "Dua", "Tawakkul"],
    answer: "Istighfar",
  },
  {
    question:
      "What is the term used for the Islamic concept of striving or struggling in the way of God?",
    options: ["Mujahadah", "Taqwa", "Jihad", "Sabr"],
    answer: "Jihad",
  },
  {
    question:
      "What is the term used for the Islamic practice of remembrance or mentioning of God?",
    options: ["Dhikr", "Dua", "Tilawah", "Sadaqah"],
    answer: "Dhikr",
  },
  {
    question:
      "What is the term used for the Islamic concept of trusting in God's will and plan?",
    options: ["Tawakkul", "Sabr", "Ihsan", "Taqwa"],
    answer: "Tawakkul",
  },
  {
    question:
      "What is the term used for the Islamic practice of pilgrimage to holy sites, other than the Hajj?",
    options: ["Ziyarah", "Umrah", "Ijtihad", "Khutbah"],
    answer: "Ziyarah",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking legal rulings from religious scholars?",
    options: ["Fatwa", "Ijtihad", "Ijma", "Qiyas"],
    answer: "Fatwa",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking forgiveness from God?",
    options: ["Tawbah", "Sadaqah", "Zakat", "Wudu"],
    answer: "Tawbah",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking knowledge and understanding through reasoning?",
    options: ["Ijtihad", "Ijma", "Qiyas", "Taqlid"],
    answer: "Ijtihad",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking guidance and wisdom from religious scholars?",
    options: ["Taqlid", "Ijtihad", "Fatwa", "Ijma"],
    answer: "Taqlid",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking religious knowledge through study and scholarship?",
    options: ["Ta'lim", "Ijtihad", "Taqlid", "Fatwa"],
    answer: "Ta'lim",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking forgiveness and repentance from God?",
    options: ["Istighfar", "Tawbah", "Sadaqah", "Zakat"],
    answer: "Tawbah",
  },
  {
    question:
      "What is the term used for the Islamic practice of ritually slaughtering an animal for food?",
    options: ["Halal", "Dhabihah", "Qurbani", "Aqiqah"],
    answer: "Dhabihah",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking protection from God?",
    options: ["Istiaadhah", "Tawakkul", "Dhikr", "Taqlid"],
    answer: "Istiaadhah",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking refuge in God?",
    options: ["Istighathah", "Tawbah", "Sadaqah", "Zakat"],
    answer: "Istighathah",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking blessings and guidance from God?",
    options: ["Istikhara", "Ijtihad", "Fatwa", "Taqlid"],
    answer: "Istikhara",
  },
  {
    question:
      "What is the term used for the Islamic practice of seeking blessings and forgiveness from God?",
    options: ["Munajat", "Ijtihad", "Fatwa", "Taqlid"],
    answer: "Munajat",
  },
  {
    question:
      "What is the term used for the Islamic practice of making supplications or invocations to God?",
    options: ["Du'a", "Ijtihad", "Fatwa", "Taqlid"],
    answer: "Du'a",
  },
  {
    question:
      "What is the term used for the Islamic practice of reciting or chanting verses from the Quran?",
    options: ["Tilawah", "Ijtihad", "Fatwa", "Taqlid"],
    answer: "Tilawah",
  },
];

export default islamicTextsQuestions;
