const islamicLawsQuestions = [
  {
    question:
      "What is the Islamic law derived from the Quran and Hadith called?",
    options: ["Sharia", "Fiqh", "Sunnah", "Tawheed"],
    answer: "Sharia",
  },
  {
    question: "Who are the people qualified to interpret Islamic law?",
    options: ["Caliphs", "Sultans", "Ulama", "Muezzins"],
    answer: "Ulama",
  },
  {
    question:
      "Which school of thought (madhab) in Sunni Islam is known for its emphasis on reason and logic in jurisprudence?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question:
      "What is the name given to the consensus of Islamic scholars on a legal issue?",
    options: ["Ijma", "Qiyas", "Fatwa", "Hadith"],
    answer: "Ijma",
  },
  {
    question:
      "What term is used for analogical reasoning in Islamic jurisprudence?",
    options: ["Hadith", "Shura", "Qiyas", "Sunnah"],
    answer: "Qiyas",
  },
  {
    question:
      "Which source of Islamic law involves personal reasoning by a qualified scholar?",
    options: ["Ijtihad", "Fiqh", "Sharia", "Sunnah"],
    answer: "Ijtihad",
  },
  {
    question:
      "In Islamic law, what is the term for charity or almsgiving, which is one of the Five Pillars of Islam?",
    options: ["Hajj", "Salah", "Zakat", "Sawm"],
    answer: "Zakat",
  },
  {
    question:
      "What type of law deals with issues such as marriage, divorce, inheritance, and child custody in Islam?",
    options: [
      "Criminal Law",
      "Family Law",
      "Economic Law",
      "International Law",
    ],
    answer: "Family Law",
  },
  {
    question: "Which Islamic term refers to forbidden or unlawful acts?",
    options: ["Halal", "Makruh", "Haram", "Mustahabb"],
    answer: "Haram",
  },
  {
    question:
      "Which document, considered to be Islam's first constitution, established a form of governance and rights for the Muslim and non-Muslim communities in Medina?",
    options: [
      "Treaty of Hudaybiyyah",
      "Constitution of Medina",
      "Charter of Privileges",
      "Covenant of Umar",
    ],
    answer: "Constitution of Medina",
  },
  {
    question: "What practice does 'Taharah' refer to in Islamic law?",
    options: ["Fasting", "Pilgrimage", "Purification", "Charity"],
    answer: "Purification",
  },
  {
    question:
      "Which Islamic practice is concerned with dietary laws including permissible food and drinks?",
    options: ["Zakat", "Halal", "Hajj", "Salah"],
    answer: "Halal",
  },
  {
    question:
      "In Islamic jurisprudence, what is the fixed share of inheritance specified by Sharia for eligible heirs called?",
    options: ["Mahr", "Mirath", "Waasiyah", "Sadaqah"],
    answer: "Mirath",
  },
  {
    question:
      "What term is used to describe the Islamic mortgage system compliant with Sharia law?",
    options: ["Murabaha", "Sukuk", "Ijara", "Gharar"],
    answer: "Murabaha",
  },
  {
    question:
      "Which term refers to the expiatory practices such as fasting, feeding the poor, or offering sacrifices to compensate for violations of Islamic law?",
    options: ["Kaffarah", "Zakat", "Sawm", "Qurbani"],
    answer: "Kaffarah",
  },
  {
    question:
      "What is the act of declaring disbelief in Islam or leaving the Islamic faith called?",
    options: ["Hijra", "Ridda", "Shahada", "Kufr"],
    answer: "Ridda",
  },
  {
    question:
      "What term refers to the Islamic legal ruling given by a scholar?",
    options: ["Fatwa", "Fiqh", "Ijma", "Qiyas"],
    answer: "Fatwa",
  },
  {
    question:
      "Which of the following is not one of the sources of Islamic jurisprudence?",
    options: ["Quran", "Sunnah", "Ijma", "Philosophy"],
    answer: "Philosophy",
  },
  {
    question:
      "What is the term for interest or usury, which is prohibited under Islamic law?",
    options: ["Riba", "Zakat", "Sadaqah", "Jizya"],
    answer: "Riba",
  },
  {
    question:
      "Which form of Islamic financing involves a partnership where profits are shared as per agreement, but loss is borne only by the owner?",
    options: ["Mudarabah", "Musharakah", "Murabaha", "Ijarah"],
    answer: "Mudarabah",
  },
  {
    question:
      "What is the obligatory prayer called that Muslims perform five times a day?",
    options: ["Zakat", "Hajj", "Salah", "Sawm"],
    answer: "Salah",
  },
  {
    question: "Which type of fasting is mandatory during the month of Ramadan?",
    options: ["Sunnah", "Nafl", "Wajib", "Fard"],
    answer: "Fard",
  },
  {
    question:
      "In Islamic jurisprudence, what term describes the act of making something pure?",
    options: ["Tayammum", "Wudu", "Ghusl", "Tahara"],
    answer: "Tahara",
  },
  {
    question:
      "Which practice involves a temporary marital arrangement mentioned in some Islamic texts?",
    options: ["Mut'ah", "Nikah", "Talaq", "Misyar"],
    answer: "Mut'ah",
  },
  {
    question:
      "What is the Islamic term for interest or usury, which is strictly prohibited?",
    options: ["Riba", "Zakat", "Sadaqah", "Jizya"],
    answer: "Riba",
  },
  {
    question:
      "Which pilgrimage is required of Muslims at least once in their lifetime, if they are able?",
    options: ["Umrah", "Hajj", "Ziyarah", "Salah"],
    answer: "Hajj",
  },
  {
    question:
      "What is the term for the alms given at the end of Ramadan before the Eid prayer?",
    options: ["Zakat ul-Fitr", "Zakat ul-Mal", "Sadaqah", "Fitrah"],
    answer: "Zakat ul-Fitr",
  },
  {
    question:
      "What legal penalty in Islamic law is fixed by the Quran and Hadith?",
    options: ["Tazir", "Qisas", "Hudud", "Diyah"],
    answer: "Hudud",
  },
  {
    question:
      "Which concept in Islamic law refers to 'custodianship' or 'stewardship' of the earth and its resources?",
    options: ["Khalifah", "Shura", "Amanah", "Karama"],
    answer: "Khalifah",
  },
  {
    question:
      "What term refers to the ethical and moral principles set out in Islamic law?",
    options: ["Akhlaq", "Fard", "Halal", "Adab"],
    answer: "Akhlaq",
  },
  {
    question: "What is the primary source of Islamic Law?",
    options: ["The Quran", "The Sunnah", "The Ijma", "The Qiyas"],
    answer: "The Quran",
  },
  {
    question: "What is the term for the obligatory acts in Islam?",
    options: ["Wajib", "Sunnat", "Makruh", "Haram"],
    answer: "Wajib",
  },
  {
    question: "Which of the following is NOT one of the five pillars of Islam?",
    options: ["Salat", "Sawm", "Zakat", "Jihad"],
    answer: "Jihad",
  },
  {
    question:
      "What is the term for the juristic principle of 'public interest'?",
    options: ["Maslaha", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Maslaha",
  },
  {
    question:
      "Which school of Islamic jurisprudence is dominant in Saudi Arabia?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanbali",
  },
  {
    question: "What is the term for the Islamic law of inheritance?",
    options: ["Zakat", "Khums", "Mirath", "Sadaqah"],
    answer: "Mirath",
  },
  {
    question: "Which of the following is NOT a valid source of Islamic Law?",
    options: ["The Quran", "The Sunnah", "Urf", "Bid'ah"],
    answer: "Bid'ah",
  },
  {
    question: "What is the term for the permissible acts in Islam?",
    options: ["Wajib", "Halal", "Makruh", "Haram"],
    answer: "Halal",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Egypt?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic legal concept of 'analogy'?",
    options: ["Qiyas", "Ijma", "Ijtihad", "Maslaha"],
    answer: "Qiyas",
  },
  {
    question: "What is the term for the Islamic legal concept of 'consensus'?",
    options: ["Qiyas", "Ijma", "Ijtihad", "Maslaha"],
    answer: "Ijma",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Morocco?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'independent reasoning'?",
    options: ["Qiyas", "Ijma", "Ijtihad", "Maslaha"],
    answer: "Ijtihad",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'public interest'?",
    options: ["Maslaha", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Maslaha",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Pakistan?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question: "What is the term for the forbidden acts in Islam?",
    options: ["Wajib", "Sunnat", "Makruh", "Haram"],
    answer: "Haram",
  },
  {
    question: "What is the term for the Islamic legal concept of 'custom'?",
    options: ["Urf", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Urf",
  },
  {
    question: "What is the term for the Islamic law of retribution?",
    options: ["Qisas", "Hudud", "Diyah", "Kaffarah"],
    answer: "Qisas",
  },
  {
    question:
      "Which of the following is NOT one of the sources of Islamic Law?",
    options: ["The Quran", "The Sunnah", "Ijma", "Papal Decrees"],
    answer: "Papal Decrees",
  },
  {
    question: "What is the term for the Islamic concept of 'repentance'?",
    options: ["Tawbah", "Zakat", "Hajj", "Sawm"],
    answer: "Tawbah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in India?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question: "What is the term for the Islamic law of financial compensation?",
    options: ["Qisas", "Hudud", "Diyah", "Kaffarah"],
    answer: "Diyah",
  },
  {
    question: "Which of the following is NOT one of the pillars of Islam?",
    options: ["Shahadah", "Salat", "Zakat", "Khilafah"],
    answer: "Khilafah",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'juristic preference'?",
    options: ["Istihsan", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Istihsan",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Turkey?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question: "What is the term for the Islamic law of prescribed punishments?",
    options: ["Qisas", "Hudud", "Diyah", "Kaffarah"],
    answer: "Hudud",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'juristic discretion'?",
    options: ["Istihsan", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Ijtihad",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Lebanon?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of expiation?",
    options: ["Qisas", "Hudud", "Diyah", "Kaffarah"],
    answer: "Kaffarah",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'blocking the means'?",
    options: ["Sadd al-Dharai'", "Qiyas", "Ijma", "Ijtihad"],
    answer: "Sadd al-Dharai'",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Iraq?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of apostasy?",
    options: ["Riddah", "Zina", "Sirqah", "Hirabah"],
    answer: "Riddah",
  },
  {
    question:
      "What is the term for the Islamic legal concept of 'juristic reasoning'?",
    options: ["Ijtihad", "Qiyas", "Ijma", "Istihsan"],
    answer: "Ijtihad",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Iran?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of contracts?",
    options: ["Muamalat", "Munakahat", "Jinayat", "Sirah"],
    answer: "Muamalat",
  },
  {
    question: "What is the term for the Islamic law of marriage?",
    options: ["Muamalat", "Munakahat", "Jinayat", "Sirah"],
    answer: "Munakahat",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Syria?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question: "What is the term for the Islamic law of crimes?",
    options: ["Muamalat", "Munakahat", "Jinayat", "Sirah"],
    answer: "Jinayat",
  },
  {
    question:
      "Which school of Islamic jurisprudence is dominant in Afghanistan?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question:
      "What is the term for the Islamic law of inheritance from non-Muslims?",
    options: ["Mirath", "Kalala", "Munakahat", "Sirah"],
    answer: "Kalala",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Yemen?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of testimony?",
    options: ["Shahadah", "Bayyinah", "Yamin", "Nudhur"],
    answer: "Bayyinah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Oman?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanbali",
  },
  {
    question: "What is the term for the Islamic law of oaths?",
    options: ["Shahadah", "Bayyinah", "Yamin", "Nudhur"],
    answer: "Yamin",
  },
  {
    question: "What is the term for the Islamic law of vows?",
    options: ["Shahadah", "Bayyinah", "Yamin", "Nudhur"],
    answer: "Nudhur",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Algeria?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Tunisia?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question: "What is the term for the Islamic law of theft?",
    options: ["Riddah", "Zina", "Sirqah", "Hirabah"],
    answer: "Sirqah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Bahrain?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question: "What is the term for the Islamic law of armed robbery?",
    options: ["Riddah", "Zina", "Sirqah", "Hirabah"],
    answer: "Hirabah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Kuwait?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question:
      "What is the term for the Islamic law of unlawful sexual intercourse?",
    options: ["Riddah", "Zina", "Sirqah", "Hirabah"],
    answer: "Zina",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Qatar?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanbali",
  },
  {
    question: "What is the term for the Islamic law of retaliation?",
    options: ["Qisas", "Hudud", "Diyah", "Kaffarah"],
    answer: "Qisas",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Libya?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question: "What is the term for the Islamic law of divorce?",
    options: ["Talaq", "Khul'", "Iddah", "Nafaqah"],
    answer: "Talaq",
  },
  {
    question:
      "Which school of Islamic jurisprudence is dominant in United Arab Emirates?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanbali",
  },
  {
    question:
      "What is the term for the Islamic law of divorce initiated by the wife?",
    options: ["Talaq", "Khul'", "Iddah", "Nafaqah"],
    answer: "Khul'",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Jordan?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question:
      "What is the term for the Islamic law of waiting period after divorce?",
    options: ["Talaq", "Khul'", "Iddah", "Nafaqah"],
    answer: "Iddah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Palestine?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Hanafi",
  },
  {
    question:
      "What is the term for the Islamic law of maintenance for the wife?",
    options: ["Talaq", "Khul'", "Iddah", "Nafaqah"],
    answer: "Nafaqah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Malaysia?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Indonesia?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of wills and bequests?",
    options: ["Wasiyyah", "Hibah", "Iqrar", "Ikhtilat"],
    answer: "Wasiyyah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Somalia?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of gifting?",
    options: ["Wasiyyah", "Hibah", "Iqrar", "Ikhtilat"],
    answer: "Hibah",
  },
  {
    question: "What is the term for the Islamic legal concept of 'admission'?",
    options: ["Iqrar", "Bayyinah", "Yamin", "Nudhur"],
    answer: "Iqrar",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Comoros?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question:
      "What is the term for the Islamic law of intermixture of properties?",
    options: ["Wasiyyah", "Hibah", "Iqrar", "Ikhtilat"],
    answer: "Ikhtilat",
  },
  {
    question:
      "Which school of Islamic jurisprudence is dominant in Mauritania?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
  {
    question: "What is the term for the Islamic law of pre-emption?",
    options: ["Shuf'ah", "Rahn", "Hajr", "Waqf"],
    answer: "Shuf'ah",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Djibouti?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Shafi'i",
  },
  {
    question: "What is the term for the Islamic law of pawning?",
    options: ["Shuf'ah", "Rahn", "Hajr", "Waqf"],
    answer: "Rahn",
  },
  {
    question: "What is the term for the Islamic law of interdiction?",
    options: ["Shuf'ah", "Rahn", "Hajr", "Waqf"],
    answer: "Hajr",
  },
  {
    question: "Which school of Islamic jurisprudence is dominant in Sudan?",
    options: ["Hanafi", "Maliki", "Shafi'i", "Hanbali"],
    answer: "Maliki",
  },
];

export default islamicLawsQuestions;
